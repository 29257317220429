import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
  Path,
  Svg,
} from "@react-pdf/renderer";
import axios from "axios";
import { useSelector } from "react-redux";
import { apiUrl, isAdminLoggedIn } from "../ApiUrlAndToken";
import dTechLogo from "../assets/img/dtech.png";

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 20,
    display: "flex",
    flexDirection: "column",
  },
  section: {
    margin: 3,
    padding: 3,
    flexDirection: "row",
    fontSize: "13px",
    textAlign: "center",
    justifyContent: "center",
  },
  viewer: {
    width: "100%", // the pdf viewer will take up all of the width and height
    height: window.innerHeight - 200,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: "20px",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "5.3%", // (100 / 18) rounded for a roughly even distribution
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontSize: 5,
  },
  tableCell: {
    margin: 3,
    fontSize: 5,
  },
  icon: {
    height: 20,
    width: 20,
    color: "#29318C",
  },
});
const initialRows = [
  "Date",
  "Royal Bolt",
  "Self Screw",
  "Norton Tape",
  "Maskin Tape",
  "Other Accessories",
  "Silicon",
  "MS Box",
  "Labour Bill (Load/Unload)",
  "Glass Lifting Bill",
  "Project Misc Expense",
  "Truck/Pickup Rent",
  "Conveyance /Van Fare",
  "Eng Conveyance & Others",
  "Others Expenses",
  "Contractor Payment",
  "Sample Clearing",
  "Couriers Services Bill",
  "Total",
];

// Create Document Component
function ExpenseReport({ project_id, project_name }) {
  const jwt_token = useSelector((state) => state.global.jwt_token);
  const [rows, setRows] = useState(initialRows);
  const [reportData, setReportData] = useState([]);

  const getReport = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/project-expense-detail/${project_id}/`,
        {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        }
      );

      // Sort the data by date
      const sortedData = response.data.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );

      // Dynamically update rows based on the data
      const allItemNames = new Set(initialRows.slice(1));
      sortedData.forEach((row) => {
        row.items.forEach((item) => {
          allItemNames.add(item.name);
        });
      });

      let dynamicRows = ["Date", ...Array.from(allItemNames)];

      // Remove rows with no data
      dynamicRows = dynamicRows.filter(
        (itemName) =>
          itemName === "Date" ||
          sortedData.some((row) =>
            row.items.some(
              (item) => item.name === itemName && item.total_amount > 0
            )
          )
      );

      // Ensure there are at least 18 rows
      // while (dynamicRows.length < 17) {
      //   dynamicRows.push(initialRows[dynamicRows.length + 1]);
      // }

      dynamicRows.push("Total");

      setRows(dynamicRows);
      setReportData(sortedData);
      console.log(sortedData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getReport();
  }, []);

  // Calculate totals for each column
  const columnTotals = rows.slice(1, -1).reduce((totals, itemName) => {
    totals[itemName] = reportData.reduce((sum, row) => {
      const item = row?.items?.find((data) => data.name === itemName);
      return sum + (item ? item.total_amount : 0);
    }, 0);
    return totals;
  }, {});

  const grandTotal = Object.values(columnTotals).reduce(
    (sum, value) => sum + value,
    0
  );

  return (
    <PDFViewer style={styles.viewer}>
      {/* Start of the document */}
      <Document>
        <Page size="A4" style={styles.page} orientation="portrait">
          <View
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <Image
              src={dTechLogo}
              style={{
                height: "150px",
                width: "150px",
                padding: "14px",
                justifyContent: "center",
                alignSelf: "center",
                marginLeft: "40px",
              }}
            />
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  height: "110px",
                  width: "4px",
                  backgroundColor: "#29318C",
                  marginRight: "10px",
                }}
              />
              <View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Svg
                    style={{
                      height: 20,
                      width: 20,
                      color: "#29318C",
                      marginRight: "10px",
                    }}
                    viewBox="0 0 24 24"
                  >
                    <Path
                      d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5s2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"
                      fill="#29318C"
                    />
                  </Svg>
                  <Text
                    style={{
                      fontSize: "13px",
                      fontWeight: "700",
                      color: "#29318C",
                      width: "180px",
                      letterSpacing: 2,
                    }}
                  >
                    House-77/B, Block- F, Road-3, Banani, 1213 Dhaka, Bangladesh
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "20px",
                  }}
                >
                  <Svg
                    style={{
                      height: 16,
                      width: 16,
                      color: "#29318C",
                      marginRight: "10px",
                    }}
                    viewBox="0 0 24 24"
                  >
                    <Path
                      d="M6.62 10.79a15.53 15.53 0 006.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.38 2.33.59 3.57.59.55 0 1 .45 1 1v3.5c0 .55-.45 1-1 1C10.29 21 3 13.71 3 4.5 3 3.95 3.45 3.5 4 3.5H7.5c.55 0 1 .45 1 1 0 1.24.21 2.45.59 3.57.12.35.03.74-.24 1.02l-2.2 2.2z"
                      fill="#29318C"
                    />
                  </Svg>
                  <Text
                    style={{
                      fontSize: "13px",
                      fontWeight: "700",
                      color: "#29318C",
                      width: "180px",
                      letterSpacing: 2,
                    }}
                  >
                    +8801988818050 +8801859666594
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.section}>
            <Text>Project Name: </Text>
            <Text>{project_name}</Text>
          </View>

          <View style={styles.section}>
            <Text>
              Project Materials and Other Payments (Expense Statement)
            </Text>
          </View>
          <View style={styles.table}>
            {/* Table Header */}
            <View style={styles.tableRow}>
              {rows.map((header, index) => (
                <View
                  key={index}
                  style={{ ...styles.tableCol, width: `${100 / rows.length}%` }}
                >
                  <Text style={styles.tableCell}>{header}</Text>
                </View>
              ))}
            </View>

            {reportData.length > 0 &&
              reportData.map((row, rowIndex) => {
                const total = rows.slice(1, -1).reduce((sum, itemName) => {
                  const item = row?.items?.find(
                    (data) => data.name === itemName
                  );
                  return sum + (item ? item.total_amount : 0);
                }, 0);

                return (
                  <View style={styles.tableRow} key={rowIndex}>
                    {[
                      row.date,
                      ...rows.slice(1, -1).map((itemName) => {
                        const item = row?.items?.find(
                          (data) => data.name === itemName
                        );
                        return item ? item.total_amount : "-";
                      }),
                      total,
                    ].map((content, index) => (
                      <View
                        key={index}
                        style={{
                          ...styles.tableCol,
                          width: `${100 / rows.length}%`,
                        }}
                      >
                        <Text style={styles.tableCell}>{content}</Text>
                      </View>
                    ))}
                  </View>
                );
              })}

            {/* Total Row */}
            <View style={styles.tableRow}>
              <View
                style={{ ...styles.tableCol, width: `${100 / rows.length}%` }}
              >
                <Text style={styles.tableCell}>Total</Text>
              </View>
              {rows.slice(1, -1).map((itemName, index) => (
                <View
                  key={index}
                  style={{ ...styles.tableCol, width: `${100 / rows.length}%` }}
                >
                  <Text style={styles.tableCell}>{columnTotals[itemName]}</Text>
                </View>
              ))}
              <View
                style={{ ...styles.tableCol, width: `${100 / rows.length}%` }}
              >
                <Text style={styles.tableCell}>{grandTotal}</Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}

export default ExpenseReport;
