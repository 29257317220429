import { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl, isAdminLoggedIn } from "../../ApiUrlAndToken";
import { useSelector } from "react-redux";
import dTechLogo from "../../assets/img/logo.png";
import PageTitle from "../pageTitle/PageTitle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DetailsModalReveneu from "../reveneu/components/DetailsModalReveneu";
import DetailsPurchaseProject from "../purchase/components/DetailsPurchaseProject";
import DownloadUserReport from "../../components/DownLoadUserReport";
import DownloadBalanceSheet from "../../components/DownloadBalanceSheet";

function BalanceSheetReport() {
  const jwt_token = useSelector((state) => state.global.jwt_token);
  const ownerID = window.localStorage.getItem("ownerID");
  const [reportData, setReportData] = useState([]);

  const getReport = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/reports/balance-sheet/`, {
        headers: {
          Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
        },
      });
      setReportData(response.data?.data);
      console.log(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getReport();
  }, []);

  return (
    <>
      <PageTitle title="Balance Sheet Report" />
      <div className="p-8 mt-[60px]">
        <div className="text-center mb-4">
          <img src={dTechLogo} alt="dTech Logo" className="mx-auto w-24 h-20" />
        </div>

        <DownloadBalanceSheet reportData={reportData} />
        <div className="text-center mb-8">
          <p className="text-lg font-bold">Balance Sheet</p>
        </div>
        <div className="mb-8">
          <table className="table-auto w-full border-collapse border border-gray-400">
            <thead>
              <tr>
                <th className="border border-gray-400 p-2">Account</th>
                <th className="border border-gray-400 p-2">Balance</th>
              </tr>
            </thead>
            <tbody>
              {reportData?.map((data, index) => (
                <tr key={index}>
                  <td className="border border-gray-400 p-2">{data.account}</td>
                  <td className="border border-gray-400 p-2">{data.balance}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <hr className="border-black mt-4" />
      </div>
    </>
  );
}

export default BalanceSheetReport;
