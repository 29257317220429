import React, { useEffect, useState } from "react";
import DownloadIcon from "@mui/icons-material/DownloadOutlined";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const DownloadPurchaseItemReport = ({ reportData }) => {
  const [revenueReport, setRevenueReport] = useState([]);
  const getUserReportByID = async () => {
    try {
      setRevenueReport(
        reportData.purchase_items.map((item) => ({
          "Purchase Date": item.purchase__date,
          "Purchase Id": item.purchase_id,
          "Purchase Amount": item.purchase__total_amount,
          Description: item.purchase__remarks,
          "Business Partner": item.purchase__business_partner__name,
          Project: item.purchase__sales_order__name,
          "Item Name": item.item__name,
          "Item Quantity": item.quantity,
          "Item Amount": item.amount,
          "Item Total Amount": item.item_total_amount,
          "Owner Name": item.owner__first_name || "",
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadRevenueReport = () => {
    // Convert data to Excel format
    const worksheet = XLSX.utils.json_to_sheet(revenueReport);
    console.log(revenueReport);
    // Set column widths
    const columnWidths = [
      { wch: 12 },
      { wch: 10 },
      { wch: 18 },
      { wch: 18 },
      { wch: 18 },
      { wch: 11 },
      { wch: 16 },
      { wch: 16 },
      { wch: 18 },
      { wch: 18 },
      { wch: 18 },
      { wch: 14 },
    ];

    worksheet["!cols"] = columnWidths;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Revenue Report");

    // Generate a download
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(
      blob,
      `Purchase Item Report ${reportData.start_date} to ${reportData.end_date}.xlsx`
    );
  };

  useEffect(() => {
    getUserReportByID();
  }, [reportData]);
  return (
    <div className="flex place-items-center space-x-3 my-[30px] justify-center items-center">
      <button
        onClick={handleDownloadRevenueReport}
        className="border border-black hover:bg-success rounded hover:text-white p-2"
      >
        <DownloadIcon />
      </button>
    </div>
  );
};

export default DownloadPurchaseItemReport;
