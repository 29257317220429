import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import GeneralInput from "../../../components/GeneralInput";
import GeneralSelect from "../../../components/GeneralSelect";
import { submitButton } from "../../../components/DesignStandardize";
import { isAdminLoggedIn } from "../../../ApiUrlAndToken";
import { useSelector } from "react-redux";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const EditItemForm = ({ id }) => {
  const [name, setName] = useState(null);

  const jwt_token = useSelector((state) => state.global.jwt_token);
  const ownerID = window.localStorage.getItem("ownerID");
  console.log(ownerID);

  const EditBankDetails = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("owner", ownerID);

    try {
      const response = await axios.put(`${apiUrl}/api/banks/${id}/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
        },
      });

      console.log(response);
      toast.success(`Edited Successfully`);
      setTimeout(() => {
        window.location.href = "/admin/banks";
      }, 500);
    } catch (error) {
      console.log(error);
    }
  };
  // Fetch user data when the component mounts
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/banks/${id}`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        const res = response.data;
        console.log(res);
        setName(res.name);
      } catch (error) {
        console.error("Error fetching bank data:", error);
      }
    };

    fetchUser();
  }, [id]);

  return (
    <form className="p-2 flex flex-col max-h-96 overflow-y-auto gap-3 items-center w-full mt-auto md:mr-auto font-semibold font-Inter text-sm text-left md:text-base">
      <div className="flex flex-row place-items-center gap-4">
        <div className="">
          <GeneralInput
            className={""}
            label="Bank Name"
            id="name"
            name="name"
            type="text"
            placeholder="Enter Bank Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <button
          className={`${submitButton} h-[50px] mt-[30px] w-max`}
          onClick={(e) => EditBankDetails(e)}
        >
          Update
        </button>
      </div>
    </form>
  );
};

export default EditItemForm;
