import React, { useState } from "react";
import { apiUrl, isAdminLoggedIn } from "../../../ApiUrlAndToken";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { hoverScale } from "../../../components/DesignStandardize";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const DetailsModalReveneu = ({ invoice, toggleDetailsModal }) => {
  return (
    <>
      <div className="z-10 fixed inset-0 flex items-center justify-center">
        <div
          className="bg-black bg-opacity-50 fixed inset-0"
          onClick={toggleDetailsModal}
        />
        <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
          <div className="card-title flex justify-between">
            <p className="text-xl text-blue-900 font-bold">
              <span className="text-black">Details</span>
            </p>
            <CloseIcon
              onClick={toggleDetailsModal}
              fontSize="1px"
              className={`hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
            />
          </div>

          <div
            className={`flex ${
              invoice.picture ? "flex-col" : "flex-col"
            }  justify-center gap-1 relative overflow-hidden`}
          >
            {invoice.picture && (
              <div className="flex justify-center items-center">
                <Zoom>
                  <img
                    src={invoice.picture}
                    className="w-full h-[200px] rounded-xl object-cover"
                    alt="Invoice Image"
                  />
                </Zoom>
              </div>
            )}

            <div className="max-h-96 overflow-x-auto">
              <table className="table border-[1px] border-solid bg-white">
                <tbody className="">
                  {/* start date */}
                  <tr className="bg-white">
                    <th className="border-r-[2px] border-solid">Date</th>
                    <td className="">{invoice.date}</td>
                  </tr>
                  {/* description */}
                  <tr className="h-max">
                    <th className="border-r-[2px] border-solid">Description</th>
                    <td className="w-80">{invoice.remarks}</td>
                  </tr>
                  <tr className="h-max">
                    <th className="border-r-[2px] border-solid">
                      Total Amount
                    </th>
                    <td className="w-80">{invoice.total_amount}</td>
                  </tr>
                  <tr className="h-max">
                    <th className="border-r-[2px] border-solid">Partner</th>
                    <td className="w-80">{invoice.business_partner}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailsModalReveneu;
