import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import GeneralInput from "../../../components/GeneralInput";
import { submitButton } from "../../../components/DesignStandardize";
import { apiUrl, isAdminLoggedIn } from "../../../ApiUrlAndToken";
import GeneralSelect from "../../../components/GeneralSelect";
import { useSelector } from "react-redux";

const validationSchema = Yup.object().shape({
  item_group_name: Yup.string().required("Item Group Name is required"),
  item_group_description: Yup.string().required(
    "Item Group Description is required"
  ),
});

const AddNewItemGroup = () => {
  const jwt_token = useSelector((state) => state.global.jwt_token);

  const formik = useFormik({
    initialValues: {
      item_group_name: "",
      item_group_description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        formData.append("name", values.item_group_name);
        formData.append("description", values.item_group_description);
        formData.append("owner", window.localStorage.getItem("ownerID"));

        const response = await axios.post(
          `${apiUrl}/api/itemgroup/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
            },
          }
        );

        toast.success(`New Item Group Created`);
        console.log(response);
        setTimeout(() => {
          window.location.href = "/admin/item-groups";
        }, 300);
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <form
      className="flex flex-col gap-3 items-center w-full mt-auto md:mr-auto font-semibold font-Inter text-sm text-left md:text-base"
      onSubmit={formik.handleSubmit}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        <div className="">
          <GeneralInput
            className={""}
            label="Item Group Name"
            id="item_group_name"
            name="item_group_name"
            type="text"
            placeholder="Enter Item Group Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.item_group_name}
          />
          {formik.touched.item_group_name && formik.errors.item_group_name ? (
            <div className="text-red-500 font-light">
              {formik.errors.item_group_name}
            </div>
          ) : null}
        </div>
        <div className="">
          <GeneralInput
            className={""}
            label="Item Group Description"
            id="item_group_description"
            name="item_group_description"
            type="text"
            placeholder="Enter Item Group Description"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.item_group_description}
          />
          {formik.touched.item_group_description &&
          formik.errors.item_group_description ? (
            <div className="text-red-500 font-light">
              {formik.errors.item_group_description}
            </div>
          ) : null}
        </div>
      </div>

      <button className={`${submitButton} mt-3`} type="submit">
        Add Item Group
      </button>
    </form>
  );
};

export default AddNewItemGroup;
