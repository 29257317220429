import React, { useState, useEffect, useRef } from "react";
import { Dropdown, Menu, Space, Table, Tag } from "antd";
import FactCheckIconOutlined from "@mui/icons-material/FactCheckOutlined";
import { DownOutlined } from "@ant-design/icons";
import axios from "axios";
import inProgress from "../../assets/img/icons/inProgress.jpg";
import EditIcon from "@mui/icons-material/BorderColor";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  apiUrl,
  isAdminLoggedIn,
  isEmployeeLoggedIn,
} from "../../ApiUrlAndToken";
import AddAssignedEngineer from "./components/AddAssignedEngineer";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { hoverScale, submitButton } from "../../components/DesignStandardize";
import { Link } from "react-router-dom";
import EditProject from "./components/EditProject";
import DetailsModalProject from "./components/DetailsModalProject";
import GeneralModal from "../../components/GeneralModal";
import DeleteProject from "./components/DeleteProject";
import { useSelector } from "react-redux";
import PdfReport from "../../components/PdfDocument";
import ReportModal from "../../components/ReportModal";
import ExpenseReport from "../../components/ExpenseReportPdf";
import ProjectExpenseReport from "../../components/ProjectExpenseReportPdf";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import Highlighter from "react-highlight-words";
import { toast } from "react-toastify";

const AllProjects = () => {
  const [projects, setProjects] = useState([]);
  const [projectID, setProjectID] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [project, setProject] = useState("");
  const [visible, setVisible] = useState(false);
  const [showExpenseReport, setShowExpenseReport] = useState(false);
  const [showDatewiseExpenseReport, setDatewiseShowExpenseReport] =
    useState(false);
  const [datePickerModal, setDatePickerModal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const jwt_token = useSelector((state) => state.global.jwt_token);
  const is_staff = useSelector((state) => state.global.is_staff);
  //Business

  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/businesspartner/`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        setCustomers(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  //

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal((prevShowModal) => !prevShowModal);
  };

  const [editModal, setEditModal] = useState(false);
  const toggleEditModal = () => {
    setEditModal((prevShowModal) => !prevShowModal);
  };
  const EditProjectModal = (id, name) => {
    setProjectID(id);
    setProjectName(name);
    toggleEditModal();
  };

  const handleDelete = async (projectId, projectName) => {
    if (window.confirm(`Are you sure you want to delete ${projectName}?`)) {
      console.log(`${apiUrl}/api/project/${projectId}`);
      try {
        await axios.delete(`${apiUrl}/api/project/${projectId}`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });

        window.location.reload();
        toast.warning("Project has been deleted!!");
      } catch (error) {
        console.error(error);
      }
    }
  };

  const [detailsModal, setDetailsModal] = useState(false);
  const toggleDetailsModal = () => {
    setDetailsModal((prevShowModal) => !prevShowModal);
  };
  const ProjectDetailsModal = (project) => {
    setProject(project);
    toggleDetailsModal();
  };

  const getOnGoingProjects = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/project/`, {
        headers: {
          Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
        },
      });
      console.log(response.data);
      const sortedByContactAmount = response.data.sort(
        (a, b) => parseFloat(b.total_amount) - parseFloat(a.total_amount)
      );
      setProjects(sortedByContactAmount);
      // setProjects(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getOnGoingProjects();
  }, []);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const generateMenuItems = (row) => [
    {
      key: "1",
      label: "View",
      onClick: () => ProjectDetailsModal(row),
    },
    {
      key: "2",
      label: "Edit",
      onClick: () => EditProjectModal(row.id, row.name),
    },
    {
      key: "3",
      label: "Get Report",
      onClick: () => {
        setProjectID(row.id);
        setVisible(true);
      },
    },
    {
      key: "4",
      label: "Expense Report",
      onClick: () => {
        setProjectID(row.id);
        setShowExpenseReport(true);
        setProjectName(row.name);
      },
    },
    {
      key: "5",
      label: "Datewise Expense Report",
      onClick: () => {
        setProjectID(row.id);
        setDatePickerModal(true);
        setProjectName(row?.name);
      },
    },
    {
      key: "6",
      label: <span style={{ color: "red" }}>Delete</span>,
      onClick: () => handleDelete(row.id, row.name),
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
      ...getColumnSearchProps("name"),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ...getColumnSearchProps("description"),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      ...getColumnSearchProps("date"),
    },
    {
      title: "Contact Amount",
      dataIndex: "contact_amount",
      key: "contact_amount",
    },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
      key: "total_amount",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) => {
        console.log(status);
        return (
          <Tag
            color={
              status === "ongoing"
                ? "geekblue"
                : status === "complete"
                ? "success"
                : "gold"
            }
          >
            {status.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (row) => (
        <Space size="middle">
          <Dropdown overlay={<Menu items={generateMenuItems(row)} />}>
            <a>
              Actions{" "}
              <DownOutlined
                style={{
                  fontSize: "10px",
                }}
              />
            </a>
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="mt-[40px]">
        <div className=" flex space-x-[20px] place-items-center font-bold">
          <div className="">
            <FactCheckIconOutlined
              fontSize="100px"
              style={{
                height: "40px",
                width: "40px",
                color: "#334155",
              }}
              color="#334155"
            />
          </div>
          <p className="text-[30px]">All Projects</p>
        </div>
        <div className="mt-[30px]">
          <Table
            columns={columns}
            dataSource={projects}
            pagination={{
              pageSize: 5,
            }}
          />
        </div>
      </div>
      {datePickerModal && (
        <GeneralModal
          title={"Pick Start Date & End Date to see report"}
          onClose={() => setDatePickerModal(false)}
        >
          <div className="flex flex-col items-center p-4">
            <div className="mb-4">
              <label
                htmlFor="start-date"
                className="block text-[20px] font-medium text-gray-700"
              >
                Start Date
              </label>
              <input
                type="date"
                id="start-date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="mt-1 p-[5px] block w-full rounded-md border-black/60 border-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-[20px]"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="end-date"
                className="block text-[20px] font-medium text-gray-700"
              >
                End Date
              </label>
              <input
                type="date"
                id="end-date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="mt-1 p-[5px] block w-full rounded-md border-black/60 border-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-[20px]"
              />
            </div>
            <button
              onClick={() => setDatewiseShowExpenseReport(true)}
              className={`${submitButton} mt-3`}
            >
              Generate Report
            </button>
          </div>
        </GeneralModal>
      )}
      {visible && (
        <ReportModal title={"Report"} onClose={() => setVisible(false)}>
          <PdfReport project_id={projectID} />
        </ReportModal>
      )}
      {showExpenseReport && (
        <ReportModal
          title={"Expense Report"}
          onClose={() => setShowExpenseReport(false)}
        >
          <ExpenseReport project_id={projectID} project_name={projectName} />
        </ReportModal>
      )}
      {showDatewiseExpenseReport && (
        <ReportModal
          title={"Datewise Expense Report"}
          onClose={() => setDatewiseShowExpenseReport(false)}
        >
          <ProjectExpenseReport
            project_id={projectID}
            project_name={projectName}
            start_date={startDate}
            end_date={endDate}
          />
        </ReportModal>
      )}
      {/* Modal to assign engineer */}
      {modal && projectID && projectName && (
        <GeneralModal
          title={`Assign Engineer to ${projectName}`}
          onClose={toggleModal}
        >
          <AddAssignedEngineer
            projectID={projectID}
            onCancel={() => setProjectID(null)}
          />
        </GeneralModal>
      )}

      {/* Modal to edit project */}
      {editModal && projectID && projectName && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleEditModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex justify-between">
              <p className="text-xl text-blue-900 font-bold">
                Edit {projectName} -{" "}
                {/* <span className="text-warning">Ongoing</span> */}
              </p>
              <CloseIcon
                onClick={toggleEditModal}
                fontSize="1px"
                className={`hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
              />
            </div>
            <EditProject projectID={projectID} />
          </div>
        </div>
      )}

      {/* Modal to see details of a project */}
      {detailsModal && project && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleDetailsModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex justify-between">
              <p className="text-xl text-blue-900 font-bold">Project Details</p>
              <CloseIcon
                onClick={toggleDetailsModal}
                fontSize="1px"
                className={`hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
              />
            </div>

            <DetailsModalProject project={project} />
          </div>
        </div>
      )}
    </>
  );
};
export default AllProjects;
