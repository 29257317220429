import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import GeneralInput from "../../../components/GeneralInput";
import { submitButton } from "../../../components/DesignStandardize";
import { isAdminLoggedIn } from "../../../ApiUrlAndToken";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const validationSchema = Yup.object().shape({
  Name: Yup.string().required("Name is required"),
  Email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  Password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .nullable(),
  Contact: Yup.string().nullable(),
  Photo: Yup.mixed().nullable(),
});

const EditUserForm = ({ userId }) => {
  const formik = useFormik({
    initialValues: {
      Name: "",
      Email: "",
      Password: "123456",
      Contact: "",
      Photo: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        // Use FormData to handle file uploads
        const formData = new FormData();
        formData.append("Name", values.Name);
        formData.append("Email", values.Email);
        formData.append("Password", values.Password);
        formData.append("Contact", values.Contact);
        formData.append("Photo", values.Photo);

        // Send a PUT request to update user data
        await axios.put(`${apiUrl}/users/${userId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // console.log(response.data);
        toast.success("Employee Details Updated Successfully");
        setTimeout(() => {
          {
            isAdminLoggedIn
              ? (window.location.href = "/admin/employee-list")
              : (window.location.href = "/employee/projects");
          }
        }, 500);
      } catch (error) {
        // Error handling logic
        toast.error("Error updating employee details");
      }
    },
  });

  const handleFileChange = (event) => {
    formik.setFieldValue("Photo", event.currentTarget.files[0]);
  };

  // Fetch user data when the component mounts
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${apiUrl}/users/${userId}`);
        const user = response.data;
        console.log(user);
        // Set fetched user data as initial values
        formik.setValues({
          Name: user.Name || "",
          Email: user.Email || "",
          Password: "123456", // You might want to handle password separately
          Contact: user.Contact || "",
          Photo: null, // You might want to handle photo separately
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUser();
  }, [userId, formik.setValues]);

  return (
    <form
      className="p-2 flex flex-col max-h-96 overflow-y-auto gap-3 items-center w-full mt-auto md:mr-auto font-semibold font-Inter text-sm text-left md:text-base"
      onSubmit={formik.handleSubmit}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <div>
          <GeneralInput
            label="Employee Name"
            id="Name"
            name="Name"
            type="text"
            placeholder="Enter Employee Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.Name}
          />
          {formik.touched.Name && formik.errors.Name ? (
            <div>{formik.errors.Name}</div>
          ) : null}
        </div>

        <div>
          <GeneralInput
            label="Employee Email"
            id="Email"
            name="Email"
            type="email"
            placeholder="Enter Employee Email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.Email}
          />
          {formik.touched.Email && formik.errors.Email ? (
            <div>{formik.errors.Email}</div>
          ) : null}
        </div>

        <div>
          <GeneralInput
            label="Employee Contact"
            id="Contact"
            name="Contact"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.Contact}
            placeholder="Enter Employee Contact"
          />
          {formik.touched.Contact && formik.errors.Contact ? (
            <div>{formik.errors.Contact}</div>
          ) : null}
        </div>

        <div>
          <GeneralInput
            label="Employee Image"
            id="Photo"
            name="Photo"
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.Photo && formik.errors.Photo ? (
            <div>{formik.errors.Photo}</div>
          ) : null}
        </div>

        <div>
          <GeneralInput
            label="Employee Paswword"
            id="Password"
            name="Password"
            type="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.Password}
            placeholder="Enter Employee Password"
          />
          {formik.touched.Password && formik.errors.Password ? (
            <div>{formik.errors.Password}</div>
          ) : null}

          <p className="w-64 font-light text-slate-400 text-sm mt-2">
            Employee Password is set to "123456" by default
          </p>
        </div>
      </div>

      <button className={`${submitButton} mt-3`} type="submit">
        Update
      </button>
    </form>
  );
};

export default EditUserForm;
