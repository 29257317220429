import React, { useState } from "react";
import PageTitle from "../pageTitle/PageTitle";
import { hoverScale, submitButton } from "../../components/DesignStandardize";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { isAdminLoggedIn } from "../../ApiUrlAndToken";
import EngineerIcon from "@mui/icons-material/Engineering";
import GeneralModal from "../../components/GeneralModal";
import AddNewCustomer from "./components/AddNewCustomer";
import CustomerList from "./components/CustomerList";
import { useSelector } from "react-redux";

const Customer = () => {
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal((prevShowModal) => !prevShowModal);
  };
  const is_staff = useSelector((state) => state.global.is_staff);

  return (
    <>
      <PageTitle title="Business Partners" />
      <div className="py-3 h-screen">
        <CustomerList />
      </div>

      {/* Button to add new employee */}
      <>
        {isAdminLoggedIn && is_staff && (
          <div
            className={`w-max hidden md:block fixed bottom-2 right-2 hover:cursor-pointer font-Inter ${submitButton} ${hoverScale}`}
            onClick={toggleModal}
          >
            <EngineerIcon className="text-sm" /> Add Partner
          </div>
        )}
        {isAdminLoggedIn && is_staff && (
          <div
            className={`w-max fixed bottom-2 left-2 md:hidden hover:cursor-pointer font-Inter ${submitButton} ${hoverScale}`}
            onClick={toggleModal}
          >
            <EngineerIcon className="text-sm" /> Add Partner
          </div>
        )}
      </>

      {/* To Register New Employee */}
      {modal && (
        <GeneralModal title="Add New Customer" onClose={toggleModal}>
          <AddNewCustomer />
        </GeneralModal>
      )}
    </>
  );
};

export default Customer;
