import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import GeneralInput from "../../../components/GeneralInput";
import GeneralSelect from "../../../components/GeneralSelect";
import { submitButton } from "../../../components/DesignStandardize";
import { isAdminLoggedIn } from "../../../ApiUrlAndToken";
import { useSelector } from "react-redux";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Item Name is required"),
  description: Yup.string().required("Item description is required"),
  // item_type: Yup.string().required("Item Type is required"),
  price: Yup.string().required("Item Price is required"),
  // group: Yup.string().required("Group Name is required"),
  code: Yup.string().required("Code is required"),
});

const EditItemForm = ({ id }) => {
  const [photo, setPhoto] = useState(null);
  const [itemGroup, setItemGroup] = useState(null);
  const [itemGroups, setItemGroups] = useState([]);
  const [itemType, setItemType] = useState("");

  const jwt_token = useSelector((state) => state.global.jwt_token);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/itemgroup/`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        const retrived = response.data;
        retrived.forEach((element) => {
          // if (itemGroups.find((el) => el.id !== element.id)) {
          itemGroups.push({
            value: element.id,
            label: element.name,
          });
          // }
        });

        console.log(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchGroups();
  }, []);

  const ItemTypes = [
    { value: "local", label: "Local" },
    { value: "foreign", label: "Foreign" },
  ];

  const ownerID = window.localStorage.getItem("ownerID");
  const formik = useFormik({
    initialValues: {
      name: "",
      code: "",
      item_type: "",
      description: "",
      price: 1,
      group: null,
      picture: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("code", values.code);
        formData.append("item_type", itemType);
        formData.append("description", values.description);
        formData.append("price", values.price);
        formData.append("group", itemGroup);
        formData.append("picture", values.photo);
        formData.append("owner", ownerID);
        const response = await axios.put(
          `${apiUrl}/api/item/${id}/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
            },
          }
        );

        console.log(response);
        toast.success(`Edited Successfully`);
        setTimeout(() => {
          window.location.href = "/admin/items";
        }, 500);
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handlePhotoChange = (event) => {
    setPhoto(URL.createObjectURL(event.currentTarget.files[0]));
    formik.setFieldValue("item_photo", event.currentTarget.files[0]);
  };

  // Fetch user data when the component mounts
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/item/${id}`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        const res = response.data;
        console.log(res);
        setItemGroup(res.group);
        setItemType(res.item_type);
        // Set fetched user data as initial values
        formik.setValues({
          name: res.name || "",
          description: res.description || "",
          code: res.code || "",
          item_type: res.item_type || "",
          price: res.price || 1,
          group: itemGroup,
          picture: res.picture || "",
        });
        setPhoto(res.picture);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUser();
  }, [id, formik.setValues]);

  return (
    <form
      className="p-2 flex flex-col max-h-96 overflow-y-auto gap-3 items-center w-full mt-auto md:mr-auto font-semibold font-Inter text-sm text-left md:text-base"
      onSubmit={formik.handleSubmit}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        <div className="">
          <GeneralSelect
            options={itemGroups}
            label="Select Item Group" // Corrected from 'lable' to 'label'
            placeholder="Select Group"
            onChange={(selectedOption) => setItemGroup(selectedOption.value)}
            value={
              itemGroups.find((option) => option.value === itemGroup) || ""
            }
          />
          {/* {formik.touched.group && formik.errors.group ? (
            <div className="text-red-500 font-light">{formik.errors.group}</div>
          ) : null} */}
        </div>
        <div className="">
          <GeneralSelect
            options={ItemTypes}
            label="Select Item Type" // Corrected from 'lable' to 'label'
            placeholder="Select Type"
            onChange={(itemtype) => setItemType(itemtype.value)}
            value={ItemTypes.find((option) => option.value === itemType) || ""}
          />
          {/* {formik.touched.item_type && formik.errors.item_type ? (
            <div className="text-red-500 font-light">
              {formik.errors.item_type}
            </div>
          ) : null} */}
        </div>
        <div className="">
          <GeneralInput
            className={""}
            label="Item Name"
            id="name"
            name="name"
            type="text"
            placeholder="Enter Item Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="text-red-500 font-light">{formik.errors.name}</div>
          ) : null}
        </div>
        <div className="">
          <GeneralInput
            className={""}
            label="Item Description"
            id="description"
            name="description"
            type="text"
            placeholder="Enter Item Description"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description}
          />
          {formik.touched.description && formik.errors.description ? (
            <div className="text-red-500 font-light">
              {formik.errors.description}
            </div>
          ) : null}
        </div>
        <div className="">
          <GeneralInput
            className={""}
            label="Item Code"
            id="code"
            name="code"
            type="text"
            placeholder="Enter Item Code"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.code}
          />
          {formik.touched.code && formik.errors.code ? (
            <div className="text-red-500 font-light">{formik.errors.code}</div>
          ) : null}
        </div>
        <div className="">
          <GeneralInput
            className={""}
            label="Item Price"
            id="price"
            name="price"
            type="number"
            min={1}
            placeholder="Enter Item Price"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.price}
          />
          {formik.touched.price && formik.errors.price ? (
            <div className="text-red-500 font-light">{formik.errors.price}</div>
          ) : null}
        </div>

        {/* <div className="flex flex-col gap-2">
          <GeneralInput
            label={photo ? "Change Picture" : "Upload Picture"}
            id="photo"
            name="photo"
            type="file"
            accept="image/*"
            onChange={handlePhotoChange}
          />
          {photo && (
            <div className="flex justify-center items-center">
              <Zoom>
                <img
                  src={photo}
                  className="w-[320px] h-[140px] rounded-xl object-cover"
                  alt=""
                />
              </Zoom>
            </div>
          )}
        </div> */}
      </div>

      <button className={`${submitButton} mt-3`} type="submit">
        Update
      </button>
    </form>
  );
};

export default EditItemForm;
