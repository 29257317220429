import React, { useEffect, useState } from "react";
import {
  apiUrl,
  isAdminLoggedIn,
  isEmployeeLoggedIn,
} from "../../ApiUrlAndToken";
import AdminSessionMenu from "./components/AdminSessionMenu";
import EmployeeSessionMenu from "./components/EmployeeSessionMenu";
import InsideProjectTopNavbar from "./components/InsideProjectTopNavbar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { hoverScale } from "../../components/DesignStandardize";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { useParams } from "react-router-dom";
import DetailsModalProject from "../projects/components/DetailsModalProject";
import axios from "axios";
import EditProject from "../projects/components/EditProject";
import EditIcon from "@mui/icons-material/BorderColor";
import GeneralModal from "../../components/GeneralModal";
import Menu from "@mui/icons-material/Menu";
import { useSelector, useDispatch } from "react-redux";
import { drawerSwitch } from "../../datastore/slices/globaldata";
import SwitchForDarkTheme from "../../components/SwitchForDarkTheme";

const PageTitle = ({ title, insideProject }) => {
  const dispatch = useDispatch();
  const toggled = useSelector((state) => state.global.isDrawerSwitched);
  const id = useParams();
  const [project, setProject] = useState("");
  const [detailsModal, setDetailsModal] = useState(false);
  const toggleDetailsModal = () => {
    setDetailsModal((prevShowModal) => !prevShowModal);
  };
  const ProjectDetailsModal = (project) => {
    setProject(project);
    toggleDetailsModal();
  };

  const [editModal, setEditModal] = useState(false);
  const toggleModal = () => {
    setEditModal((prevShowModal) => !prevShowModal);
  };

  const getProjectByID = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/project/fetchProject/${id.ProjectID}`
      );
      setProject(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (id.ProjectID) getProjectByID();
  }, [id.ProjectID]);

  const darked = useSelector((state) => state.global.dark_theme);

  return (
    <>
      <div
        className={`
      font-Inter fixed top-0 place-content-center right-0 left-0 ${
        toggled ? "md:left-[80px]" : "md:left-[220px]"
      } ${
          darked ? "bg-slate-950" : "bg-white"
        }  shadow-md z-[1] h-auto lg:h-[80px] px-1 md:px-4 flex flex-row justify-between place-items-center
      `}
      >
        <div className="flex flex-row justify-between place-items-center w-full">
          <div className="flex flex-row flex-wrap gap-2">
            <button
              onClick={() => dispatch(drawerSwitch())}
              className="mr-[20px] bg-slate-100 rounded-full size-[50px]"
            >
              <Menu
                className="text-black transition-all duration-700"
                fontSize="large"
              />
            </button>
            <p className="text-left my-auto text-xl md:text-2xl font-semibold">
              {title}
            </p>
          </div>

          {/* <div className="absolute top-[20%] right-[5px]">
            <SwitchForDarkTheme />
          </div> */}
        </div>

        {/* <div className="block lg:hidden">
          {insideProject === 1 && <InsideProjectTopNavbar />}
        </div> */}
      </div>

      {/* Modal to see details of a project */}
      {detailsModal && project && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleDetailsModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex justify-between">
              <p className="text-xl text-blue-900 font-bold">
                {project.ProjectName} -{" "}
                <span
                  className={`${
                    project.Status === 0
                      ? "text-info"
                      : project.Status === 1
                      ? "text-warning"
                      : "text-success"
                  }`}
                >
                  {project.Status === 0
                    ? "Upcoming"
                    : project.Status === 1
                    ? "Ongoing"
                    : "Completed"}
                </span>
              </p>
              <CloseIcon
                onClick={toggleDetailsModal}
                fontSize="1px"
                className={`hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
              />
            </div>

            <DetailsModalProject project={project} />
          </div>
        </div>
      )}

      {/* modal edit project */}
      {editModal && (
        <GeneralModal title={`Edit ${id.ProjectName}`} onClose={toggleModal}>
          <EditProject projectID={id.ProjectID} />
        </GeneralModal>
      )}
    </>
  );
};

export default PageTitle;
