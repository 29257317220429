import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import GeneralInput from "../../../components/GeneralInput";
import { submitButton } from "../../../components/DesignStandardize";
import { isAdminLoggedIn } from "../../../ApiUrlAndToken";
import { useSelector } from "react-redux";
import GeneralSelect from "../../../components/GeneralSelect";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const validationSchema = Yup.object().shape({
  name: Yup.string().required("name is required"),
  contact: Yup.string().required("Contact is required"),
  code: Yup.string().required("Code is required"),
  address: Yup.mixed().required("Address is required"),
});

const EditPartnerForm = ({ id }) => {
  const jwt_token = useSelector((state) => state.global.jwt_token);
  const [customerType, setCustomerType] = useState("");
  const ownerID = window.localStorage.getItem("ownerID");
  const formik = useFormik({
    initialValues: {
      code: "",
      name: "",
      address: "",
      vendor_type: "",
      contact: "",
      currency: 1,
      photo: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("code", values.code);
        formData.append("vendor_type", values.vendor_type);
        formData.append("address", values.address);
        formData.append("contact", values.contact);
        formData.append("currency", values.currency);
        formData.append("picture", values.photo);
        formData.append("owner", ownerID);
        // Send a PUT request to update partner data
        await axios.put(`${apiUrl}/api/businesspartner/${id}/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        // console.log(response.data);
        toast.success("Partner Details Updated Successfully");
        setTimeout(() => {
          {
            isAdminLoggedIn
              ? (window.location.href = "/admin/customers")
              : (window.location.href = "/admin/customers");
          }
        }, 500);
      } catch (error) {
        // Error handling logic
        toast.error("Error updating partner details");
      }
    },
  });

  const handlePhotoChange = (event) => {
    formik.setFieldValue("photo", event.currentTarget.files[0]);
  };

  const CustomerTypes = [
    { value: "supplier", label: "Vendor" },
    { value: "customer", label: "Customer" },
  ];

  // Fetch partner data when the component mounts
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/businesspartner/${id}`,
          {
            headers: {
              Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
            },
          }
        );
        const partner = response.data;
        setCustomerType(partner.vendor_type);
        console.log(partner);
        // Set fetched partner data as initial values
        formik.setValues({
          code: partner.code || "",
          name: partner.name || "",
          vendor_type: customerType || partner.vendor_type,
          address: partner.address || "",
          currency: partner.currency || 1,
          contact: partner.contact || "",
          picture: null, // You might want to handle photo separately
        });
      } catch (error) {
        console.error("Error fetching partner data:", error);
      }
    };

    fetchUser();
  }, [id, formik.setValues]);

  return (
    <form
      className="p-2 flex flex-col max-h-96 overflow-y-auto gap-3 items-center w-full mt-auto md:mr-auto font-semibold font-Inter text-sm text-left md:text-base"
      onSubmit={formik.handleSubmit}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        <div>
          <GeneralInput
            label="Customer Name"
            id="name"
            name="name"
            type="text"
            placeholder="Enter Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="text-red-500 font-light">{formik.errors.name}</div>
          ) : null}
        </div>
        <div>
          <GeneralInput
            label="Customer Code"
            id="code"
            name="code"
            type="text"
            placeholder="Enter Code"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.code}
          />
          {formik.touched.code && formik.errors.code ? (
            <div className="text-red-500 font-light">{formik.errors.code}</div>
          ) : null}
        </div>
        <div className="">
          <GeneralSelect
            options={CustomerTypes}
            label="Select Customer Type" // Corrected from 'lable' to 'label'
            placeholder="Select Customer Type"
            onChange={(selectedOption) => setCustomerType(selectedOption.value)}
            value={
              CustomerTypes.find((option) => option.value === customerType) ||
              ""
            }
          />
          {formik.touched.type && formik.errors.type ? (
            <div className="text-red-500 font-light">{formik.errors.type}</div>
          ) : null}
        </div>
        <div>
          <GeneralInput
            label="Customer Contact"
            id="contact"
            name="contact"
            type="contact"
            placeholder="Enter Customer Contact"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.contact}
          />
          {formik.touched.contact && formik.errors.contact ? (
            <div className="text-red-500 font-light">
              {formik.errors.contact}
            </div>
          ) : null}
        </div>
        <div>
          <GeneralInput
            label="Customer Address"
            id="address"
            name="address"
            type="address"
            placeholder="Enter Customer Address"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address}
          />
          {formik.touched.address && formik.errors.address ? (
            <div className="text-red-500 font-light">
              {formik.errors.address}
            </div>
          ) : null}
        </div>
        {/* <div>
          <GeneralInput
            label="Customer Picture"
            id="photo"
            name="photo"
            type="file"
            accept="image/*"
            onChange={handlePhotoChange}
          />
          {formik.touched.photo && formik.errors.photo ? (
            <div className="text-red-500 font-light">{formik.errors.photo}</div>
          ) : null}
        </div> */}
      </div>

      <button className={`${submitButton} mt-3`} type="submit">
        Update
      </button>
    </form>
  );
};

export default EditPartnerForm;
