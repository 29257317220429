import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import GeneralInput from "../../../components/GeneralInput";
import GeneralSelect from "../../../components/GeneralSelect";
import { submitButton } from "../../../components/DesignStandardize";
import { isAdminLoggedIn } from "../../../ApiUrlAndToken";
import { useSelector } from "react-redux";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const EditItemForm = ({ id }) => {
  const [photo, setPhoto] = useState(null);
  const [paymentSource, setPaymentSource] = useState("");
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState("");
  const [description, setDescription] = useState("");
  const [totalAmount, setTotalAmount] = useState(null);
  const [bankaccounts, setBankAccounts] = useState([]);
  const [bankaccount, setBankAccount] = useState("");
  const jwt_token = useSelector((state) => state.global.jwt_token);
  const ownerID = window.localStorage.getItem("ownerID");
  console.log(ownerID);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/bank-accounts/`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        setBankAccounts(
          response.data.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        );
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchGroups();
  }, []);

  const getOnGoingProjects = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/project/`, {
        headers: {
          Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
        },
      });

      const retrived = response.data;
      setProjects(
        retrived.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getOnGoingProjects();
  }, []);

  const EditExpense = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("remarks", description);
    formData.append("totalAmount", totalAmount);
    formData.append("sales_order", project);
    formData.append("owner", ownerID);
    formData.append("payment_source", paymentSource);
    formData.append("bankaccount", bankaccount);
    if (photo) {
      formData.append("image", photo); // 'Upload' matches the server's expected field name
    }
    console.log(formData);
    try {
      const response = await axios.put(
        `${apiUrl}/api/incoming-payments/${id}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        }
      );

      console.log(response);
      toast.success(`Incoming Payment Updated`);
      setTimeout(() => {
        window.location.href = "/admin/incoming-payments";
      }, 500);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePhotoChange = (event) => {
    setPhoto(event.currentTarget.files[0]);
  };

  // Fetch user data when the component mounts
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/incoming-payments/${id}`,
          {
            headers: {
              Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
            },
          }
        );
        const res = response.data;
        console.log(res);
        setDescription(res.remarks);
        setProject(res.sales_order);
        setPaymentSource(res.payment_source);
        setTotalAmount(res.totalAmount);
        setBankAccount(res.bankaccount);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUser();
  }, [id]);

  return (
    <form className="p-2 flex flex-col max-h-96 overflow-y-auto gap-3 items-center w-full mt-auto md:mr-auto font-semibold font-Inter text-sm text-left md:text-base">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        <div>
          <GeneralSelect
            options={projects}
            label="Select Project" // Corrected from 'lable' to 'label'
            placeholder="Select Project"
            onChange={(selectedOption) => setProject(selectedOption.value)}
            value={projects.find((option) => option.value === project) || ""}
          />
        </div>
        <div>
          <GeneralSelect
            options={bankaccounts}
            label="Select Bank Account" // Corrected from 'lable' to 'label'
            placeholder="Select Bank Account"
            onChange={(selectedOption) => setBankAccount(selectedOption.value)}
            value={
              bankaccounts.find((option) => option.value === bankaccount) || ""
            }
          />
        </div>
        <div>
          <GeneralSelect
            options={[
              { value: "bank", label: "bank" },
              { value: "customer", label: "customer" },
              { value: "vendor", label: "vendor" },
            ]}
            label="Select Payment Source" // Corrected from 'lable' to 'label'
            placeholder="Select Payment Source"
            onChange={(selectedOption) =>
              setPaymentSource(selectedOption.value)
            }
            value={
              [
                { value: "bank", label: "bank" },
                { value: "customer", label: "customer" },
                { value: "vendor", label: "vendor" },
              ].find((option) => option.value === paymentSource) || ""
            }
          />
        </div>
        <div className="">
          <GeneralInput
            className={""}
            label="Incoming Payment Description"
            id="description"
            name="description"
            type="text"
            placeholder="Enter Incoming Payment Description"
            value={description}
            required
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className="">
          <GeneralInput
            className={""}
            label="Incoming Payment Amount"
            id="totalAmount"
            name="totalAmount"
            type="number"
            min={1}
            placeholder="Enter Amount"
            value={totalAmount}
            required
            onChange={(e) => setTotalAmount(e.target.value)}
          />
        </div>

        {/* <div>
          <GeneralInput
            label="Picture"
            id="photo"
            name="image"
            type="file"
            accept="image/*"
            onChange={handlePhotoChange}
          />
        </div> */}
      </div>

      <button
        className={`${submitButton} mt-3`}
        onClick={(e) => EditExpense(e)}
      >
        Update
      </button>
    </form>
  );
};

export default EditItemForm;
