import React, { useEffect, useState } from "react";
import DownloadIcon from "@mui/icons-material/DownloadOutlined";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const DownloadPurchaseReport = ({ reportData }) => {
  const [itemSummaryReport, setItemReport] = useState([]);
  const [vendorReport, setVendorReport] = useState([]);
  const getUserReportByID = async () => {
    try {
      setItemReport(
        reportData.item_summary.map((item) => ({
          "Item Name": item.item__name,
          "Total Quantity": item.total_quantity,
          "Total Amount": item.total_amount,
        }))
      );
      setVendorReport(
        reportData.vendor_performance.map((item) => ({
          "Business Partner": item.business_partner__name,
          "Total Items": item.total_items,
          "Total Amount": item.total_purchases,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadRevenueReport = () => {
    // Convert data to Excel format
    const worksheet = XLSX.utils.json_to_sheet(itemSummaryReport);
    console.log(itemSummaryReport);
    // Set column widths
    const columnWidths = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];

    worksheet["!cols"] = columnWidths;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Item Summary Report");

    // Generate a download
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(
      blob,
      `Item Summary Report ${reportData.start_date} to ${reportData.end_date}.xlsx`
    );
  };

  const handleDownloadPurchaseReport = () => {
    // Convert data to Excel format
    const worksheet = XLSX.utils.json_to_sheet(vendorReport);
    console.log(vendorReport);
    // Set column widths
    const columnWidths = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];

    worksheet["!cols"] = columnWidths;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Vendor Summary Report");

    // Generate a download
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(
      blob,
      `Vendor Summary Report ${reportData.start_date} to ${reportData.end_date}.xlsx`
    );
  };

  useEffect(() => {
    getUserReportByID();
  }, [reportData]);
  return (
    <div className="flex place-items-center space-x-3 my-[30px] justify-center items-center">
      <button
        onClick={handleDownloadRevenueReport}
        className="border border-black hover:bg-success rounded hover:text-white p-1"
      >
        Download Item Report <DownloadIcon />
      </button>
      <button
        onClick={handleDownloadPurchaseReport}
        className="border border-black hover:bg-success rounded hover:text-white p-1"
      >
        Download Vendor Report <DownloadIcon />
      </button>
    </div>
  );
};

export default DownloadPurchaseReport;
