import { DashboardOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import {
  FundProjectionScreenOutlined,
  DollarOutlined,
  TransactionOutlined,
  MergeCellsOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { apiUrl, isAdminLoggedIn } from "../../../ApiUrlAndToken";
import { useSelector } from "react-redux";

const cardStyle =
  "w-full md:w-[22.5%] h-[220px] text-black shadow-md px-5 flex flex-col space-y-4 justify-center";

function CountingFlow() {
  const [all_total, setAllTotal] = useState({});
  const [projects, setProjects] = useState([]);
  const jwt_token = useSelector((state) => state.global.jwt_token);
  const getAllTotals = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/reports/income-statement/`,
        {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        }
      );
      const responseTwo = await axios.get(`${apiUrl}/api/project/`, {
        headers: {
          Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
        },
      });

      const completeProjects = responseTwo?.data?.filter(
        (project) => project.status === "complete"
      );

      console.log(response.data);
      setProjects(completeProjects);
      setAllTotal(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllTotals();
  }, []);
  return (
    <div className="flex flex-col space-y-[20px] mb-[40px]">
      <div className=" flex space-x-[20px] place-items-center font-bold">
        <div className="">
          <DashboardOutlined
            style={{
              height: "40px",
              width: "40px",
              color: "#334155",
            }}
            color="#334155"
          />
        </div>
        <p className="text-[30px]">Dashboard</p>
      </div>
      <div className="flex flex-col md:flex-row flex-wrap gap-6 my-[20px] bg-white rounded-xl">
        <div className={`${cardStyle} bg-[#EEF0F8]`}>
          <div className="bg-[#2A308D]/40 size-[60px] rounded-2xl flex items-center justify-center">
            <FundProjectionScreenOutlined
              style={{
                fontSize: "30px",
                color: "#2A308D",
              }}
            />
          </div>
          <div>
            <p className="font-bold text-black text-[20px]">
              Completed Projects
            </p>
            <p className="text-[18px] font-light">{projects?.length} Project</p>
          </div>
        </div>
        <div className={`${cardStyle} bg-[#E6F2E2]`}>
          <div className="bg-[#92D268] size-[60px] rounded-2xl flex items-center justify-center">
            <DollarOutlined
              style={{
                fontSize: "30px",
                color: "#2A308D",
              }}
            />
          </div>
          <div>
            <p className="font-bold text-black text-[20px]">Total Reveneu</p>
            <p className="text-[18px] font-light">
              {all_total?.total_ar_invoice_amount ?? 0} BDT
            </p>
          </div>
        </div>
        <div className={`${cardStyle} bg-[#F8F0E7]`}>
          <div className="bg-[#E6AA69] size-[60px] rounded-2xl flex items-center justify-center">
            <MergeCellsOutlined
              style={{
                fontSize: "30px",
                color: "#2A308D",
              }}
            />
          </div>
          <div>
            <p className="font-bold text-black text-[20px]">Total Purchase</p>
            <p className="text-[18px] font-light">
              {all_total?.total_purchase_amount ?? 0} BDT
            </p>
          </div>
        </div>
        <div className={`${cardStyle} bg-[#F9E8E8]`}>
          <div className="bg-[#DC7B7B] size-[60px] rounded-2xl flex items-center justify-center">
            <TransactionOutlined
              style={{
                fontSize: "30px",
                color: "#2A308D",
              }}
            />
          </div>
          <div>
            <p className="font-bold text-black text-[20px]">Total Expense</p>
            <p className="text-[18px] font-light">
              {all_total?.total_expenses ?? 0} BDT
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CountingFlow;
