import React, { useEffect, useState } from "react";
import { HitApi } from "../../../utils/Api_Loader";

import CloseIcon from "@mui/icons-material/CloseOutlined";
import { hoverScale } from "../../../components/DesignStandardize";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const DetailsPurchaseProject = ({ purchase, toggleDetailsModal }) => {
  const [items, setItems] = useState([]);
  const [all_items, setAllItems] = useState([]);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await HitApi("/api/purchase-items/");
        setItems(response);
        console.log(response);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchGroups();
  }, []);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await HitApi("/api/item/");
        setAllItems(response);
        console.log(response);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchGroups();
  }, []);

  const handleDelete = (id) => {
    const updatedData = items.filter((item) => item.id !== id);
    setItems(updatedData);
  };

  return (
    <>
      <div className="z-[9999999999999] fixed inset-0 flex items-center justify-center">
        <div
          className="bg-black bg-opacity-50 fixed inset-0"
          onClick={toggleDetailsModal}
        />
        <div className="card text-black bg-white z-[9999999] p-4 modal-white flex flex-col gap-4">
          <div className="card-title flex justify-between">
            <p>Details</p>
            <CloseIcon
              onClick={toggleDetailsModal}
              fontSize="1px"
              className={`hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
            />
          </div>

          <div
            className={`flex z-[999999999999] flex-col justify-center gap-1 text-black`}
          >
            <div className="max-h-96 overflow-x-auto">
              <table className="table border-[1px] border-solid">
                <tbody className="">
                  {/* start date */}
                  <tr className="bg-white">
                    <th className="border-r-[2px] border-solid">Date</th>
                    <td className="">{purchase.date}</td>
                  </tr>
                  {/* description */}
                  <tr className="h-max">
                    <th className="border-r-[2px] border-solid">Description</th>
                    <td className="w-80">{purchase.remarks}</td>
                  </tr>
                  <tr className="h-max">
                    <th className="border-r-[2px] border-solid">
                      Total Amount
                    </th>
                    <td className="w-80">{purchase.total_amount}</td>
                  </tr>
                  {purchase.picture && (
                    <tr className="h-max">
                      <th className="border-r-[2px] border-solid">Image</th>
                      <td className="w-80">
                        <Zoom>
                          <img
                            src={purchase.picture}
                            className="w-full h-auto"
                          />
                        </Zoom>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <p className="font-bold pt-[20px] text-[20px] ml-[5px]">
                Item List
              </p>
              <div className="container mx-auto mt-[20px]">
                <table className="w-full bg-white">
                  <thead className="">
                    <tr className="bg-gray-200">
                      <th className="py-2 px-5 border-b border-gray-200">
                        Name
                      </th>
                      <th className="py-2 px-5 border-b border-gray-200">
                        Quantity
                      </th>
                      <th className="py-2 px-5 border-b border-gray-200">
                        Amount
                      </th>
                      {/* <th className="py-2 px-5 border-b border-gray-200">
                        Actions
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {items
                      .filter((data) => data.purchase === purchase.id)
                      .map((item) => (
                        <tr key={item.id}>
                          <td className="py-2 px-[24px] border-b border-gray-200">
                            {
                              all_items?.find((data) => data.id === item.item)
                                ?.name
                            }
                          </td>
                          <td className="py-2 px-[24px] border-b border-gray-200">
                            {item.quantity}
                          </td>
                          <td className="py-2 px-[24px] border-b border-gray-200">
                            {item.amount}
                          </td>
                          {/* <td className="py-2 px-[24px] border-b border-gray-200">
                            <button
                              className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                              onClick={() => handleDelete(item.id)}
                            >
                              Delete
                            </button>
                          </td> */}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailsPurchaseProject;
