import React, { useEffect, useState } from "react";
import GeneralInput from "../../../components/GeneralInput";
import GeneralSelect from "../../../components/GeneralSelect";
import { submitButton } from "../../../components/DesignStandardize";
import axios from "axios";
import { apiUrl, isAdminLoggedIn } from "../../../ApiUrlAndToken";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const EditProject = ({ projectID }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [total_amount, setTotalAmount] = useState("");
  const [contact_amount, setContactAmount] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [business_partner, setBusinessPartner] = useState("");
  const [file, setFile] = useState(null); // State to store the selected file
  const [business_partners, setBusinessPartners] = useState([]);
  const jwt_token = useSelector((state) => state.global.jwt_token);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/businesspartner/`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        const retrived = response.data;
        retrived.forEach((element) => {
          // if (business_partners.find((el) => el.id !== element.id)) {
          business_partners.push({
            value: element.id,
            label: element.name,
          });
          // }
        });

        console.log(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchGroups();
  }, []);

  const EditProjects = async (e) => {
    e.preventDefault();
    let statusValue;

    if (statusValue === -1) {
      toast.error("Invalid project status");
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("total_amount", total_amount);
    formData.append("contact_amount", contact_amount);
    formData.append("status", selectedStatus);
    formData.append("business_partner", business_partner);
    if (file) {
      formData.append("picture", file);
    }

    try {
      const response = await axios.put(
        `${apiUrl}/api/project/${projectID}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        }
      );
      toast.success("Project Updated Successfully!");
      console.log(response);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.log(error);
      if (error.response) {
        toast.error(error.response.data.status[0]);
      }
    }
  };

  // Fetch user data when the component mounts
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/project/${projectID}`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        const res = response.data;
        setName(res.name);
        setDescription(res.description);
        setTotalAmount(res.total_amount);
        setContactAmount(res.contact_amount);
        setSelectedStatus(res.status);
        setBusinessPartner(res.business_partner);
        console.log(res);
        // Set fetched user data as initial values
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUser();
  }, [projectID]);

  const ProjectStatus = [
    { value: "upcoming", label: "Upcoming" },
    { value: "ongoing", label: "Ongoing" },
    { value: "complete", label: "Completed" },
  ];

  return (
    <form
      className="p-1 flex flex-col gap-3 items-center min-h-max max-h-[440px] overflow-y-auto mt-auto md:mr-auto font-semibold font-Inter text-sm text-left md:text-base"
      onSubmit={EditProjects}
    >
      <div className="flex w-full flex-col md:flex-row gap-6 justify-center ">
        {/*  */}
        <div className="flex w-auto flex-col gap-3">
          <GeneralInput
            label="Project Name"
            type="text"
            placeholder="Enter Project Name"
            value={name}
            required
            className={""}
            onChange={(e) => setName(e.target.value)}
          />
          <GeneralSelect
            options={business_partners}
            label="Select Partner" // Corrected from 'lable' to 'label'
            placeholder="Select Partner"
            onChange={(selectedOption) =>
              setBusinessPartner(selectedOption.value)
            }
            value={
              business_partners.find(
                (option) => option.value === business_partner
              ) || ""
            }
          />

          <GeneralSelect
            options={ProjectStatus}
            label="Select Project Status" // Corrected from 'lable' to 'label'
            placeholder="Status"
            onChange={(selectedOption) =>
              setSelectedStatus(selectedOption.value)
            }
            value={
              ProjectStatus.find((option) => option.value === selectedStatus) ||
              ""
            }
          />
          <GeneralInput
            label="Add Image"
            type="file"
            onChange={(e) => setFile(e.target.files[0])}
          />
        </div>

        {/*  */}
        <div className="flex w-auto flex-col gap-3">
          <GeneralInput
            label="Project Details"
            type="textarea"
            placeholder="Enter Project Details"
            value={description}
            required
            onChange={(e) => setDescription(e.target.value)}
          />
          <GeneralInput
            label="Total Amount"
            type="number"
            min={1}
            value={total_amount}
            onChange={(e) => setTotalAmount(e.target.value)}
          />
          <GeneralInput
            label="Contact Amount"
            type="number"
            min={1}
            value={contact_amount}
            onChange={(e) => setContactAmount(e.target.value)}
          />
          {/* <GeneralInput
            label="Project Location"
            type="textarea"
            placeholder="Enter Project Location"
            value={location}
            required
            onChange={(e) => setLocation(e.target.value)}
          /> */}

          {/* <div>
            <GeneralInput
              label="Scope of Work"
              type="text"
              value={sow}
              onChange={(e) => setSow(e.target.value)}
              placeholder="Enter Scope of Work"
              addItems={1}
              buttonFuntion={handleClick}
              className={""}
            />
            <p className="text-sm my-1 text-slate-500 font-normal mx-1">
              Press Enter or + button
            </p>
            {itemsOfSow.length > 0 && (
              <ul
                className={`flex flex-row flex-wrap gap-1 w-[320px] h-auto bg-[#c4c4ec] -mt-7 pt-8 pb-3 px-3 py-4 rounded-b`}
              >
                {itemsOfSow.map((item, index) => (
                  <li
                    key={index}
                    className="bg-white text-black rounded-xl px-2 ring-1 font-normal"
                  >
                    {item}
                  </li>
                ))}
              </ul>
            )}
          </div> */}
        </div>
      </div>
      <button className={`${submitButton} mt-3`}>Update</button>
    </form>
  );
};

export default EditProject;
