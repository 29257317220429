import React, { useEffect, useState } from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const DetailsModal = ({ item }) => {
  return (
    <div
      className={`flex z-[999999999999] flex-col
        justify-center gap-1 text-black`}
    >
      <div className="max-h-96 overflow-x-auto">
        <table className="table border-[1px] border-solid">
          <tbody className="">
            {/* start date */}
            <tr className="bg-white">
              <th className="border-r-[2px] border-solid">Date</th>
              <td className="">{item.date}</td>
            </tr>
            {/* description */}
            <tr className="h-max">
              <th className="border-r-[2px] border-solid">Description</th>
              <td className="w-80">{item.description}</td>
            </tr>
            <tr className="h-max">
              <th className="border-r-[2px] border-solid">Total Amount</th>
              <td className="w-80">{item.totalAmount}</td>
            </tr>
            {item.image && (
              <tr className="h-max">
                <th className="border-r-[2px] border-solid">Image</th>
                <td className="w-80">
                  <Zoom>
                    <img src={item.image} className="w-full h-auto" />
                  </Zoom>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DetailsModal;
