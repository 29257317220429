export const submitButton =
  "text-white bg-[#334155] hover:bg-[#334155] focus:outline-none dark:focus:ring-blue-800 font-medium rounded-lg px-3 py-2";

export const hoverScale =
  "transition-transform duration-300 hover:cursor-pointer";

export const generalInputField =
  "input input-bordered input-primary w-full max-w-xs bg-base-50";

export const generalTableInputField = `px-3 py-1 text-sm flex-1 h-10 bg-transparent border w-24 md:w-36`;

export const generalTextAreaField =
  "textarea textarea-primary w-full max-w-xs bg-base-50";

export const inputEyeIcon =
  "cursor-pointer absolute right-2 top-2.5 text-slate-900";
