import React from "react";
import { apiUrl, isAdminLoggedIn } from "../../../ApiUrlAndToken";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const DetailsModalProject = ({ project }) => {
  return (
    <div className={`flex flex-col justify-center gap-1`}>
      <div className="flex flex-col gap-1">
        {project.picture && (
          <div>
            <img
              className={`w-full h-[300px] object-cover hover:cursor-pointer rounded-xl`}
              src={`${project.picture}`}
              alt="Profile"
            />
          </div>
        )}
      </div>
      <div className="max-h-96 overflow-x-auto">
        <table className="table border-[1px] border-solid">
          <tbody className="">
            <tr className="bg-base-200">
              <th className="border-r-[2px] border-solid">Name</th>
              <td className="">{project.name}</td>
            </tr>
            {/* description */}
            <tr className="h-max bg-base-200">
              <th className="border-r-[2px] border-solid">Description</th>
              <td className="w-80">{project.description}</td>
            </tr>
            {/* start date */}
            <tr className="bg-base-200">
              <th className="border-r-[2px] border-solid">Date</th>
              <td className="">{project.date}</td>
            </tr>
            <tr className="bg-base-200">
              <th className="border-r-[2px] border-solid">Status</th>
              <td className="">{project.status}</td>
            </tr>
            <tr className="bg-base-200">
              <th className="border-r-[2px] border-solid">Total Amount</th>
              <td className="">{project.total_amount}</td>
            </tr>
            <tr className="bg-base-200">
              <th className="border-r-[2px] border-solid">Partner</th>
              <td className="">{project.business_partner}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DetailsModalProject;
