import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ["Expense", "Revenue", "Purchase"],
  datasets: [
    {
      data: [12, 19, 13],
      backgroundColor: ["#DC7B7B", "#33ABFB", "#E6AA69"],
      borderColor: ["#DC7B7B", "#33ABFB", "#E6AA69"],
      borderWidth: 1,
    },
  ],
};

export function DoughnutChart() {
  return (
    <div className="bg-gray-50 p-5 rounded-xl h-auto w-full mt-[40px]">
      <div>
        <p className="font-bold text-[20px] text-black mb-[10px]">
          Pichart Report
        </p>
      </div>
      <Doughnut data={data} className="opacity-[0.7]" />
    </div>
  );
}
