import React, { useState, useEffect } from "react";
import {
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
  LogoutOutlined,
  DatabaseOutlined,
  CreditCardOutlined,
  FileOutlined,
  ProfileOutlined,
  PicCenterOutlined,
  BankOutlined,
} from "@ant-design/icons";
import { Button, Menu } from "antd";
import { useSelector } from "react-redux";
import dTechLogo from "../assets/img/dtechLogo.png";
import { useNavigate, useLocation } from "react-router-dom";
import ReportModal from "./ReportModal";
import AllExpense from "./AllExpenseReportPdf";
import { submitButton } from "./DesignStandardize";
import GeneralModal from "./GeneralModal";
import { isAdminLoggedIn, isEmployeeLoggedIn } from "../ApiUrlAndToken";

const Drawer = () => {
  const items = [
    {
      key: "1",
      icon: <PieChartOutlined />,
      label: "Dashboard",
      path: "/admin/dashboard",
    },
    {
      key: "2",
      icon: <DesktopOutlined />,
      label: "Business Partner",
      path: "/admin/customers",
    },
    {
      key: "sub1",
      label: "Item",
      icon: <PieChartOutlined />,
      children: [
        {
          key: "5",
          label: "Items",
          path: "/admin/items",
        },
        {
          key: "6",
          label: "Item Groups",
          path: "/admin/item-groups",
        },
      ],
    },
    {
      key: "18",
      label: "Accountant Actions",
      type: "group",
    },
    {
      key: "3",
      icon: <CreditCardOutlined />,
      label: "Reveneu",
      path: "/admin/revenue",
    },
    {
      key: "sub2",
      label: "Purchase",
      icon: <DatabaseOutlined />,
      children: [
        {
          key: "9",
          label: "Purchase List",
          path: "/admin/purchase-list",
        },
        {
          key: "10",
          label: "New Purchase",
          path: "/admin/purchase",
        },
      ],
    },
    {
      key: "sub3",
      label: "Others Expense",
      icon: <AppstoreOutlined />,
      children: [
        {
          key: "112",
          label: "Expense Categories",
          path: "/admin/extra-expense-categories",
        },
        {
          key: "11",
          label: "Others Expense",
          path: "/admin/extra-expense-list",
        },
      ],
    },
    {
      key: "19",
      label: "Voucher and Reports",
      type: "group",
    },
    {
      key: "4",
      label: "Voucher",
      icon: <ProfileOutlined />,
      path: "/admin/vouchers",
    },
    {
      key: "sub5",
      label: "Banking",
      icon: <BankOutlined />,
      children: [
        {
          key: "45",
          label: "Banks",
          path: "/admin/banks",
        },
        {
          key: "22",
          label: "Bank Accounts",
          path: "/admin/bank-accounts",
        },
        {
          key: "23",
          label: "Incoming Payments",
          path: "/admin/incoming-payments",
        },
        {
          key: "24",
          label: "Outgoing Payments",
          path: "/admin/outgoing-payments",
        },
      ],
    },
    {
      key: "sub4",
      label: "Reports",
      icon: <FileOutlined />,
      children: [
        {
          key: "12",
          label: "Expense Report",
          onClick: () => setDatePickerModal(true),
        },
        {
          key: "13",
          label: "My Report",
          path: "/admin/my-report",
        },
        {
          key: "14",
          label: "Revenue Report",
          path: "/admin/ar-invoice-report",
        },
        {
          key: "15",
          label: "Purchase Report",
          path: "/admin/purchase-report",
        },
        {
          key: "16",
          label: "Purchase Item Report",
          path: "/admin/purchase-item-report",
        },
        {
          key: "17",
          label: "Balance Sheet Report",
          path: "/admin/balance-sheet-report",
        },
        {
          key: "44",
          label: "All Projects Report",
          path: "/admin/all-projects-report",
        },
      ],
    },
    {
      key: "46",
      label: "Generate Custom Report",
      type: "group",
    },
    {
      key: "47",
      label: "Generate Report",
      icon: <PicCenterOutlined />,
      onClick: () => {
        window.open(process.env.REACT_APP_API_BASE_URL, "_blank");
      },
    },
    {
      key: "21",
      label: "Sign Out",
      type: "group",
    },
    {
      key: "20",
      label: "Logout",
      icon: <LogoutOutlined />,
      onClick: () => {
        localStorage.removeItem("token");
        localStorage.removeItem("is_staff");
        localStorage.removeItem("ownerID");
        if (isEmployeeLoggedIn) {
          localStorage.removeItem("employeeToken");
        } else if (isAdminLoggedIn) {
          localStorage.removeItem("adminLoggedIn");
        }
        window.location.href = "/";
      },
      style: { color: "red" },
    },
  ];

  const toggled = useSelector((state) => state.global.isDrawerSwitched);
  const navigate = useNavigate();
  const location = useLocation();
  const is_staff = useSelector((state) => state.global.is_staff);
  const [showAllExpense, setShowAllExpenseReport] = useState(false);
  const [datePickerModal, setDatePickerModal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedKey, setSelectedKey] = useState("");

  const handleClick = (data) => {
    const item = items
      .flatMap((item) => (item.children ? [item, ...item.children] : item))
      .find((item) => item.key === data.key);
    if (item) {
      navigate(item.path);
      setSelectedKey(item.key);
    }
    if (item.onClick) {
      item.onClick();
    }
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const currentItem = items
      .flatMap((item) => (item.children ? [item, ...item.children] : item))
      .find((item) => item.path === currentPath);
    if (currentItem) {
      setSelectedKey(currentItem.key);
    }
  }, [location.pathname]);

  return (
    <>
      <div
        style={{
          width: 220,
          height: "100vh",
          zIndex: 1,
        }}
        className={`fixed left-0 top-0 ${
          toggled ? "overflow-hidden" : "overflow-y-scroll"
        } h-[100vh] font-semibold bg-transparent`}
      >
        <div className="hidden md:flex bg-white justify-center items-center my-[30px] pt-[100px] md:pt-0">
          <img
            src={dTechLogo}
            alt="Welcme to DTech Corp."
            class={`my-auto h-[70px] w-[140px] ${toggled ? "hidden" : "flex"}`}
          />
        </div>
        <Menu
          defaultSelectedKeys={[selectedKey]}
          selectedKeys={[selectedKey]}
          mode="inline"
          theme="light"
          inlineCollapsed={toggled}
          onClick={handleClick}
          items={items.map((item) => ({
            ...item,
            onClick: handleClick,
          }))}
          className={`${toggled && "mt-[30%]"} mt-[30%] md:mt-0 pb-[100%]`}
        />
      </div>
      {datePickerModal && (
        <GeneralModal
          title={"Pick Start Date & End Date to see report"}
          onClose={() => setDatePickerModal(false)}
        >
          <div className="flex flex-col items-center p-4">
            <div className="mb-4">
              <label
                htmlFor="start-date"
                className="block text-[20px] font-medium text-gray-700"
              >
                Start Date
              </label>
              <input
                type="date"
                id="start-date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="mt-1 p-[5px] block w-full rounded-md border-black/60 border-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-[20px]"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="end-date"
                className="block text-[20px] font-medium text-gray-700"
              >
                End Date
              </label>
              <input
                type="date"
                id="end-date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="mt-1 p-[5px] block w-full rounded-md border-black/60 border-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-[20px]"
              />
            </div>
            <button
              onClick={() => setShowAllExpenseReport(true)}
              className={`${submitButton} mt-3`}
            >
              Generate Report
            </button>
          </div>
        </GeneralModal>
      )}
      {showAllExpense && (
        <ReportModal
          title={"Datewise All Expense Report"}
          onClose={() => setShowAllExpenseReport(false)}
        >
          <AllExpense start_date={startDate} end_date={endDate} />
        </ReportModal>
      )}
    </>
  );
};
export default Drawer;
