import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { apiUrl, isAdminLoggedIn } from "../../../ApiUrlAndToken";
import GeneralInput from "../../../components/GeneralInput";
import GeneralSelect from "../../../components/GeneralSelect";
import { submitButton } from "../../../components/DesignStandardize";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const RevenueAdd = () => {
  const navigate = useNavigate();
  const [description, setDescription] = useState("");
  const [total_amount, setTotalAmount] = useState("");
  const [project, setProject] = useState("");
  const [file, setFile] = useState(null);
  const [projects, setProjects] = useState([]);

  const jwt_token = useSelector((state) => state.global.jwt_token);

  const getOnGoingProjects = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/project/`, {
        headers: {
          Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
        },
      });

      const retrived = response.data;
      setProjects(
        retrived.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getOnGoingProjects();
  }, []);

  const AddProjects = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("remarks", description);
    formData.append("total_amount", total_amount);
    formData.append("sales_order", project);
    if (file) {
      formData.append("picture", file); // 'Upload' matches the server's expected field name
    }

    try {
      const response = await axios.post(
        `${apiUrl}/api/ar-invoices/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        }
      );
      toast.success("Revenue Added Successfully!");
      console.log(response);
      setTimeout(() => {
        navigate(0);
      }, 500);
    } catch (error) {
      console.log(error);
      if (error.response) {
        toast.error(`${error.response.data.message}`);
      } else if (error.request) {
        toast.error("No response received from the server");
      } else {
        toast.error("An error occurred while processing the request");
      }
    }
  };

  return (
    <div className="p-1 w-full flex flex-col gap-3 min-h-max max-h-[480px] overflow-y-auto mt-auto md:mr-auto font-semibold font-Inter text-sm text-left md:text-base">
      <div className="flex w-full flex-col md:flex-row gap-6 justify-center ">
        {/*  */}
        <div className="flex w-auto flex-col gap-3">
          <GeneralInput
            label="Invoice Details"
            type="textarea"
            placeholder="Enter Invoice Details"
            value={description}
            required
            onChange={(e) => setDescription(e.target.value)}
          />
          <GeneralInput
            label="Total Amount"
            type="number"
            min={1}
            value={total_amount}
            onChange={(e) => setTotalAmount(e.target.value)}
          />
        </div>

        {/*  */}
        <div className="flex w-auto flex-col gap-3">
          <GeneralInput
            label="Add Image"
            type="file"
            onChange={(e) => setFile(e.target.files[0])}
          />
          <GeneralSelect
            options={projects}
            label="Select Project" // Corrected from 'lable' to 'label'
            placeholder="Select Project"
            onChange={(selectedOption) => setProject(selectedOption.value)}
            value={projects.find((option) => option.value === project) || ""}
          />
        </div>
      </div>

      <button
        className={`${submitButton} mt-3 mx-auto`}
        type="submit"
        onClick={AddProjects}
      >
        Submit
      </button>
    </div>
  );
};

export default RevenueAdd;
