import React, { useState, useEffect } from "react";
import dtechLogo from "../../assets/img/dtechLogo.png";
import Roadmap from "../../components/Roadmap";
import EmployeeLogin from "./components/EmployeeLogin";
import AdminLogin from "./components/AdminLogin";
import { isAdminLoggedIn, isEmployeeLoggedIn } from "../../ApiUrlAndToken";
import { Player } from "@lottiefiles/react-lottie-player";

const Auth = () => {
  const [loading, setLoading] = useState(true);
  const [toggleState, setToggleState] = useState(1);
  const changeTab = (tabs) => {
    setToggleState(tabs);
  };
  useEffect(() => {
    localStorage.removeItem("jwtToken");
    if (isEmployeeLoggedIn) {
      localStorage.removeItem("employeeToken");
      // console.log("employee token removed");
    } else if (isAdminLoggedIn) {
      localStorage.removeItem("adminToken");
      // console.log("admin token removed");
    }

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [isAdminLoggedIn, isEmployeeLoggedIn]);

  return (
    <div className="h-screen flex flex-col gap-3 items-center justify-center bg-blue-100">
      {loading ? (
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid" />
      ) : (
        <>
          <img
            src={dtechLogo}
            alt="DTech Corp. LTD"
            className="h-[100px] mb-[35px]"
          />
          {/* <div
            role="tablist"
            className="tabs tabs-boxed text-[28px] mb-3 w-max mx-auto font-bold"
          >
            <p
              role="tab"
              className={`tab bg-blue-100 ${toggleState === 1 && "tab-active"}`}
              onClick={() => {
                changeTab(1);
              }}
            >
              Log In
            </p>
          </div> */}
          <div className="flex flex-col md:flex-row gap-3 w-4/5 lg:w-3/5 lg:justify-around">
            <div className="flex flex-col gap-3 my-auto">
              <p className="text-lg font-semibold text-blue-900">
                Manage Your Accounts Here
              </p>

              <Player
                autoplay
                loop
                src="https://lottie.host/15cd71e8-2145-4c7f-8f1c-6322d60fdccc/PzzsWJEr5Q.json"
                style={{ height: "300px", width: "300px" }}
              ></Player>
            </div>

            {/* Tabs */}
            <div className="flex flex-col gap-3 items-center">
              {toggleState === 1 ? <AdminLogin /> : <EmployeeLogin />}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Auth;
