import React, { useEffect, useState } from "react";
import GeneralInput from "../../../components/GeneralInput";
import GeneralSelect from "../../../components/GeneralSelect";
import { submitButton } from "../../../components/DesignStandardize";
import axios from "axios";
import { apiUrl, isAdminLoggedIn } from "../../../ApiUrlAndToken";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const EditPurchase = ({ purchaseID }) => {
  const [description, setDescription] = useState("");
  const [total_amount, setTotalAmount] = useState("");
  const [business_partner, setBusinessPartner] = useState("");
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [business_partners, setBusinessPartners] = useState([]);
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState("");

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/businesspartner/`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        const retrived = response.data;
        retrived.forEach((element) => {
          business_partners.push({
            value: element.id,
            label: element.name,
          });
        });

        console.log(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchGroups();
  }, []);

  const jwt_token = useSelector((state) => state.global.jwt_token);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/businesspartner/${purchaseID}/`,
          {
            headers: {
              Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
            },
          }
        );
        const retrived = response.data;
        if (Array.isArray(retrived)) {
          retrived.forEach((element) => {
            business_partners.push({
              value: element.id,
              label: element.name,
            });
          });
        } else {
          console.error("Error: Retrieved data is not an array");
        }

        console.log(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchGroups();
  }, []);

  const EditInvoice = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("remarks", description);
    formData.append("total_amount", total_amount);
    formData.append("business_partner", business_partner);
    formData.append("sales_order", project);
    if (file) {
      formData.append("picture", file);
    }

    try {
      const response = await axios.put(
        `${apiUrl}/api/purchases/${purchaseID}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        }
      );
      toast.success("Purchase Edited Successfully!");
      console.log(response);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.log(error);
      if (error.response) {
        toast.error(`${error.response.data.message}`);
      } else if (error.request) {
        toast.error("No response received from the server");
      } else {
        toast.error("An error occurred while processing the request");
      }
    }
  };

  // Fetch user data when the component mounts
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/purchases/${purchaseID}/`,
          {
            headers: {
              Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
            },
          }
        );
        const res = response.data;
        setDescription(res.remarks);
        setTotalAmount(res.total_amount);
        setBusinessPartner(res.business_partner);
        setProject(res.sales_order);
        setFile(res.picture);
        setFilePreview(res.picture);
        console.log(res);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUser();
  }, [purchaseID]);

  const getOnGoingProjects = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/project/`, {
        headers: {
          Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
        },
      });

      const retrived = response.data;
      setProjects(
        retrived.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getOnGoingProjects();
  }, []);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFilePreview(URL.createObjectURL(selectedFile));
  };

  return (
    <form
      className="p-1 flex flex-col gap-3 items-center min-h-max max-h-[440px] overflow-y-auto mt-auto md:mr-auto font-semibold font-Inter text-sm text-left md:text-base"
      onSubmit={EditInvoice}
    >
      <div className="flex w-full flex-col md:flex-row gap-6 justify-center ">
        <div className="flex w-auto flex-col gap-3">
          <GeneralInput
            label="Purchase Details"
            type="textarea"
            placeholder="Enter Invoice Details"
            value={description}
            required
            onChange={(e) => setDescription(e.target.value)}
          />
          <GeneralInput
            label="Total Amount"
            type="number"
            min={1}
            value={total_amount}
            readOnly={true}
          />

          <GeneralSelect
            options={business_partners}
            label="Select Partner"
            placeholder="Select Partner"
            onChange={(selectedOption) =>
              setBusinessPartner(selectedOption.value)
            }
            value={
              business_partners.find(
                (option) => option.value === business_partner
              ) || ""
            }
          />
          <GeneralSelect
            options={projects}
            label="Select Project"
            placeholder="Select Project"
            onChange={(selectedOption) => setProject(selectedOption.value)}
            value={projects.find((option) => option.value === project) || ""}
          />
        </div>

        <div className="flex w-auto flex-col gap-3">
          {filePreview && (
            <div>
              <Zoom>
                <img
                  src={filePreview}
                  alt="Current"
                  className="w-[200px] h-[300px] mb-2 object-cover"
                />
              </Zoom>
              <GeneralInput
                label="Change Image"
                type="file"
                onChange={handleFileChange}
              />
            </div>
          )}
          {!filePreview && (
            <GeneralInput
              label="Add Image"
              type="file"
              onChange={handleFileChange}
            />
          )}
        </div>
      </div>
      <button className={`${submitButton} mt-3`}>Update</button>
    </form>
  );
};

export default EditPurchase;
