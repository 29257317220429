import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import GeneralInput from "../../../components/GeneralInput";
import GeneralSelect from "../../../components/GeneralSelect";
import { submitButton } from "../../../components/DesignStandardize";
import { isAdminLoggedIn } from "../../../ApiUrlAndToken";
import { useSelector } from "react-redux";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const EditItemForm = ({ id }) => {
  const [photo, setPhoto] = useState(null);
  const [name, setName] = useState("");
  const [expenseCategories, setExpenseCategories] = useState([]);
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState("");
  const [description, setDescription] = useState("");
  const [totalAmount, setTotalAmount] = useState(null);
  const [existingImage, setExistingImage] = useState(null);

  const jwt_token = useSelector((state) => state.global.jwt_token);
  const ownerID = window.localStorage.getItem("ownerID");
  console.log(ownerID);

  const getOnGoingProjects = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/project/`, {
        headers: {
          Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
        },
      });

      const retrived = response.data;
      setProjects(
        retrived.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getOnGoingProjects();
  }, []);

  const EditExpense = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("description", description);
    formData.append("totalAmount", totalAmount);
    formData.append("name", name);
    formData.append("sales_order", project);
    formData.append("owner", ownerID);
    if (photo) {
      formData.append("image", photo); // 'Upload' matches the server's expected field name
    }
    console.log(formData);
    try {
      const response = await axios.put(
        `${apiUrl}/api/voucher/${id}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        }
      );

      console.log(response);
      toast.success(`New Item Registered`);
      setTimeout(() => {
        window.location.href = "/admin/vouchers";
      }, 500);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePhotoChange = (event) => {
    setPhoto(event.currentTarget.files[0]);
  };

  // Fetch user data when the component mounts
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/voucher/${id}`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        const res = response.data;
        console.log(res);
        setName(res.name);
        setProject(res.sales_order);
        setDescription(res.description);
        setTotalAmount(res.totalAmount);
        setExistingImage(res.image);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUser();
  }, [id]);

  return (
    <form className="p-2 flex flex-col max-h-96 overflow-y-auto gap-3 items-center w-full mt-auto md:mr-auto font-semibold font-Inter text-sm text-left md:text-base">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        <div className="">
          <GeneralInput
            className={""}
            label="Voucher Name"
            id="name"
            name="name"
            type="text"
            placeholder="Enter Voucher Name"
            value={name}
            required
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <GeneralSelect
            options={projects}
            label="Select Project" // Corrected from 'lable' to 'label'
            placeholder="Select Project"
            onChange={(selectedOption) => setProject(selectedOption.value)}
            value={projects.find((option) => option.value === project) || ""}
          />
        </div>
        <div className="">
          <GeneralInput
            className={""}
            label="Voucher Description"
            id="description"
            name="description"
            type="text"
            placeholder="Enter Voucher Description"
            value={description}
            required
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className="">
          <GeneralInput
            className={""}
            label="Voucher Amount"
            id="totalAmount"
            name="totalAmount"
            type="number"
            min={1}
            placeholder="Enter Voucher Amount"
            value={totalAmount}
            required
            onChange={(e) => setTotalAmount(e.target.value)}
          />
        </div>

        <div>
          <GeneralInput
            label={existingImage ? "Change Picture" : "Invoice Picture"}
            id="photo"
            name="image"
            type="file"
            accept="image/*"
            onChange={handlePhotoChange}
          />
        </div>

        {existingImage && (
          <div className="flex flex-col items-start">
            <label className="font-semibold">Existing Image</label>
            <Zoom>
              <img
                src={existingImage}
                alt="Existing Voucher"
                className="size-[200px] rounded-xl object-cover"
              />
            </Zoom>
          </div>
        )}
      </div>

      <button
        className={`${submitButton} mt-3`}
        onClick={(e) => EditExpense(e)}
      >
        Update
      </button>
    </form>
  );
};

export default EditItemForm;
