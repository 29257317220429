import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl, isAdminLoggedIn } from "../../../ApiUrlAndToken";
import { hoverScale } from "../../../components/DesignStandardize";
import EditIcon from "@mui/icons-material/BorderColor";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import ProjectsIcon from "@mui/icons-material/HomeWorkOutlined";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import EditItemGroupForm from "./EditItemGroup";
import SearchInput from "../../../components/SearchInput";
import { Table } from "../../../components/Table";
import GeneralModal from "../../../components/GeneralModal";
import { Descriptions } from "antd";

const ItemGroupsList = () => {
  const [itemgroups, setItemGroups] = useState([]);
  const [editingGroupId, setEditingGroupId] = useState(null);
  const [projectModalID, setProjectModalID] = useState(null);
  const [viewingGroup, setViewingGroup] = useState(null);
  const jwt_token = useSelector((state) => state.global.jwt_token);
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/itemgroup/`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        setItemGroups(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchGroups();
  }, []);

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal((prevShowModal) => !prevShowModal);
  };
  const [projectModal, setProjectModal] = useState(false);
  const toggleProjectModal = () => {
    setProjectModal(!projectModal);
  };
  const ProjectFetch = (userID) => {
    setProjectModalID(userID);
    toggleProjectModal();
  };
  const handleEdit = (id) => {
    setEditingGroupId(id);
    toggleModal();
  };

  const handleDelete = async (id, name) => {
    if (window.confirm(`Are you sure you want to remove ${name}?`)) {
      try {
        await axios.delete(`${apiUrl}/api/itemgroup/${id}/`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        const response = await axios.get(`${apiUrl}/api/itemgroup/`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        setItemGroups(response.data);
        toast.warning("Group Deleted Successfully");
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    }
  };

  const handleView = (group) => {
    setViewingGroup(group);
  };

  const closeViewModal = () => {
    setViewingGroup(null);
  };

  return (
    <>
      <div className="h-screen pb-[200px]">
        <SearchInput
          onChange={(e) => setSearchString(e.target.value)}
          value={searchString}
        />

        <div className="mt-[30px]">
          <Table
            items={itemgroups}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            searchString={searchString}
            handleView={handleView}
          />
        </div>

        {/* <ul className="flex flex-row flex-wrap gap-4 md:gap-6 font-Inter mt-12">
          {itemgroups
            .filter((data) =>
              data.name.toLowerCase().includes(searchString.toLowerCase())
            )
            .map((group, index) => (
              <li
                key={index}
                className="w-[200px] h-[240px] bg-slate-800 text-white p-4 flex flex-col gap-2 hover:shadow-md rounded-xl border border-solid"
              >
                <div className="h-[80%] text-[20px] text-left">
                  <p>
                    <strong>Name:</strong> {group.name}
                  </p>

                  <p>
                    <strong>Description:</strong> {group.description}
                  </p>
                </div>
                <div className="flex flex-row items-end  gap-5 justify-between">
                  {isAdminLoggedIn && (
                    <div className="flex flex-row gap-3">
                      <button
                        onClick={() => handleEdit(group.id)}
                        className={`mb-auto w-[80px] hover:text-blue-500 hover:cursor-pointer flex items-center justify-center rounded-full text-white space-x-2 hover:bg-slate-800 bg-slate-900  p-2  place-items-center place-content-center ${hoverScale}`}
                      >
                        <EditIcon fontSize="1px" />
                        <span className="font-semibold">Edit</span>
                      </button>
                      <button
                        onClick={() => handleDelete(group.id, group.name)}
                        className={`mb-auto w-[80px] hover:cursor-pointer flex items-center justify-center rounded-full text-black ${hoverScale} hover:bg-red-500 w-auto bg-red-700 rounded-2xl text-white p-2 flex place-items-center place-content-center`}
                      >
                        <DeleteIcon fontSize="1px" />
                        <span className="font-semibold">Delete</span>
                      </button>
                    </div>
                  )}
                </div>
              </li>
              
            ))}
        </ul> */}
      </div>

      {modal && editingGroupId && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex justify-between">
              <p className="text-xl text-blue-900 font-bold">
                Edit Item Details
              </p>
              <CloseIcon
                onClick={toggleModal}
                fontSize="1px"
                className={`hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
              />
            </div>
            <EditItemGroupForm
              id={editingGroupId}
              onCancel={() => setEditingGroupId(null)}
            />
          </div>
        </div>
      )}
      {projectModal && projectModalID && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleProjectModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex justify-between">
              <p className="text-xl text-blue-900 font-bold">
                See Individual Projects
              </p>
              <CloseIcon
                onClick={toggleProjectModal}
                fontSize="1px"
                className={`hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
              />
            </div>
          </div>
        </div>
      )}
      {viewingGroup && (
        <GeneralModal title={"Group Details"} onClose={closeViewModal}>
          <Descriptions bordered className="flex flex-col">
            <Descriptions.Item label="ID">{viewingGroup.id}</Descriptions.Item>
            <Descriptions.Item label="Name">
              {viewingGroup.name}
            </Descriptions.Item>
            <Descriptions.Item label="Description">
              {viewingGroup.description}
            </Descriptions.Item>
            <Descriptions.Item label="Owner">
              {viewingGroup.owner}
            </Descriptions.Item>
          </Descriptions>
        </GeneralModal>
      )}
    </>
  );
};

export default ItemGroupsList;
