import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
  Svg,
  Path,
} from "@react-pdf/renderer";
import axios from "axios";
import { useEffect, useState } from "react";
import { apiUrl, isAdminLoggedIn } from "../ApiUrlAndToken";
import { useSelector } from "react-redux";
import dTechLogo from "../assets/img/dtech.png";
// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: "black",
  },
  section: {
    margin: 2,
    padding: 2,
    flexDirection: "row",
    fontSize: "18px",
    textAlign: "center",
    justifyContent: "center",
  },
  viewer: {
    width: "100%", //the pdf viewer will take up all of the width and height
    height: window.innerHeight - 200,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: "20px",
  },
  tablewithouthead: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "33.33%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColSpan: {
    width: "66.66%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
  icon: {
    height: 20,
    width: 20,
    color: "#29318C",
  },
});

// Create Document Component
function PdfReport({ project_id }) {
  const jwt_token = useSelector((state) => state.global.jwt_token);

  const [reportData, setReportData] = useState({});
  const getReport = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/project-statement-summery/${project_id}/`,
        {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        }
      );

      setReportData(response.data);
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getReport();
  }, []);
  return (
    <PDFViewer style={styles.viewer}>
      {/* Start of the document*/}
      <Document>
        {/*render a single page*/}
        <Page size="A4" style={styles.page}>
          <View
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <Image
              src={dTechLogo}
              style={{
                height: "150px",
                width: "150px",
                padding: "14px",
                justifyContent: "center",
                alignSelf: "center",
                marginLeft: "40px",
              }}
            />
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "50%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  height: "110px",
                  width: "4px",
                  backgroundColor: "#29318C",
                  marginRight: "10px",
                }}
              />
              <View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Svg
                    style={{
                      height: 20,
                      width: 20,
                      color: "#29318C",
                      marginRight: "10px",
                    }}
                    viewBox="0 0 24 24"
                  >
                    <Path
                      d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5s2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"
                      fill="#29318C"
                    />
                  </Svg>
                  <Text
                    style={{
                      fontSize: "13px",
                      fontWeight: "700",
                      color: "#29318C",
                      width: "180px",
                      letterSpacing: 2,
                    }}
                  >
                    House-77/B, Block- F, Road-3, Banani, 1213 Dhaka, Bangladesh
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "20px",
                  }}
                >
                  <Svg
                    style={{
                      height: 16,
                      width: 16,
                      color: "#29318C",
                      marginRight: "10px",
                    }}
                    viewBox="0 0 24 24"
                  >
                    <Path
                      d="M6.62 10.79a15.53 15.53 0 006.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.38 2.33.59 3.57.59.55 0 1 .45 1 1v3.5c0 .55-.45 1-1 1C10.29 21 3 13.71 3 4.5 3 3.95 3.45 3.5 4 3.5H7.5c.55 0 1 .45 1 1 0 1.24.21 2.45.59 3.57.12.35.03.74-.24 1.02l-2.2 2.2z"
                      fill="#29318C"
                    />
                  </Svg>
                  <Text
                    style={{
                      fontSize: "13px",
                      fontWeight: "700",
                      color: "#29318C",
                      width: "180px",
                      letterSpacing: 2,
                    }}
                  >
                    +8801988818050 +8801859666594
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.section}>
            <Text>Project Name: </Text>
            <Text>{reportData?.sales_order?.name}</Text>
          </View>

          <View style={styles.section}>
            <Text>All Recieved and Expenditure (Income Statement)</Text>
          </View>
          <View
            style={{
              margin: 10,
              padding: 10,
              fontSize: "15px",
              fontWeight: 700,
            }}
          >
            <View style={styles.tablewithouthead}>
              <View style={styles.tableRow}>
                <View style={styles.tableColSpan} rowspan="2">
                  <Text style={styles.tableCell}>Contact Value</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {reportData?.sales_order?.contact_amount} BDT
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableColSpan} rowspan="2">
                  <Text style={styles.tableCell}>Actual Work Done Value</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {reportData?.sales_order?.total_amount} BDT
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              backgroundColor: "black",
              width: "100%",
              height: "1px",
            }}
          />
          <View
            style={{
              padding: 10,
              fontSize: "15px",
              fontWeight: 700,
            }}
          >
            <Text>Sales/Revenew From The Project</Text>

            <View style={styles.table}>
              {/* Table Header */}
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Date</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Description</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Amount</Text>
                </View>
              </View>
              {reportData?.ar_invoices?.map((data) => (
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{data.date}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{data.remarks}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {data.total_amount} BDT
                    </Text>
                  </View>
                </View>
              ))}

              <View style={styles.tableRow}>
                <View style={styles.tableColSpan} rowspan="2">
                  <Text style={styles.tableCell}>Total Received Amount</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {reportData?.sales_order?.total_ar_invoice} BDT
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              padding: 10,
              fontSize: "15px",
              fontWeight: 700,
            }}
          >
            <Text>Expenditure - Materials</Text>

            <View style={styles.table}>
              {/* Table Header */}
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Date</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Item</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Amount</Text>
                </View>
              </View>
              {reportData?.purchase_items?.map((data) => (
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{data.date}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{data.group_name}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{data.total} BDT</Text>
                  </View>
                </View>
              ))}

              <View style={styles.tableRow}>
                <View style={styles.tableColSpan} rowspan="2">
                  <Text style={styles.tableCell}>Total Amount</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {reportData?.sales_order?.total_purchase} BDT
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              padding: 10,
              fontSize: "15px",
              fontWeight: 700,
            }}
          >
            <Text>Expenditure - Extra</Text>

            <View style={styles.table}>
              {/* Table Header */}
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Date</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Item</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Amount</Text>
                </View>
              </View>
              {reportData?.extra_expense_lists?.map((data) => (
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{"11/11/19"}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {data.expense_list__name}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{data.total} BDT</Text>
                  </View>
                </View>
              ))}

              <View style={styles.tableRow}>
                <View style={styles.tableColSpan} rowspan="2">
                  <Text style={styles.tableCell}>Total Amount</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {reportData?.sales_order?.total_extra_expense} BDT
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              backgroundColor: "black",
              width: "100%",
              height: "1px",
            }}
          />
          <View
            style={{
              padding: 10,
              fontSize: "15px",
              fontWeight: 700,
            }}
          >
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableColSpan} rowspan="2">
                  <Text style={styles.tableCell}>Total Expenditure</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {reportData?.sales_order?.total_extra_expense +
                      reportData?.sales_order?.total_ar_invoice +
                      reportData?.sales_order?.total_purchase}{" "}
                    BDT
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}
export default PdfReport;
