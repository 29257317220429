import React, { useEffect, useState } from "react";
import DownloadIcon from "@mui/icons-material/DownloadOutlined";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { useParams } from "react-router-dom";
import axios from "axios";
import { apiUrl, isAdminLoggedIn } from "../ApiUrlAndToken";
import { useSelector } from "react-redux";

const DownLoadRevenueReport = ({ reportData }) => {
  const [revenueReport, setRevenueReport] = useState([]);
  const getUserReportByID = async () => {
    try {
      setRevenueReport(
        reportData.ar_invoices.map((item) => ({
          Date: item.date,
          Description: item.remarks,
          "Business Partner": item.business_partner__name,
          Project: item.sales_order__name,
          Amount: item.total_amount,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadRevenueReport = () => {
    // Convert data to Excel format
    const worksheet = XLSX.utils.json_to_sheet(revenueReport);
    console.log(revenueReport);
    // Set column widths
    const columnWidths = [
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];

    worksheet["!cols"] = columnWidths;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Revenue Report");

    // Generate a download
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(
      blob,
      `Revenue Report ${reportData.start_date} to ${reportData.end_date}.xlsx`
    );
  };

  useEffect(() => {
    getUserReportByID();
  }, [reportData]);
  return (
    <div className="flex place-items-center space-x-3 my-[30px] justify-center items-center">
      <button
        onClick={handleDownloadRevenueReport}
        className="border border-black hover:bg-success rounded hover:text-white p-2"
      >
        <DownloadIcon />
      </button>
    </div>
  );
};

export default DownLoadRevenueReport;
