import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import GeneralInput from "../../../components/GeneralInput";
import { submitButton } from "../../../components/DesignStandardize";
import { apiUrl, isAdminLoggedIn } from "../../../ApiUrlAndToken";
import GeneralSelect from "../../../components/GeneralSelect";
import { useSelector } from "react-redux";

const AddNewItem = () => {
  const [photo, setPhoto] = useState(null);
  const [expenseCategory, setExpenseCategory] = useState(null);
  const [expenseCategories, setExpenseCategories] = useState([]);
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState(null);

  const jwt_token = useSelector((state) => state.global.jwt_token);
  const ownerID = window.localStorage.getItem("ownerID");

  const getOnGoingProjects = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/project/`, {
        headers: {
          Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
        },
      });

      const retrived = response.data;
      setProjects(
        retrived.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getOnGoingProjects();
  }, []);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/extra-expense-lists/`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        const retrived = response.data;
        setExpenseCategories(
          retrived.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        );

        console.log(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchGroups();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("description", description);
      formData.append("amount", amount);
      formData.append("expense_list", expenseCategory);
      formData.append("sales_order", project);
      formData.append("owner", ownerID);
      if (photo) {
        formData.append("picture", photo); // 'Upload' matches the server's expected field name
      }

      const response = await axios.post(
        `${apiUrl}/api/extra-expenses/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        }
      );

      console.log(response);
      toast.success(`New Expense Added`);
      setTimeout(() => {
        window.location.href = "/admin/extra-expense-list";
      }, 500);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePhotoChange = (event) => {
    setPhoto(event.currentTarget.files[0]);
  };

  return (
    <form className="flex flex-col gap-3 items-center w-full mt-auto md:mr-auto font-semibold font-Inter text-sm text-left md:text-base">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        <div className="">
          <GeneralSelect
            options={expenseCategories}
            label="Select Expense Category" // Corrected from 'lable' to 'label'
            placeholder="Select Expense Category"
            onChange={(selectedOption) =>
              setExpenseCategory(selectedOption.value)
            }
            value={
              expenseCategories.find(
                (option) => option.value === expenseCategory
              ) || ""
            }
          />
        </div>
        <div>
          <GeneralSelect
            options={projects}
            label="Select Project" // Corrected from 'lable' to 'label'
            placeholder="Select Project"
            onChange={(selectedOption) => setProject(selectedOption.value)}
            value={projects.find((option) => option.value === project) || ""}
          />
        </div>
        <div className="">
          <GeneralInput
            className={""}
            label="Expense Description"
            id="description"
            name="description"
            type="text"
            placeholder="Enter Expense Description"
            value={description}
            required
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className="">
          <GeneralInput
            className={""}
            label="Expense Amount"
            id="amount"
            name="amount"
            type="number"
            min={1}
            placeholder="Enter Expense Amount"
            value={amount}
            required
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>

        <div>
          <GeneralInput
            label="Invoice Picture"
            id="photo"
            name="photo"
            type="file"
            accept="image/*"
            onChange={handlePhotoChange}
          />
        </div>
      </div>

      <button
        className={`${submitButton} mt-3`}
        onClick={(e) => handleSubmit(e)}
      >
        Add Expense
      </button>
    </form>
  );
};

export default AddNewItem;
