import React, { useEffect, useState } from "react";
import PageTitle from "../pageTitle/PageTitle";
import { Button, DatePicker, Space } from "antd";
import { submitButton } from "../../components/DesignStandardize";
import { Table } from "antd";
import { SnippetsOutlined } from "@ant-design/icons";
import axios from "axios";
import { apiUrl, isAdminLoggedIn } from "../../ApiUrlAndToken";
import { useSelector } from "react-redux";
import DownLoadRevenueReport from "../../components/DownloadArInvoiceReport";

function ArInvoiceReport() {
  const jwt_token = useSelector((state) => state.global.jwt_token);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [reportData, setReportData] = useState({});
  const [generatedReport, setGeneratedReport] = useState([]);
  const getReport = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/reports/ar-invoice-report/?start_date=${startDate}&end_date=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        }
      );
      setReportData(response.data);
      setGeneratedReport(response.data.ar_invoices);
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [filteredInfo, setFilteredInfo] = useState({});

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
    setFilteredInfo(filters);
  };

  const clearFilters = () => {
    setFilteredInfo({});
  };
  const clearAll = () => {
    setFilteredInfo({});
  };

  const getUniqueFilterValues = (data, key) => {
    const uniqueValues = [...new Set(data.map((item) => item[key]))];
    return uniqueValues.map((value) => ({ text: value, value }));
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: {
        compare: (a, b) => new Date(a.date) - new Date(b.date),
        multiple: 2,
      },
    },

    {
      title: "Description",
      dataIndex: "remarks",
      sorter: {
        compare: (a, b) => a.remarks.localeCompare(b.remarks),
        multiple: 1,
      },
      filters: getUniqueFilterValues(generatedReport, "remarks"),
      filteredValue: filteredInfo.remarks || null,
      onFilter: (value, record) => record.remarks.includes(value),
    },

    {
      title: "Business Partner",
      dataIndex: "business_partner__name",
      sorter: {
        compare: (a, b) =>
          a.business_partner__name.localeCompare(b.business_partner__name),
        multiple: 1,
      },
      filters: getUniqueFilterValues(generatedReport, "business_partner__name"),
      filteredValue: filteredInfo.business_partner__name || null,
      onFilter: (value, record) =>
        record.business_partner__name.includes(value),
    },
    {
      title: "Project",
      dataIndex: "sales_order__name",
      sorter: {
        compare: (a, b) =>
          a.sales_order__name.localeCompare(b.sales_order__name),
        multiple: 1,
      },
    },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
      sorter: {
        compare: (a, b) => a.total_amount - b.total_amount,
        multiple: 1,
      },
    },
  ];

  return (
    <>
      <PageTitle title="Revenue Report" />
      <div className="mt-[7%] py-[40px] flex space-x-6 place-items-center">
        <div className="flex space-x-4">
          <div className="mb-4">
            <label
              htmlFor="start-date"
              className="block text-[20px] font-medium text-gray-700"
            >
              Start Date
            </label>
            <input
              type="date"
              id="start-date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="mt-1 p-[5px] block w-full rounded-md border-black/60 border-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-[14px]"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="end-date"
              className="block text-[20px] font-medium text-gray-700"
            >
              End Date
            </label>
            <input
              type="date"
              id="end-date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="mt-1 p-[5px] block w-full rounded-md border-black/60 border-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-[14px]"
            />
          </div>
        </div>
        <button
          onClick={() => getReport()}
          className={`bg-white mt-[17px] border-slate-900/70 rounded-md text-slate-900/70 text-[17px] border-2 p-1`}
        >
          Generate Report <SnippetsOutlined />
        </button>
      </div>
      <div className="flex justify-between place-items-center">
        <Space
          style={{
            marginBottom: 16,
          }}
        >
          <Button onClick={() => clearFilters()}>Clear filters</Button>
          <Button onClick={() => clearAll()}>Clear filters and sorters</Button>
        </Space>
        <div className="flex place-items-center space-x-2  px-[20px]">
          <p className="text-[20px] text-black">
            Total Revenue: {reportData?.total_ar_amount} BDT
          </p>
          <DownLoadRevenueReport reportData={reportData} />
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={generatedReport}
        onChange={onChange}
      />
    </>
  );
}

export default ArInvoiceReport;
