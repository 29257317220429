import React, { useState } from "react";
import EyeIcon from "@mui/icons-material/Visibility";
import EyeCloseIcon from "@mui/icons-material/VisibilityOff";
import AddIcon from "@mui/icons-material/AddBoxOutlined";
import {
  generalInputField,
  generalTextAreaField,
  hoverScale,
  inputEyeIcon,
  generalTableInputField,
} from "./DesignStandardize";

const GeneralInput = ({
  className,
  label,
  labelSpanText,
  id,
  name,
  type,
  placeholder,
  value,
  onChange,
  onBlur,
  accept,
  addItems,
  buttonFuntion,
  table,
  min,
  disabled,
  readOnly,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      buttonFuntion();
    }
  };

  return (
    <div className={`${table === 1 ? "w-40" : "w-full"} flex flex-col gap-2 `}>
      <label className="flex flex-row gap-2">
        {label}

        {labelSpanText && (
          <span className="font-semibold text-red-600">{labelSpanText}</span>
        )}
      </label>
      <div className="flex flex-row relative">
        {type === "password" ? (
          <input
            id={id}
            name={name}
            type={showPassword ? "text" : "password"}
            placeholder={placeholder}
            className={`${generalInputField} text-slate-900 w-[320px] bg-white`}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
        ) : type === "textarea" ? (
          <textarea
            className={`${
              table === 1 ? generalTableInputField : generalTextAreaField
            } text-slate-900 h-[139px] bg-white`}
            id={id}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
          />
        ) : type === "file" ? (
          <input
            id={id}
            name={name}
            type="file"
            placeholder={placeholder}
            className={`input input-bordered input-primary w-full max-w-xs bg-base-50 pt-[8.5px] bg-white`}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            accept={accept}
            min={min}
          />
        ) : (
          <input
            id={id}
            name={name}
            type={type}
            placeholder={placeholder}
            className={`${
              table === 1 ? generalTableInputField : generalInputField
            } text-slate-900 ${className} w-[320px] bg-white`}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            accept={accept}
            onKeyDown={addItems === 1 ? handleKeyPress : undefined}
            min={min}
            disabled={disabled}
            readOnly={readOnly}
          />
        )}

        {type === "password" && (
          <button
            type="button"
            className={`${inputEyeIcon} ${hoverScale} pr-[10px]`}
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <EyeCloseIcon color="black" />
            ) : (
              <EyeIcon color="black" />
            )}
          </button>
        )}
        {type === "number" && (
          <p className={`${inputEyeIcon} ${hoverScale} pr-[40px]`}>BDT</p>
        )}
        {addItems === 1 && (
          <button
            type="button"
            className={`${inputEyeIcon} ${hoverScale}`}
            onClick={buttonFuntion}
          >
            <AddIcon />
          </button>
        )}
      </div>
    </div>
  );
};

export default GeneralInput;
