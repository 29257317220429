import "./App.css";
import { Routes, Route } from "react-router-dom";
import Auth from "./views/auth/Auth.jsx";
import { isAdminLoggedIn, isEmployeeLoggedIn } from "./ApiUrlAndToken.jsx";
import Dashbooard from "./views/dashboard/Dashbooard.jsx";
import Items from "./views/items/Items.jsx";
import ItemGroups from "./views/items/itemGroups.jsx";
import Customer from "./views/customers/Customer.jsx";
import { useSelector } from "react-redux";
import Purchase from "./views/purchase/Purchase.jsx";
import ExtraExpenses from "./views/extra-expenses/extraexpences.jsx";
import Vouchers from "./views/voucher/vouchers.jsx";
import UserReport from "./views/userReport/UserReport.jsx";
import Drawer from "./components/Menu.jsx";
import ExtraExpensesList from "./views/extra-expenses/extraexpenseslist.jsx";
import AllPurchases from "./views/purchase/AllPurchases.jsx";
import AllRevenue from "./views/reveneu/AllRevenue.jsx";
import PurchaseItemReport from "./views/PurchaseItemReport/PurchaseItemReport.jsx";
import ArInvoiceReport from "./views/arInvoiceReport/arInvoiceReport.jsx";
import PurchaseReport from "./views/PurchaseReport/PurchaseReport.jsx";
import BankAccountsList from "./views/bank/bank-accounts.jsx";
import IncomingPayments from "./views/incoming-payments/incoming-payments.jsx";
import OutgoingPayments from "./views/outgoing-payments/outgoing-payments.jsx";
import BalanceSheetReport from "./views/balanceSheetReport/balanceSheetReport.jsx";
import AllProjectReport from "./views/allProjectReport/allProjectReport.jsx";
import Banks from "./views/bank/banks.jsx";
import { jwtDecode } from "jwt-decode";

function App() {
  const handleLogOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("is_staff");
    localStorage.removeItem("ownerID");
    if (isEmployeeLoggedIn) {
      localStorage.removeItem("employeeToken");
    } else if (isAdminLoggedIn) {
      localStorage.removeItem("adminLoggedIn");
    }
    window.location.href = "/";
  };

  const darked = useSelector((state) => state.global.dark_theme);
  const toggled = useSelector((state) => state.global.isDrawerSwitched);
  const jwt_token = useSelector((state) => state.global.jwt_token);

  if (jwt_token) {
    const decodedToken = jwtDecode(jwt_token);
    const isExpired = Date.now() >= decodedToken.exp * 1000;

    if (isExpired) {
      handleLogOut();
    }
  }

  return (
    <div
      className={`${
        darked
          ? "bg-slate-900 transition-transform text-white"
          : "bg-white text-slate-900"
      } ${
        toggled
          ? "pl-[83px] md:pl-[130px]"
          : !isAdminLoggedIn
          ? "pl-0"
          : "pl-[30px] md:pl-[260px]"
      }`}
    >
      {isAdminLoggedIn || isEmployeeLoggedIn ? <Drawer /> : <></>}
      <Routes>
        {isAdminLoggedIn ? (
          <Route path="/" element={<Dashbooard />} />
        ) : (
          <Route path="/" element={<Auth />} />
        )}

        {isAdminLoggedIn && (
          <>
            <Route path="/admin/dashboard" element={<Dashbooard />} />
            <Route path="/admin/items" element={<Items />} />
            <Route path="/admin/item-groups" element={<ItemGroups />} />
            <Route path="/admin/customers" element={<Customer />} />
            <Route path="/admin/revenue" element={<AllRevenue />} />
            <Route path="/admin/purchase" element={<Purchase />} />
            <Route path="/admin/purchase-list" element={<AllPurchases />} />
            <Route
              path="/admin/extra-expense-list"
              element={<ExtraExpenses />}
            />
            <Route
              path="/admin/extra-expense-categories"
              element={<ExtraExpensesList />}
            />
            <Route path="/admin/vouchers" element={<Vouchers />} />
            <Route path="/admin/my-report" element={<UserReport />} />
            <Route
              path="/admin/purchase-item-report"
              element={<PurchaseItemReport />}
            />
            <Route
              path="/admin/ar-invoice-report"
              element={<ArInvoiceReport />}
            />
            <Route path="/admin/purchase-report" element={<PurchaseReport />} />
            <Route path="/admin/bank-accounts" element={<BankAccountsList />} />
            <Route path="/admin/banks" element={<Banks />} />
            <Route
              path="/admin/incoming-payments"
              element={<IncomingPayments />}
            />
            <Route
              path="/admin/outgoing-payments"
              element={<OutgoingPayments />}
            />
            <Route
              path="/admin/balance-sheet-report"
              element={<BalanceSheetReport />}
            />
            <Route
              path="/admin/all-projects-report"
              element={<AllProjectReport />}
            />
          </>
        )}
      </Routes>
    </div>
  );
}

export default App;
