import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import GeneralInput from "../../../components/GeneralInput";
import { submitButton } from "../../../components/DesignStandardize";
import { isAdminLoggedIn } from "../../../ApiUrlAndToken";
import { useSelector } from "react-redux";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string().required("description is required"),
});

const EditItemGroupForm = ({ id }) => {
  const jwt_token = useSelector((state) => state.global.jwt_token);
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        // Use FormData to handle file uploads
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("description", values.description);
        formData.append("owner", window.localStorage.getItem("ownerID"));

        // Send a PUT request to update user data
        await axios.put(`${apiUrl}/api/itemgroup/${id}/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        // console.log(response.data);
        toast.success("Details Updated Successfully");
        setTimeout(() => {
          {
            isAdminLoggedIn
              ? (window.location.href = "/admin/item-groups")
              : (window.location.href = "/admin/item-groups");
          }
        }, 500);
      } catch (error) {
        // Error handling logic
        toast.error("Error updating group details");
      }
    },
  });

  // Fetch user data when the component mounts
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/itemgroup/${id}`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        const res = response.data;
        console.log(res);
        // Set fetched user data as initial values
        formik.setValues({
          name: res.name || "",
          description: res.description || "",
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUser();
  }, [id, formik.setValues]);

  return (
    <form
      className="p-2 flex flex-col max-h-96 overflow-y-auto gap-3 items-center w-full mt-auto md:mr-auto font-semibold font-Inter text-sm text-left md:text-base"
      onSubmit={formik.handleSubmit}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <div>
          <GeneralInput
            label="Group Name"
            id="name"
            name="name"
            type="text"
            placeholder="Enter Group Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
          {formik.touched.name && formik.errors.name ? (
            <div>{formik.errors.name}</div>
          ) : null}
        </div>

        <div>
          <GeneralInput
            label="Group Description"
            id="description"
            name="description"
            type="description"
            placeholder="Enter Group Description"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description}
          />
          {formik.touched.description && formik.errors.description ? (
            <div>{formik.errors.description}</div>
          ) : null}
        </div>
      </div>

      <button className={`${submitButton} mt-3`} type="submit">
        Update
      </button>
    </form>
  );
};

export default EditItemGroupForm;
