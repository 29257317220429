import React, { useEffect, useState } from "react";
import PageTitle from "../pageTitle/PageTitle";
import { Button, DatePicker, Space } from "antd";
import { submitButton } from "../../components/DesignStandardize";
import { Table } from "antd";
import { SnippetsOutlined } from "@ant-design/icons";
import axios from "axios";
import { apiUrl, isAdminLoggedIn } from "../../ApiUrlAndToken";
import { useSelector } from "react-redux";
import DownLoadRevenueReport from "../../components/DownloadArInvoiceReport";
import DownloadPurchaseReport from "../../components/DownloadPurchaseReport";
import DownloadAllProjectReport from "../../components/DownloadAllProjectReport";

function AllProjectReport() {
  const jwt_token = useSelector((state) => state.global.jwt_token);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [reportData, setReportData] = useState({});
  const [generatedRevenueReport, setGeneratedRevenueReport] = useState([]);
  const [generatedPurchasesReport, setGeneratedPurchasesReport] = useState([]);
  const [generatedExtraExpensesReport, setGeneratedExtraExpensesReport] =
    useState([]);
  const getReport = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/reports/project-report/?start_date=${startDate}&end_date=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        }
      );
      setReportData(response.data);
      setGeneratedRevenueReport(response.data.ar_invoices);
      setGeneratedPurchasesReport(response.data.purchases);
      setGeneratedExtraExpensesReport(response.data.extra_expenses);
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [filteredInfo, setFilteredInfo] = useState({});

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
    setFilteredInfo(filters);
  };

  const clearFilters = () => {
    setFilteredInfo({});
  };
  const clearAll = () => {
    setFilteredInfo({});
  };

  const getUniqueFilterValues = (data, key) => {
    const uniqueValues = [...new Set(data.map((item) => item[key]))];
    return uniqueValues.map((value) => ({ text: value, value }));
  };

  const revenue_columns = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: {
        compare: (a, b) => new Date(a.date) - new Date(b.date),
        multiple: 2,
      },
    },
    {
      title: "Business Partner Name",
      dataIndex: "business_partner__name",
      sorter: {
        compare: (a, b) =>
          a.business_partner__name.localeCompare(b.business_partner__name),
        multiple: 1,
      },
      filters: getUniqueFilterValues(
        generatedRevenueReport,
        "business_partner__name"
      ),
      filteredValue: filteredInfo.business_partner__name || null,
      onFilter: (value, record) =>
        record.business_partner__name.includes(value),
    },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
      sorter: {
        compare: (a, b) => a.total_amount - b.total_amount,
        multiple: 1,
      },
    },
  ];

  const purchases_columns = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: {
        compare: (a, b) => new Date(a.date) - new Date(b.date),
        multiple: 2,
      },
    },
    {
      title: "Business Partner Name",
      dataIndex: "business_partner__name",
      sorter: {
        compare: (a, b) =>
          a.business_partner__name.localeCompare(b.business_partner__name),
        multiple: 1,
      },
      filters: getUniqueFilterValues(
        generatedPurchasesReport,
        "business_partner__name"
      ),
      filteredValue: filteredInfo.business_partner__name || null,
      onFilter: (value, record) =>
        record.business_partner__name.includes(value),
    },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
      sorter: {
        compare: (a, b) => a.total_amount - b.total_amount,
        multiple: 1,
      },
    },
  ];

  const extra_expenses_columns = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: {
        compare: (a, b) => new Date(a.date) - new Date(b.date),
        multiple: 2,
      },
    },
    {
      title: "Expense Category Name",
      dataIndex: "expense_list__name",
      sorter: {
        compare: (a, b) =>
          a.expense_list__name.localeCompare(b.expense_list__name),
        multiple: 1,
      },
      filters: getUniqueFilterValues(
        generatedExtraExpensesReport,
        "expense_list__name"
      ),
      filteredValue: filteredInfo.expense_list__name || null,
      onFilter: (value, record) => record.expense_list__name.includes(value),
    },
    {
      title: "Total Amount",
      dataIndex: "amount",
      sorter: {
        compare: (a, b) => a.amount - b.amount,
        multiple: 1,
      },
    },
  ];

  return (
    <>
      <PageTitle title="All Projects Report" />
      <div className="mt-[7%] py-[40px] flex space-x-6 place-items-center">
        <div className="flex space-x-4">
          <div className="mb-4">
            <label
              htmlFor="start-date"
              className="block text-[20px] font-medium text-gray-700"
            >
              Start Date
            </label>
            <input
              type="date"
              id="start-date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="mt-1 p-[5px] block w-full rounded-md border-black/60 border-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-[14px]"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="end-date"
              className="block text-[20px] font-medium text-gray-700"
            >
              End Date
            </label>
            <input
              type="date"
              id="end-date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="mt-1 p-[5px] block w-full rounded-md border-black/60 border-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-[14px]"
            />
          </div>
        </div>
        <button
          onClick={() => getReport()}
          className={`bg-white mt-[17px] border-slate-900/70 rounded-md text-slate-900/70 text-[17px] border-2 p-1`}
        >
          Generate Report <SnippetsOutlined />
        </button>
      </div>
      <div className="flex justify-between place-items-center">
        <Space
          style={{
            marginBottom: 16,
          }}
        >
          <Button onClick={() => clearFilters()}>Clear filters</Button>
          <Button onClick={() => clearAll()}>Clear filters and sorters</Button>
        </Space>
      </div>

      <div className="flex justify-between place-items-end  pr-[20px] my-4">
        <div className="flex place-items-start space-y-2 flex-col font-medium">
          <p className="text-[17px] text-black">
            Total Revenue: {reportData?.total_revenue} BDT
          </p>
          <p className="text-[17px] text-black">
            Total Purchases: {reportData?.total_purchase_amount} BDT
          </p>
          <p className="text-[17px] text-black">
            Total Extra Expense: {reportData?.total_extra_expense_amount} BDT
          </p>
        </div>

        <DownloadAllProjectReport reportData={reportData} />
      </div>
      <h1 className="font-semibold text-[22px] text-black my-4">
        All Revenue Report
      </h1>
      <Table
        columns={revenue_columns}
        dataSource={generatedRevenueReport}
        onChange={onChange}
      />
      <h1 className="font-semibold text-[22px] text-black my-4">
        All Purchases Report
      </h1>
      <Table
        columns={purchases_columns}
        dataSource={generatedPurchasesReport}
        onChange={onChange}
      />
      <h1 className="font-semibold text-[22px] text-black my-4">
        All Extra Expenses Report
      </h1>
      <Table
        columns={extra_expenses_columns}
        dataSource={generatedExtraExpensesReport}
        onChange={onChange}
      />
    </>
  );
}

export default AllProjectReport;
