import React, { useState, useEffect, useRef } from "react";
import { Dropdown, Menu, Space, Table, Tag } from "antd";
import { DownOutlined } from "@ant-design/icons";
import FactCheckIconOutlined from "@mui/icons-material/FactCheckOutlined";
import axios from "axios";
import EditIcon from "@mui/icons-material/BorderColor";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  apiUrl,
  isAdminLoggedIn,
  isEmployeeLoggedIn,
} from "../../ApiUrlAndToken";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { hoverScale, submitButton } from "../../components/DesignStandardize";
import { Link } from "react-router-dom";
import GeneralModal from "../../components/GeneralModal";
import { useSelector } from "react-redux";
import PageTitle from "../pageTitle/PageTitle";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { HitApi } from "../../utils/Api_Loader";
import PurchaseAdd from "./components/PurchaseAdd";
import DeletePurchase from "./components/DeletePurchase";
import EditPurchase from "./components/EditPurchase";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import DrawIcon from "@mui/icons-material/Draw";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import DetailsPurchaseProject from "./components/DetailsPurchaseProject";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import Highlighter from "react-highlight-words";

function AllPurchases() {
  const [purchases, setPurchases] = useState([]);
  const [purchaseID, setPurchaseID] = useState(null);
  const [purchaseName, setPurchaseName] = useState(null);
  const [purchase, setPurchase] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);

  const [customers, setCustomers] = useState([]);

  const jwt_token = useSelector((state) => state.global.jwt_token);
  const is_staff = useSelector((state) => state.global.is_staff);

  const handleApprove = async (item) => {
    const formData = new FormData();
    formData.append("total_amount", item.total_amount);
    formData.append("business_partner", item.business_partner);
    formData.append("is_approved", true);

    alert(`Do you want to approve ${item.remarks}`);
    try {
      const response = await axios.patch(
        `${apiUrl}/api/purchases/${item.id}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        }
      );
      toast.success("Approved Successfully!");
      console.log(response);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.log(error);
      if (error.response) {
        toast.error(`${error.response.data.message}`);
      } else if (error.request) {
        toast.error("No response received from the server");
      } else {
        toast.error("An error occurred while processing the request");
      }
    }
  };

  const handleSign = async (item) => {
    const formData = new FormData();
    formData.append("total_amount", item.total_amount);
    formData.append("business_partner", item.business_partner);
    formData.append("is_signature_by_director", true);

    alert(`Do you want to sign ${item.remarks}`);
    try {
      const response = await axios.patch(
        `${apiUrl}/api/purchases/${item.id}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        }
      );
      toast.success("Signed Successfully!");
      console.log(response);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.log(error);
      if (error.response) {
        toast.error(`${error.response.data.message}`);
      } else if (error.request) {
        toast.error("No response received from the server");
      } else {
        toast.error("An error occurred while processing the request");
      }
    }
  };

  const handleDeletePurchase = async (purchaseID, purchaseName) => {
    if (window.confirm(`Are you sure you want to delete ${purchaseName}?`)) {
      try {
        await axios.delete(`${apiUrl}/api/purchases/${purchaseID}/`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        window.location.reload();
        toast.warning("Purchase has been deleted!!");
      } catch (error) {
        console.error(error);
      }
    }
  };

  //Business

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const data = await HitApi("/api/businesspartner/");
        setCustomers(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  //

  const [editModal, setEditModal] = useState(false);
  const toggleEditModal = () => {
    setEditModal((prevShowModal) => !prevShowModal);
  };
  const EditProjectModal = (id, name) => {
    setPurchaseID(id);
    setPurchaseName(name);
    toggleEditModal();
  };

  const [detailsModal, setDetailsModal] = useState(false);
  const toggleDetailsModal = () => {
    setDetailsModal((prevShowModal) => !prevShowModal);
  };
  const ProjectDetailsModal = (purchase) => {
    setPurchase(purchase);
    toggleDetailsModal();
  };

  const getPurchases = async () => {
    try {
      const response = await HitApi("/api/purchases/");
      setPurchases(response);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPurchases();
  }, [purchases.length]);

  // const items = [];
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await HitApi("/api/item/");

        setItems(
          response.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        );
        console.log(items);
        console.log(response);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchGroups();
  }, []);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const generateMenuItems = (row) => [
    {
      key: "1",
      label: "View",
      onClick: () => ProjectDetailsModal(row),
    },
    {
      key: "2",
      label: "Edit",
      onClick: () => EditProjectModal(row.id, row.name),
    },
    {
      key: "3",
      label: "Approve",
      onClick: () => handleApprove(row),
    },
    {
      key: "4",
      label: "Signature",
      onClick: () => handleSign(row),
    },
    {
      key: "5",
      label: <span style={{ color: "red" }}>Delete</span>,
      onClick: () => handleDeletePurchase(row.id, row.remarks),
    },
  ];

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      ...getColumnSearchProps("date"),
    },
    {
      title: "Description",
      dataIndex: "remarks",
      key: "remarks",
      render: (text) => <a>{text}</a>,
      ...getColumnSearchProps("remarks"),
    },
    {
      title: "Business Partner",
      dataIndex: "business_partner",
      key: "business_partner",
      render: (business_partner) => {
        return (
          <p>{customers?.find((data) => data.id === business_partner)?.name}</p>
        );
      },
    },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
      key: "total_amount",
    },
    {
      title: "Approval Status",
      key: "is_approved",
      dataIndex: "is_approved",
      render: (is_approved) => (
        <>
          <Tag color={is_approved ? "green" : "gold"}>
            {is_approved ? "APPROVED" : "PENDING"}
          </Tag>
        </>
      ),
    },
    {
      title: "Signature Status",
      key: "is_signature_by_director",
      dataIndex: "is_signature_by_director",
      render: (is_signature_by_director) => (
        <>
          <Tag color={is_signature_by_director ? "green" : "gold"}>
            {is_signature_by_director ? "SIGNED" : "NOT SIGNED"}
          </Tag>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (row) => (
        <Space size="middle">
          <Dropdown overlay={<Menu items={generateMenuItems(row)} />}>
            <a>
              Actions{" "}
              <DownOutlined
                style={{
                  fontSize: "10px",
                }}
              />
            </a>
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <>
      <PageTitle title="Purchase List" />
      <div className="h-[100%] pt-[8%]">
        <div className=" flex space-x-[20px] place-items-center font-bold">
          <div className="">
            <FactCheckIconOutlined
              fontSize="100px"
              style={{
                height: "40px",
                width: "40px",
                color: "#334155",
              }}
              color="#334155"
            />
          </div>
          <p className="text-[30px]">All Purchases</p>
        </div>
        <div className="mt-[30px] px-[13px]">
          <Table columns={columns} dataSource={purchases} />
        </div>
      </div>

      {/* Modal to edit purchase */}
      {editModal && purchaseID && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleEditModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex justify-between">
              <p className="text-xl text-blue-900 font-bold">
                Edit
                {/* <span className="text-warning">Ongoing</span> */}
              </p>
              <CloseIcon
                onClick={toggleEditModal}
                fontSize="1px"
                className={`hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
              />
            </div>
            <EditPurchase purchaseID={purchaseID} />
          </div>
        </div>
      )}

      {/* Modal to see details of a purchase */}
      {detailsModal && purchase && (
        <DetailsPurchaseProject
          purchase={purchase}
          toggleDetailsModal={toggleDetailsModal}
        />
      )}
    </>
  );
}

export default AllPurchases;
