import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import GeneralInput from "../../../components/GeneralInput";
import { submitButton } from "../../../components/DesignStandardize";
import { apiUrl, isAdminLoggedIn } from "../../../ApiUrlAndToken";
import GeneralSelect from "../../../components/GeneralSelect";
import { useSelector } from "react-redux";

const validationSchema = Yup.object().shape({
  item_group_name: Yup.string().required("Category Name is required"),
});

const AddNewItemGroup = () => {
  const jwt_token = useSelector((state) => state.global.jwt_token);

  const formik = useFormik({
    initialValues: {
      item_group_name: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        formData.append("name", values.item_group_name);
        formData.append("owner", window.localStorage.getItem("ownerID"));

        const response = await axios.post(
          `${apiUrl}/api/extra-expense-lists/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
            },
          }
        );

        toast.success(`New Category Created`);
        console.log(response);
        setTimeout(() => {
          window.location.href = "/admin/extra-expense-categories";
        }, 300);
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <form
      className="flex flex-col gap-3 items-center w-full mt-auto md:mr-auto font-semibold font-Inter text-sm text-left md:text-base"
      onSubmit={formik.handleSubmit}
    >
      <div className="flex flex-row place-items-center gap-4">
        <div className="">
          <GeneralInput
            className={""}
            label="Category Name"
            id="item_group_name"
            name="item_group_name"
            type="text"
            placeholder="Enter Category Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.item_group_name}
          />
          {formik.touched.item_group_name && formik.errors.item_group_name ? (
            <div className="text-red-500 font-light">
              {formik.errors.item_group_name}
            </div>
          ) : null}
        </div>

        <button
          className={`${submitButton} h-[50px] mt-[30px] w-max`}
          type="submit"
        >
          Add Category
        </button>
      </div>
    </form>
  );
};

export default AddNewItemGroup;
