import { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl, isAdminLoggedIn } from "../../ApiUrlAndToken";
import { useSelector } from "react-redux";
import dTechLogo from "../../assets/img/logo.png";
import PageTitle from "../pageTitle/PageTitle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DetailsModalReveneu from "../reveneu/components/DetailsModalReveneu";
import DetailsPurchaseProject from "../purchase/components/DetailsPurchaseProject";
import DownloadUserReport from "../../components/DownLoadUserReport";

function UserReport() {
  const jwt_token = useSelector((state) => state.global.jwt_token);
  const ownerID = window.localStorage.getItem("ownerID");
  const [reportData, setReportData] = useState({});
  const [invoice, setInvoice] = useState({});
  const [detailsModal, setDetailsModal] = useState(false);
  const toggleDetailsModal = () => {
    setDetailsModal((prevShowModal) => !prevShowModal);
  };

  const getReport = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/reports/owner/${ownerID}/`,
        {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        }
      );
      setReportData(response.data);
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getReport();
  }, []);

  return (
    <>
      <PageTitle title="User Report" />
      <div className="p-8 mt-[60px]">
        <DownloadUserReport ownerID={ownerID} />
        <div className="text-center mb-4">
          <img src={dTechLogo} alt="dTech Logo" className="mx-auto w-24 h-20" />
        </div>
        <div className="text-center mb-8">
          <p className="text-lg font-bold">Owner Id: {ownerID}</p>
          <p className="text-lg font-bold">
            All Received and Expenditure Statement
          </p>
        </div>
        <div className="mb-8">
          <h2 className="text-xl font-semibold mb-2">
            Revenue From The Project
          </h2>
          <table className="table-auto w-full border-collapse border border-gray-400">
            <thead>
              <tr>
                <th className="border border-gray-400 p-2">Date</th>
                <th className="border border-gray-400 p-2">Project ID</th>
                <th className="border border-gray-400 p-2">Description</th>
                <th className="border border-gray-400 p-2">Amount</th>
                <th className="border border-gray-400 p-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {reportData?.ar_invoices?.map((data, index) => (
                <tr key={index}>
                  <td className="border border-gray-400 p-2">{data.date}</td>
                  <td className="border border-gray-400 p-2">
                    {data.sales_order}
                  </td>
                  <td className="border border-gray-400 p-2">{data.remarks}</td>
                  <td className="border border-gray-400 p-2">
                    {data.total_amount} BDT
                  </td>
                  <td className="border border-gray-400 p-2">
                    <button
                      onClick={() => {
                        setInvoice(data);
                        setDetailsModal(true);
                      }}
                      className={`mb-auto h-[34px] w-full hover:cursor-pointer flex items-center justify-center rounded-md text-[#0d6efd] space-x-2 hover:bg-slate-200 bg-transparent border-[1px] border-[#0d6efd]/50  p-2  place-items-center place-content-center `}
                    >
                      <VisibilityIcon
                        style={{
                          height: "16px",
                          width: "16px",
                        }}
                      />
                      <p>View Details</p>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mb-8">
          <h2 className="text-xl font-semibold mb-2">Purchases</h2>
          <table className="table-auto w-full border-collapse border border-gray-400">
            <thead>
              <tr>
                <th className="border border-gray-400 p-2">Date</th>
                <th className="border border-gray-400 p-2">Project ID</th>
                <th className="border border-gray-400 p-2">Description</th>
                <th className="border border-gray-400 p-2">Approval Status</th>
                <th className="border border-gray-400 p-2">Signature Status</th>
                <th className="border border-gray-400 p-2">Amount</th>
                <th className="border border-gray-400 p-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {reportData?.purchases?.map((data, index) => (
                <tr key={index}>
                  <td className="border border-gray-400 p-2">{data.date}</td>
                  <td className="border border-gray-400 p-2">
                    {data.sales_order}
                  </td>
                  <td className="border border-gray-400 p-2">{data.remarks}</td>

                  <td className="border border-gray-400 p-2">
                    {data.is_approved ? "Approved" : "Not Approved"}
                  </td>
                  <td className="border border-gray-400 p-2">
                    {data.is_signature_by_director ? "Signed" : "Not Signed"}
                  </td>
                  <td className="border border-gray-400 p-2">
                    {data.total_amount} BDT
                  </td>
                  <td className="border border-gray-400 p-2">
                    <button
                      onClick={() => {
                        setInvoice(data);
                        setDetailsModal(true);
                      }}
                      className={`mb-auto h-[34px] w-full hover:cursor-pointer flex items-center justify-center rounded-md text-[#0d6efd] space-x-2 hover:bg-slate-200 bg-transparent border-[1px] border-[#0d6efd]/50  p-2  place-items-center place-content-center `}
                    >
                      <VisibilityIcon
                        style={{
                          height: "16px",
                          width: "16px",
                        }}
                      />
                      <p>View Details</p>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mb-8">
          <h2 className="text-xl font-semibold mb-2">Expenditure</h2>
          <table className="table-auto w-full border-collapse border border-gray-400">
            <thead>
              <tr>
                <th className="border border-gray-400 p-2">Date</th>
                <th className="border border-gray-400 p-2">Project ID</th>
                <th className="border border-gray-400 p-2">Item</th>
                <th className="border border-gray-400 p-2">Amount</th>
              </tr>
            </thead>
            <tbody>
              {reportData?.extra_expenses?.map((data, index) => (
                <tr key={index}>
                  <td className="border border-gray-400 p-2">{data.date}</td>
                  <td className="border border-gray-400 p-2">
                    {data.sales_order}
                  </td>
                  <td className="border border-gray-400 p-2">
                    {data.description}
                  </td>
                  <td className="border border-gray-400 p-2">
                    {data.amount} BDT
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <hr className="border-black mt-4" />
      </div>
      {detailsModal && (
        <DetailsModalReveneu
          invoice={invoice}
          toggleDetailsModal={toggleDetailsModal}
        />
      )}
      {detailsModal && (
        <DetailsPurchaseProject
          purchase={invoice}
          toggleDetailsModal={toggleDetailsModal}
        />
      )}
    </>
  );
}

export default UserReport;
