import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import GeneralInput from "../../../components/GeneralInput";
import GeneralSelect from "../../../components/GeneralSelect";
import { submitButton } from "../../../components/DesignStandardize";
import { isAdminLoggedIn } from "../../../ApiUrlAndToken";
import { useSelector } from "react-redux";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Item Name is required"),
  description: Yup.string().required("Item description is required"),
  // item_type: Yup.string().required("Item Type is required"),
  price: Yup.string().required("Item Price is required"),
  // group: Yup.string().required("Group Name is required"),
  code: Yup.string().required("Code is required"),
});

const EditItemForm = ({ id }) => {
  const [photo, setPhoto] = useState(null);
  const [existingPhoto, setExistingPhoto] = useState(null);
  const [expenseCategory, setExpenseCategory] = useState(null);
  const [expenseCategories, setExpenseCategories] = useState([]);
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState(null);

  const jwt_token = useSelector((state) => state.global.jwt_token);
  const ownerID = window.localStorage.getItem("ownerID");
  console.log(ownerID);

  const getOnGoingProjects = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/project/`, {
        headers: {
          Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
        },
      });

      const retrived = response.data;
      setProjects(
        retrived.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getOnGoingProjects();
  }, []);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/extra-expense-lists/`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        const retrived = response.data;
        setExpenseCategories(
          retrived.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        );

        console.log(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchGroups();
  }, []);

  const EditExpense = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("description", description);
    formData.append("amount", amount);
    formData.append("expense_list", expenseCategory);
    formData.append("sales_order", project);
    formData.append("owner", ownerID);
    if (photo) {
      formData.append("picture", photo); // 'Upload' matches the server's expected field name
    }

    try {
      const response = await axios.put(
        `${apiUrl}/api/extra-expenses/${id}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        }
      );

      console.log(response);
      toast.success(`Edited Successfully`);
      setTimeout(() => {
        window.location.href = "/admin/extra-expense-list";
      }, 500);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePhotoChange = (event) => {
    setPhoto(event.currentTarget.files[0]);
  };

  // Fetch user data when the component mounts
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/extra-expenses/${id}`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        const res = response.data;
        console.log(res);
        setExpenseCategory(res.expense_list);
        setProject(res.sales_order);
        setDescription(res.description);
        setAmount(res.amount);
        setExistingPhoto(res.picture);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUser();
  }, [id]);

  return (
    <form className="p-2 flex flex-col max-h-96 overflow-y-auto gap-3 items-center w-full mt-auto md:mr-auto font-semibold font-Inter text-sm text-left md:text-base">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        <div className="">
          <GeneralSelect
            options={expenseCategories}
            label="Select Expense Category" // Corrected from 'lable' to 'label'
            placeholder="Select Expense Category"
            onChange={(selectedOption) =>
              setExpenseCategory(selectedOption.value)
            }
            value={
              expenseCategories.find(
                (option) => option.value === expenseCategory
              ) || ""
            }
          />
        </div>
        <div>
          <GeneralSelect
            options={projects}
            label="Select Project" // Corrected from 'lable' to 'label'
            placeholder="Select Project"
            onChange={(selectedOption) => setProject(selectedOption.value)}
            value={projects.find((option) => option.value === project) || ""}
          />
        </div>
        <div className="">
          <GeneralInput
            className={""}
            label="Expense Description"
            id="description"
            name="description"
            type="text"
            placeholder="Enter Expense Description"
            value={description}
            required
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className="">
          <GeneralInput
            className={""}
            label="Expense Amount"
            id="amount"
            name="amount"
            type="number"
            min={1}
            placeholder="Enter Expense Amount"
            value={amount}
            required
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>

        {existingPhoto && (
          <div>
            <Zoom>
              <img
                src={existingPhoto}
                alt="Existing"
                className="mb-2 size-[100px] object-cover"
              />
            </Zoom>
            <GeneralInput
              label="Change Invoice Picture"
              id="photo"
              name="photo"
              type="file"
              accept="image/*"
              onChange={handlePhotoChange}
            />
          </div>
        )}

        {!existingPhoto && (
          <div>
            <GeneralInput
              label="Invoice Picture"
              id="photo"
              name="photo"
              type="file"
              accept="image/*"
              onChange={handlePhotoChange}
            />
          </div>
        )}
      </div>

      <button
        className={`${submitButton} mt-3`}
        onClick={(e) => EditExpense(e)}
      >
        Update
      </button>
    </form>
  );
};

export default EditItemForm;
