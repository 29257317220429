import React, { useEffect, useState } from "react";
import axios from "axios";
import EditItemForm from "./EditItem";
import { apiUrl, isAdminLoggedIn } from "../../../ApiUrlAndToken";
import { hoverScale } from "../../../components/DesignStandardize";
import EditIcon from "@mui/icons-material/BorderColor";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import ProjectsIcon from "@mui/icons-material/HomeWorkOutlined";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Table } from "../../../components/Table";
import SearchInput from "../../../components/SearchInput";
import GeneralModal from "../../../components/GeneralModal";
import { Descriptions } from "antd";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const ItemsList = () => {
  const [items, setItems] = useState([]);
  const [editingItemID, setEditingItemID] = useState(null);
  const [projectModalID, setProjectModalID] = useState(null);
  const [viewingItem, setViewingItem] = useState(null);
  const jwt_token = useSelector((state) => state.global.jwt_token);
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/item/`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        setItems(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchGroups();
  }, []);

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal((prevShowModal) => !prevShowModal);
  };
  const [projectModal, setProjectModal] = useState(false);
  const toggleProjectModal = () => {
    setProjectModal(!projectModal);
  };
  const ProjectFetch = (userID) => {
    setProjectModalID(userID);
    toggleProjectModal();
  };
  const handleEdit = (id) => {
    setEditingItemID(id);
    toggleModal();
  };

  const handleDelete = async (id, name) => {
    if (window.confirm(`Are you sure you want to remove ${name}?`)) {
      try {
        await axios.delete(`${apiUrl}/api/item/${id}/`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        const response = await axios.get(`${apiUrl}/api/item/`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        setItems(response.data);
        toast.warning("Group Deleted Successfully");
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    }
  };

  const handleView = (item) => {
    setViewingItem(item);
  };

  const closeViewModal = () => {
    setViewingItem(null);
  };

  return (
    <>
      <div className="my-[30px] pt-[14px]">
        <SearchInput
          onChange={(e) => setSearchString(e.target.value)}
          value={searchString}
        />
      </div>
      <div>
        <Table
          items={items}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleView={handleView}
          searchString={searchString}
        />
      </div>

      {modal && editingItemID && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex justify-between">
              <p className="text-xl text-blue-900 font-bold">
                Edit Item Details
              </p>
              <CloseIcon
                onClick={toggleModal}
                fontSize="1px"
                className={`hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
              />
            </div>
            <EditItemForm
              id={editingItemID}
              onCancel={() => setEditingItemID(null)}
            />
          </div>
        </div>
      )}
      {projectModal && projectModalID && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleProjectModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex justify-between">
              <p className="text-xl text-blue-900 font-bold">
                See Individual Projects
              </p>
              <CloseIcon
                onClick={toggleProjectModal}
                fontSize="1px"
                className={`hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
              />
            </div>
          </div>
        </div>
      )}
      {viewingItem && (
        <GeneralModal title="Item Details" onClose={closeViewModal}>
          <div>
            <Descriptions bordered>
              {/* <Descriptions.Item label="Picture">
                <Zoom>
                  <img
                    src={viewingItem.picture}
                    alt={viewingItem.name}
                    style={{ maxWidth: "100px" }}
                  />
                </Zoom>
              </Descriptions.Item> */}
              <Descriptions.Item label="ID">{viewingItem.id}</Descriptions.Item>
              <Descriptions.Item label="Code">
                {viewingItem.code}
              </Descriptions.Item>
              <Descriptions.Item label="Name">
                {viewingItem.name}
              </Descriptions.Item>
              <Descriptions.Item label="Description">
                {viewingItem.description}
              </Descriptions.Item>
              <Descriptions.Item label="Price">
                {viewingItem.price}
              </Descriptions.Item>
              <Descriptions.Item label="Item Type">
                {viewingItem.item_type}
              </Descriptions.Item>
              {/* <Descriptions.Item label="Remarks">
                {viewingItem.remarks}
              </Descriptions.Item> */}
              <Descriptions.Item label="Group">
                {viewingItem.group}
              </Descriptions.Item>
              <Descriptions.Item label="Owner">
                {viewingItem.owner}
              </Descriptions.Item>
            </Descriptions>
          </div>
        </GeneralModal>
      )}
    </>
  );
};

export default ItemsList;
