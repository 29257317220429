import React, { useState } from "react";
import PageTitle from "../pageTitle/PageTitle";
import { hoverScale, submitButton } from "../../components/DesignStandardize";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import AddNewItem from "./components/AddNewItem";
import { isAdminLoggedIn } from "../../ApiUrlAndToken";
import EngineerIcon from "@mui/icons-material/Engineering";
import GeneralModal from "../../components/GeneralModal";
import ItemsList from "./components/ItemsList";
import { useSelector } from "react-redux";

const Banks = () => {
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal((prevShowModal) => !prevShowModal);
  };
  const is_staff = useSelector((state) => state.global.is_staff);

  return (
    <>
      <PageTitle title="Banks" />
      <div className="pt-[10px] h-screen">
        <ItemsList />
      </div>

      {/* Button to add new employee */}
      <>
        {isAdminLoggedIn && is_staff && (
          <div
            className={`w-max  hidden md:block fixed bottom-2 right-2 hover:cursor-pointer font-Inter ${submitButton} ${hoverScale}`}
            onClick={toggleModal}
          >
            <EngineerIcon className="text-sm" /> Add Banks
          </div>
        )}
        {isAdminLoggedIn && is_staff && (
          <div
            className={`w-max  fixed bottom-2 left-2 md:hidden hover:cursor-pointer font-Inter ${submitButton} ${hoverScale}`}
            onClick={toggleModal}
          >
            <EngineerIcon className="text-sm" /> Add Banks
          </div>
        )}
      </>

      {/* To Register New Employee */}
      {modal && (
        <GeneralModal title="Add New Bank" onClose={toggleModal}>
          <AddNewItem />
        </GeneralModal>
      )}
    </>
  );
};

export default Banks;
