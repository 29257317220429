import React, { useState, useEffect, useRef } from "react";
import { Dropdown, Menu, Space, Table, Tag } from "antd";
import { DownOutlined } from "@ant-design/icons";
import axios from "axios";
import FactCheckIconOutlined from "@mui/icons-material/FactCheckOutlined";
import {
  apiUrl,
  isAdminLoggedIn,
  isEmployeeLoggedIn,
} from "../../ApiUrlAndToken";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { hoverScale, submitButton } from "../../components/DesignStandardize";
import { Link } from "react-router-dom";
import GeneralModal from "../../components/GeneralModal";
import { useSelector } from "react-redux";
import PageTitle from "../pageTitle/PageTitle";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RevenueAdd from "./components/RevenueAdd";
import DeleteRevenue from "./components/DeleteRevenue";
import { HitApi } from "../../utils/Api_Loader";
import EditRevenue from "./components/EditRevenue";
import DetailsModalReveneu from "./components/DetailsModalReveneu";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import Highlighter from "react-highlight-words";
import { toast } from "react-toastify";

const AllRevenue = () => {
  const [invoices, setInvoices] = useState([]);
  const [invoiceID, setInvoiceID] = useState(null);
  const [invoiceName, setInvoiceName] = useState(null);
  const [invoice, setInvoice] = useState("");

  const jwt_token = useSelector((state) => state.global.jwt_token);

  //Business

  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const data = await HitApi("/api/businesspartner/");
        setCustomers(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  //

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal((prevShowModal) => !prevShowModal);
  };

  const [editModal, setEditModal] = useState(false);
  const toggleEditModal = () => {
    setEditModal((prevShowModal) => !prevShowModal);
  };
  const EditProjectModal = (id, name) => {
    setInvoiceID(id);
    setInvoiceName(name);
    toggleEditModal();
  };

  const handleDelete = async (invoiceId, invoiceName) => {
    if (window.confirm(`Are you sure you want to delete ${invoiceName}?`)) {
      try {
        await axios.delete(`${apiUrl}/api/ar-invoices/${invoiceId}/`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        toast.warning("Invoice has been deleted!!");
        window.location.reload();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const [detailsModal, setDetailsModal] = useState(false);
  const toggleDetailsModal = () => {
    setDetailsModal((prevShowModal) => !prevShowModal);
  };
  const ProjectDetailsModal = (invoice) => {
    setInvoice(invoice);
    toggleDetailsModal();
  };

  const getArInvoice = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/ar-invoices/`, {
        headers: {
          Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
        },
      });

      setInvoices(response.data);
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getArInvoice();
  }, []);

  const is_staff = useSelector((state) => state.global.is_staff);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const generateMenuItems = (row) => [
    {
      key: "1",
      label: "View",
      onClick: () => ProjectDetailsModal(row),
    },
    {
      key: "2",
      label: "Edit",
      onClick: () => EditProjectModal(row.id, row.remarks),
    },
    {
      key: "4",
      label: <span style={{ color: "red" }}>Delete</span>,
      onClick: () => handleDelete(row.id, row.remarks),
    },
  ];

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      ...getColumnSearchProps("date"),
    },
    {
      title: "Name",
      dataIndex: "remarks",
      key: "remarks",
      render: (text) => <a>{text}</a>,
      ...getColumnSearchProps("remarks"),
    },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
      key: "total_amount",
      ...getColumnSearchProps("total_amount"),
    },
    {
      title: "Business Partner",
      dataIndex: "business_partner",
      key: "business_partner",
      render: (business_partner) => {
        return (
          <p>{customers?.find((data) => data.id === business_partner)?.name}</p>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (row) => (
        <Space size="middle">
          <Dropdown overlay={<Menu items={generateMenuItems(row)} />}>
            <a>
              Actions{" "}
              <DownOutlined
                style={{
                  fontSize: "10px",
                }}
              />
            </a>
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <>
      <PageTitle title="All Revenue" />
      <div className="text-left mx-auto pt-[120px] h-[100vh]">
        <div className=" flex space-x-[20px] place-items-center font-bold">
          <div className="">
            <FactCheckIconOutlined
              fontSize="100px"
              style={{
                height: "40px",
                width: "40px",
                color: "#334155",
              }}
              color="#334155"
            />
          </div>
          <p className="text-[30px]">All Revenue</p>
        </div>

        <div className="mt-[30px]">
          <Table columns={columns} dataSource={invoices} />
        </div>
      </div>

      {isAdminLoggedIn && is_staff && (
        <div
          className={`w-max hidden md:place-content-center md:place-items-center md:flex fixed bottom-2 right-2 hover:cursor-pointer font-Inter ${submitButton} ${hoverScale}`}
          onClick={toggleModal}
        >
          <AddCircleIcon className="text-sm mr-1" />{" "}
          <span>Add New Invoice</span>
        </div>
      )}

      {/* Modal to assign engineer */}
      {modal && (
        <GeneralModal title="New Revenue" onClose={toggleModal}>
          <RevenueAdd />
        </GeneralModal>
      )}

      {/* Modal to edit invoice */}
      {editModal && invoiceID && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleEditModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex justify-between">
              <p className="text-xl text-blue-900 font-bold">
                Edit {invoiceName} -{" "}
                {/* <span className="text-warning">Ongoing</span> */}
              </p>
              <CloseIcon
                onClick={toggleEditModal}
                fontSize="1px"
                className={`hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
              />
            </div>
            <EditRevenue invoiceID={invoiceID} />
          </div>
        </div>
      )}

      {/* Modal to see details of a invoice */}
      {detailsModal && invoice && (
        <DetailsModalReveneu
          invoice={invoice}
          toggleDetailsModal={toggleDetailsModal}
        />
      )}
    </>
  );
};

export default AllRevenue;
