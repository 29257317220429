import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl, isAdminLoggedIn } from "../../../ApiUrlAndToken";
import { useSelector } from "react-redux";
import { ConfigProvider, Table } from "antd";

const RecentRevenue = () => {
  const [items, setItems] = useState([]);
  const jwt_token = useSelector((state) => state.global.jwt_token);
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
    },
    {
      title: "Amount",
      dataIndex: "total_amount",
    },
  ];

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/ar-invoices/`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        setItems(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchGroups();
  }, []);
  return (
    <div className="bg-gray-50 p-5 rounded-xl h-auto w-full mt-[40px]">
      <div>
        <p className="font-bold text-[20px] text-black mb-[10px]">
          Recent Revenue
        </p>
      </div>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              borderColor: "#EEF0F8",
              headerBg: "#A0A3CD",
              headerColor: "white",
            },
          },
        }}
      >
        <Table
          bordered
          columns={columns}
          dataSource={items}
          size="middle"
          pagination={false}
        />
      </ConfigProvider>
    </div>
  );
};

export default RecentRevenue;
