import React, { useEffect, useState } from "react";
import DownloadIcon from "@mui/icons-material/DownloadOutlined";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { useParams } from "react-router-dom";
import axios from "axios";
import { apiUrl, isAdminLoggedIn } from "../ApiUrlAndToken";
import { useSelector } from "react-redux";

const DownloadUserReport = ({ ownerID }) => {
  const [userRevenueReport, setUserRevenueReport] = useState([]);
  const [userPurchaseReport, setUserPurchaseReport] = useState([]);
  const [userExpenseReport, setUserExpenseReport] = useState([]);
  const jwt_token = useSelector((state) => state.global.jwt_token);
  const getUserReportByID = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/reports/owner/${ownerID}/`,
        {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        }
      );
      setUserRevenueReport(
        response.data.ar_invoices.map((item) => ({
          Date: item.date,
          "Project ID": item.sales_order,
          Description: item.remarks,
          Amount: item.total_amount,
        }))
      );
      setUserPurchaseReport(
        response.data.purchases.map((item) => ({
          Date: item.date,
          "Project ID": item.sales_order,
          Description: item.remarks,
          Amount: item.total_amount,
          "Approval Status": item.is_approved ? "APPROVED" : "PENDING",
          "Signature Status": item.is_signature_by_director
            ? "SIGNED"
            : "PENDING",
        }))
      );
      setUserExpenseReport(
        response.data.extra_expenses.map((item) => ({
          Date: item.date,
          "Project ID": item.sales_order,
          Description: item.description,
          Amount: item.total_amount,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadRevenueReport = () => {
    // Convert data to Excel format
    const worksheet = XLSX.utils.json_to_sheet(userRevenueReport);
    console.log(userRevenueReport);
    // Set column widths
    const columnWidths = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];

    worksheet["!cols"] = columnWidths;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "User Report");

    // Generate a download
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, `User Revenue ${ownerID}.xlsx`);
  };

  const handleDownloadPurchaseReport = () => {
    // Convert data to Excel format
    const worksheet = XLSX.utils.json_to_sheet(userPurchaseReport);
    console.log(userPurchaseReport);
    // Set column widths
    const columnWidths = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];

    worksheet["!cols"] = columnWidths;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "User Report");

    // Generate a download
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, `User Purchase Report ${ownerID}.xlsx`);
  };

  const handleDownloadExpenseReport = () => {
    // Convert data to Excel format
    const worksheet = XLSX.utils.json_to_sheet(userExpenseReport);
    console.log(userExpenseReport);
    // Set column widths
    const columnWidths = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];

    worksheet["!cols"] = columnWidths;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "User Report");

    // Generate a download
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(blob, `User Expense Report ${ownerID}.xlsx`);
  };

  useEffect(() => {
    getUserReportByID();
  }, [ownerID]);
  return (
    <div className="flex place-items-center space-x-3 my-[30px] justify-center items-center">
      <button
        onClick={handleDownloadRevenueReport}
        className="border border-black hover:bg-success rounded hover:text-white p-2"
      >
        Download Revenue Report <DownloadIcon />
      </button>
      <button
        onClick={handleDownloadPurchaseReport}
        className="border border-black hover:bg-success rounded hover:text-white p-2"
      >
        Download Purchase Report <DownloadIcon />
      </button>
      <button
        onClick={handleDownloadExpenseReport}
        className="border border-black hover:bg-success rounded hover:text-white p-2"
      >
        Download Expense Report <DownloadIcon />
      </button>
    </div>
  );
};

export default DownloadUserReport;
