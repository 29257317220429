import React, { useState, useEffect, useRef } from "react";
import { Dropdown, Menu, Space, Table, Tag } from "antd";
import FactCheckIconOutlined from "@mui/icons-material/FactCheckOutlined";
import { DownOutlined } from "@ant-design/icons";
import axios from "axios";
import EditItemForm from "./EditItem";
import { apiUrl, isAdminLoggedIn } from "../../../ApiUrlAndToken";
import { hoverScale } from "../../../components/DesignStandardize";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import DetailsModal from "./DetailsModal";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import Highlighter from "react-highlight-words";

const AllIncomingPayments = () => {
  const [items, setItems] = useState([]);
  const [editingItemID, setEditingItemID] = useState(null);
  const jwt_token = useSelector((state) => state.global.jwt_token);
  const [item, setItem] = useState(null);
  const [bankaccounts, setBankAccounts] = useState([]);

  const [detailsModal, setDetailsModal] = useState(false);
  const toggleDetailsModal = () => {
    setDetailsModal((prevShowModal) => !prevShowModal);
  };
  const ProjectDetailsModal = (item) => {
    setItem(item);
    setDetailsModal((prevShowModal) => !prevShowModal);
  };

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/bank-accounts/`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        setBankAccounts(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchGroups();
  }, []);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/incoming-payments/`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        setItems(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchGroups();
  }, []);

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal((prevShowModal) => !prevShowModal);
  };

  const handleEdit = (id) => {
    setEditingItemID(id);
    toggleModal();
  };

  const handleDelete = async (id, name) => {
    if (window.confirm(`Are you sure you want to delete ${name}?`)) {
      try {
        await axios.delete(`${apiUrl}/api/incoming-payments/${id}/`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        window.location.reload();
        toast.warning("Incoming Payments has been deleted!!");
      } catch (error) {
        console.error(error);
      }
    }
  };

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const generateMenuItems = (row) => [
    {
      key: "1",
      label: "View",
      onClick: () => ProjectDetailsModal(row),
    },
    {
      key: "2",
      label: "Edit",
      onClick: () => handleEdit(row.id),
    },
    {
      key: "4",
      label: <span style={{ color: "red" }}>Delete</span>,
      onClick: () => handleDelete(row.id, row.remarks),
    },
  ];

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      ...getColumnSearchProps("date"),
    },
    {
      title: "Payment Source",
      dataIndex: "payment_source",
      key: "payment_source",
      render: (text) => <a>{text}</a>,
      ...getColumnSearchProps("payment_source"),
    },
    {
      title: "Description",
      dataIndex: "remarks",
      key: "remarks",
      ...getColumnSearchProps("remarks"),
    },
    {
      title: "Bank Account",
      dataIndex: "bankaccount",
      key: "bankaccount",
      render: (bankaccount) => {
        return <p>{bankaccounts.find(({ id }) => id == bankaccount)?.name}</p>;
      },
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (totalAmount) => {
        return <p>{parseFloat(totalAmount).toFixed(2)}</p>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (row) => (
        <Space size="middle">
          <Dropdown overlay={<Menu items={generateMenuItems(row)} />}>
            <a>
              Actions{" "}
              <DownOutlined
                style={{
                  fontSize: "10px",
                }}
              />
            </a>
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="mt-[40px]">
        <div className=" flex space-x-[20px] place-items-center font-bold">
          <div className="">
            <FactCheckIconOutlined
              fontSize="100px"
              style={{
                height: "40px",
                width: "40px",
                color: "#334155",
              }}
              color="#334155"
            />
          </div>
          <p className="text-[30px]">All Incoming Payments</p>
        </div>
        <div className="mt-[30px] pb-[100px]">
          <Table
            columns={columns}
            dataSource={items}
            pagination={{ pageSize: 20 }}
          />
        </div>
      </div>

      {modal && editingItemID && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex justify-between">
              <p className="text-xl text-blue-900 font-bold">
                Edit Item Details
              </p>
              <CloseIcon
                onClick={toggleModal}
                fontSize="1px"
                className={`hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
              />
            </div>
            <EditItemForm
              id={editingItemID}
              onCancel={() => setEditingItemID(null)}
            />
          </div>
        </div>
      )}
      {detailsModal && item && (
        <div className="z-[9999999999999] fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleDetailsModal}
          />
          <div className="card text-black bg-white z-[9999999] p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex justify-between">
              <p>Details</p>
              <CloseIcon
                onClick={toggleDetailsModal}
                fontSize="1px"
                className={`hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
              />
            </div>

            <DetailsModal item={item} />
          </div>
        </div>
      )}
    </>
  );
};
export default AllIncomingPayments;
