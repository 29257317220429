import React, { useState } from "react";
import EditIcon from "@mui/icons-material/BorderColor";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import GeneralModal from "./GeneralModal";
const tableCellClasses = "py-[10px] px-[25px] font-semibold text-center";
const flexItemsCenterClasses = "flex items-center space-x-[5px]";

export const Table = ({
  items,
  handleEdit,
  handleDelete,
  searchString,
  expense_category,
  handleView,
}) => {
  const is_staff = useSelector((state) => state.global.is_staff);
  console.log(items);
  const [viewVoucher, setViewVoucher] = useState(false);
  const [invoiceImage, setInvoiceImage] = useState(null);

  return (
    <>
      <div className="overflow-x-auto pb-[40px] bg-white">
        <table className="min-w-full mt-[20px] bg-white text-black">
          <thead className="bg-slate-300 text-black">
            <tr className="w-full bg-[#FAFAFA] text-black py-[30px] ">
              {items.find((obj) => obj["id"]) && (
                <th className={tableCellClasses}>SL</th>
              )}
              {items.find((obj) => obj["date"]) && (
                <th className={tableCellClasses}>Date</th>
              )}
              {items.find((obj) => obj["expense_list"]) && (
                <th className={tableCellClasses}>Category</th>
              )}
              {items.find((obj) => obj["name"]) && (
                <th className={tableCellClasses}>Name</th>
              )}
              {items.find((obj) => obj["description"]) && (
                <th className={tableCellClasses}>Description</th>
              )}
              {items.find((obj) => obj["price"]) && (
                <th className={tableCellClasses}>Price</th>
              )}
              {items.find((obj) => obj["amount"]) && (
                <th className={tableCellClasses}>Amount</th>
              )}
              {items.find((obj) => obj["item_type"]) && (
                <th className={tableCellClasses}>Type</th>
              )}
              {items.find((obj) => obj["code"]) && (
                <th className={tableCellClasses}>Code</th>
              )}
              {items.find((obj) => obj["address"]) && (
                <th className={tableCellClasses}>Address</th>
              )}
              {items.find((obj) => obj["vendor_type"]) && (
                <th className={tableCellClasses}>Type</th>
              )}
              {items.find((obj) => obj["contact"]) && (
                <th className={tableCellClasses}>Contact</th>
              )}
              {items.find((obj) => obj["group"]) && (
                <th className={tableCellClasses}>Group</th>
              )}
              {items.find((obj) => obj["total_ar_invoice_amount"]) && (
                <th className={tableCellClasses}>Recieved</th>
              )}
              {items.find((obj) => obj["total_purchase_amount"]) && (
                <th className={tableCellClasses}>Purchased</th>
              )}
              {items.find((obj) => obj["bank"]) && (
                <th className={tableCellClasses}>Bank</th>
              )}
              {items.find((obj) => obj["account_number"]) && (
                <th className={tableCellClasses}>Account Number</th>
              )}
              {items.find((obj) => obj["owner"]) && (
                <th className={tableCellClasses}>Owner</th>
              )}
              {is_staff && <th className={tableCellClasses}>Actions</th>}
            </tr>
          </thead>
          <tbody className="bg-white text-black">
            {items
              .filter((data) =>
                (data?.description || data?.name)
                  ?.toLowerCase()
                  .includes(searchString?.toLowerCase())
              )
              .map((item, index) => (
                <tr className="border-t">
                  {item.id && <td className={tableCellClasses}>{item.id}</td>}
                  {item.date && (
                    <td className={tableCellClasses}>{item.date}</td>
                  )}
                  {item.expense_list && (
                    <td className={tableCellClasses}>
                      {
                        expense_category?.find(
                          (data) => data.value === item.expense_list
                        )?.label
                      }
                    </td>
                  )}
                  {item.name && (
                    <td className={tableCellClasses}>{item.name}</td>
                  )}
                  {item.description && (
                    <td className={tableCellClasses}>{item.description}</td>
                  )}
                  {item.price && (
                    <td className={tableCellClasses}>{item.price} BDT</td>
                  )}
                  {item.amount && (
                    <td className={tableCellClasses}>{item.amount} BDT</td>
                  )}
                  {item.item_type && (
                    <td className={tableCellClasses}>{item.item_type}</td>
                  )}
                  {item.code && (
                    <td className={tableCellClasses}>{item.code}</td>
                  )}
                  {item.address && (
                    <td className={tableCellClasses}>{item.address}</td>
                  )}
                  {item.vendor_type && (
                    <td className={tableCellClasses}>{item.vendor_type}</td>
                  )}
                  {item.contact && (
                    <td className={tableCellClasses}>{item.contact}</td>
                  )}
                  {item.group && (
                    <td className={tableCellClasses}>{item.group}</td>
                  )}
                  {item.total_ar_invoice_amount !== undefined &&
                    item.total_ar_invoice_amount !== null && (
                      <td className={tableCellClasses}>
                        {item.total_ar_invoice_amount < 1
                          ? "No Revenue Added"
                          : `${item.total_ar_invoice_amount} BDT`}
                      </td>
                    )}
                  {item.total_purchase_amount !== undefined &&
                    item.total_purchase_amount !== null && (
                      <td className={tableCellClasses}>
                        {item.total_purchase_amount < 1
                          ? "No Purchase Added"
                          : `${item.total_purchase_amount} BDT`}
                      </td>
                    )}
                  {item.owner && (
                    <td className={`${tableCellClasses}`}>
                      {item.owner !== null ? item.owner : "Null"}
                    </td>
                  )}
                  {item.bank && (
                    <td className={tableCellClasses}>{item.bank}</td>
                  )}
                  {item.account_number && (
                    <td className={tableCellClasses}>{item.account_number}</td>
                  )}
                  {is_staff && (
                    <td
                      className={`${tableCellClasses} ${flexItemsCenterClasses} ml-[18%]`}
                    >
                      {/* {item.image ||
                        (item.picture && (
                          <button
                            onClick={() => {
                              setInvoiceImage(item.image ?? item.picture);
                              setViewVoucher(true);
                            }}
                            className={`mb-auto h-[34px] w-[34px] hover:cursor-pointer flex items-center justify-center rounded-md text-[#0d6efd] space-x-2 hover:bg-slate-200 bg-transparent border-[1px] border-[#0d6efd]/50  p-2  place-items-center place-content-center `}
                          >
                            <VisibilityIcon
                              style={{
                                height: "16px",
                                width: "16px",
                              }}
                            />
                          </button>
                        ))} */}
                      <button
                        onClick={() => handleEdit(item.id)}
                        className={`mb-auto w-[40px] hover:text-[#0dcaf0] hover:cursor-pointer flex items-center justify-center rounded-md text-[#0dcaf0] space-x-2 hover:bg-slate-200 bg-transparent border-[1px] border-[#0dcaf0]/50  p-2  place-items-center place-content-center `}
                      >
                        <EditIcon
                          style={{
                            height: "16px",
                            width: "16px",
                          }}
                        />
                      </button>
                      <button
                        onClick={() =>
                          handleDelete(item.id, item.name ?? item.description)
                        }
                        className={`mb-auto w-[40px] hover:cursor-pointer flex items-center justify-center rounded-md text-[#dc3545] space-x-2 bg-transparent border-[1px] border-[#dc3545]/50 hover:bg-slate-200  p-2  place-items-center place-content-center`}
                      >
                        <DeleteIcon
                          style={{
                            height: "16px",
                            width: "16px",
                          }}
                        />
                      </button>
                      {handleView && (
                        <button
                          onClick={() => handleView(item)}
                          className="mb-auto w-[40px] hover:cursor-pointer flex items-center justify-center rounded-md text-[#0dcaf0] space-x-2 bg-transparent border-[1px] border-[#0dcaf0]/50 hover:bg-slate-200  p-2  place-items-center place-content-center"
                        >
                          <VisibilityIcon
                            style={{
                              height: "16px",
                              width: "16px",
                            }}
                          />
                        </button>
                      )}
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
        {items.filter(
          (data) =>
            data?.name?.toLowerCase().includes(searchString.toLowerCase()) ||
            data?.description
              ?.toLowerCase()
              .includes(searchString.toLowerCase())
        ).length == 0 && (
          <div className="flex w-full h-full">
            <h1 className="text-[20px] md:text-[40px] font-semibold text-black/70 w-full text-center mt-[20%]">
              No items are available !
            </h1>
          </div>
        )}
      </div>
      {viewVoucher && (
        <GeneralModal
          title={"Invoice Image"}
          onClose={() => setViewVoucher(false)}
        >
          <img src={invoiceImage} className="h-auto w-auto" />
        </GeneralModal>
      )}
    </>
  );
};
