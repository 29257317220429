import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import GeneralInput from "../../../components/GeneralInput";
import { submitButton } from "../../../components/DesignStandardize";
import { isAdminLoggedIn } from "../../../ApiUrlAndToken";
import { useSelector } from "react-redux";
import GeneralSelect from "../../../components/GeneralSelect";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const EditItemGroupForm = ({ id }) => {
  const jwt_token = useSelector((state) => state.global.jwt_token);
  const [banks, setBanks] = useState([]);
  const [bank, setBank] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [account_number, setAccountNumber] = useState("");

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/banks/`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        setBanks(
          response.data.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        );
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchGroups();
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/bank-accounts/${id}/`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        const res = response.data;
        setAddress(res.address);
        setName(res.name);
        setBank(res.bank);
        setAccountNumber(res.account_number);
        console.log(res);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUser();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("address", address);
      formData.append("account_number", account_number);
      formData.append("bank", bank);

      const response = await axios.put(
        `${apiUrl}/api/bank-accounts/${id}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        }
      );

      toast.success(`Account Updated Successfully`);
      console.log(response);
      setTimeout(() => {
        window.location.href = "/admin/bank-accounts";
      }, 300);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form className="p-2 flex flex-col max-h-96 overflow-y-auto gap-3 items-center w-full mt-auto md:mr-auto font-semibold font-Inter text-sm text-left md:text-base">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        <div className="">
          <GeneralInput
            className={""}
            label="Details"
            id="name"
            name="name"
            type="text"
            placeholder="Details"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </div>
        <div className="">
          <GeneralInput
            className={""}
            label="Account Number"
            id="account_number"
            name="account_number"
            type="text"
            placeholder="Account Number"
            onChange={(e) => setAccountNumber(e.target.value)}
            value={account_number}
          />
        </div>
        <div className="">
          <GeneralInput
            className={""}
            label="Address"
            id="address"
            name="address"
            type="text"
            placeholder="Address"
            onChange={(e) => setAddress(e.target.value)}
            value={address}
          />
        </div>
        <div>
          <GeneralSelect
            options={banks}
            label="Select Bank" // Corrected from 'lable' to 'label'
            placeholder="Select Bank"
            onChange={(selectedOption) => setBank(selectedOption.value)}
            value={banks.find((option) => option.value === bank) || ""}
          />
        </div>
      </div>
      <button
        className={`${submitButton} h-[50px] mt-[30px] w-max`}
        onClick={(e) => handleSubmit(e)}
      >
        Update Account
      </button>
    </form>
  );
};

export default EditItemGroupForm;
