import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiUrl, isAdminLoggedIn } from "../../ApiUrlAndToken";
import { hoverScale, submitButton } from "../../components/DesignStandardize";

import GeneralModal from "../../components/GeneralModal";
import { useSelector } from "react-redux";
import PageTitle from "../pageTitle/PageTitle";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { HitApi } from "../../utils/Api_Loader";
import PurchaseAdd from "./components/PurchaseAdd";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";

const Purchase = () => {
  const [purchases, setPurchases] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  const [rows, setRows] = useState([
    { quantity: "", amount: "", item: "", purchase: null },
  ]);

  useEffect(() => {
    const total = rows.reduce((sum, row) => {
      const quantity = parseFloat(row.quantity) || 0;
      const amount = parseFloat(row.amount) || 0;
      return sum + quantity * amount;
    }, 0);
    setTotalAmount(total);
  }, [rows]);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newRows = [...rows];
    newRows[index][name] = value;
    setRows(newRows);
  };

  const handleDeleteRow = (index) => {
    const newRows = rows.filter((_, rowIndex) => rowIndex !== index);
    setRows(newRows);
  };

  const handleAddRow = () => {
    const newRows = [
      ...rows,
      {
        quantity: "",
        amount: "",
        item: "",
        purchase: null,
      },
    ];
    setRows(newRows);
    console.log(rows);
  };

  const handleRemoveLastRow = () => {
    const newRows = rows.slice(0, -1);
    setRows(newRows);
  };

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal((prevShowModal) => !prevShowModal);
  };

  const getPurchases = async () => {
    try {
      const response = await HitApi("/api/purchases/");
      setPurchases(response);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPurchases();
  }, [purchases.length, modal]);

  // const items = [];
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await HitApi("/api/item/");

        setItems(
          response.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        );
        console.log(items);
        console.log(response);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchGroups();
  }, []);

  return (
    <>
      <PageTitle title="Purchases" />
      <div
        className={`text-left flex flex-col justify-start pt-[120px] h-screen transition-all duration-700 overflow-x-auto md:overflow-x-hidden`}
      >
        <div className=" flex space-x-[20px] place-items-center font-bold">
          <div className="">
            <LocalAtmIcon
              fontSize="100px"
              style={{
                height: "40px",
                width: "40px",
              }}
            />
          </div>
          <p className="text-[30px]">Purchase Items</p>
        </div>

        <div className="w-full mt-10 ">
          <table className="min-w-full border border-gray-300">
            <thead>
              <tr className="text-center">
                <th className="py-[30px] px-[25px] border-b border-gray-300">
                  Quantity
                </th>
                <th className="py-[30px] px-[25px]  border-b border-gray-300">
                  Amount
                </th>
                <th className="py-[30px] px-[25px]  border-b border-gray-300">
                  Item
                </th>
                <th className="py-[30px] px-[25px]  border-b border-gray-300">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="text-black">
              {rows.map((row, index) => (
                <tr key={index}>
                  <td className="py-[30px] px-[25px]  border-b border-gray-300">
                    <input
                      type="number"
                      min={1}
                      name="quantity"
                      value={row.quantity}
                      placeholder="Quantity"
                      onChange={(e) => handleInputChange(index, e)}
                      className="w-full px-[12px] py-1 h-[60px] text-[20px] border-2 border-black/40 rounded-xl bg-white"
                    />
                  </td>
                  <td className="py-[30px] px-[25px]  border-b border-gray-300">
                    <input
                      name="amount"
                      type="number"
                      min={1}
                      placeholder="Amount"
                      value={row.amount}
                      onChange={(e) => handleInputChange(index, e)}
                      className="w-full px-[12px] py-1 h-[60px] text-[20px] border-2 border-black/40 rounded-xl bg-white"
                    />
                  </td>

                  <td className="py-[30px] px-[25px] border-b border-gray-300">
                    <select
                      name="item"
                      value={row.item}
                      onChange={(e) => handleInputChange(index, e)}
                      className="w-full px-[12px] py-1 h-[60px] text-[20px] border-2 border-black/40 rounded-xl bg-white"
                    >
                      <option value="">Select Item</option>
                      {items.map((option, optionIndex) => (
                        <option key={optionIndex} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className="py-[30px] px-[25px]  border-b border-gray-300 text-center">
                    <button
                      onClick={() => handleDeleteRow(index)}
                      className="px-4 py-2 h-[60px] text-[20px] rounded-2xl border-[1px] border-red-600 text-red-600 bg-white"
                    >
                      <DeleteIcon />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="my-10 mx-3 flex place-items-center space-x-3">
          <button
            onClick={() => handleAddRow()}
            className="px-4 flex place-items-center space-x-2 py-2 border-2 bg-white border-[#334155] text-[#334155] rounded-2xl"
          >
            <AddIcon fontSize="60px" />
            <p>Add a Item</p>
          </button>
          <button
            onClick={() => handleRemoveLastRow()}
            className="px-4 flex place-items-center space-x-2 py-2 bg-white text-red-600 border-[2px] border-red-600 rounded-2xl"
          >
            <AddIcon fontSize="60px" />
            <p>Delete Last Item</p>
          </button>
        </div>
      </div>

      {isAdminLoggedIn && (
        <div
          className={`w-max hidden md:place-content-center md:place-items-center md:flex fixed bottom-2 right-2 hover:cursor-pointer font-Inter ${submitButton} ${hoverScale}`}
          onClick={toggleModal}
        >
          <AddCircleIcon className="text-sm mr-1" />{" "}
          <span>Add New Purchase</span>
        </div>
      )}

      {modal && (
        <GeneralModal title="New Purchase" onClose={toggleModal}>
          <PurchaseAdd
            total_amount_from_items={totalAmount}
            purchaseItems={rows}
            toggleModal={toggleModal}
            purchases={purchases}
            setPurchases={setPurchases}
          />
        </GeneralModal>
      )}
    </>
  );
};

export default Purchase;
