import React, { useEffect, useState } from "react";
import DownloadIcon from "@mui/icons-material/DownloadOutlined";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const DownloadAllProjectReport = ({ reportData }) => {
  const [itemSummaryReport, setItemReport] = useState([]);
  const [purchaseReport, setPurchaseReport] = useState([]);
  const [extraExpenseReport, setExtraExpenseReport] = useState([]);
  const getUserReportByID = async () => {
    try {
      setItemReport(
        reportData.ar_invoices.map((item) => ({
          Date: item.date,
          "Business Partner Name": item.business_partner__name,
          "Total Amount": item.total_amount,
        }))
      );
      setPurchaseReport(
        reportData.purchases.map((item) => ({
          Date: item.date,
          "Business Partner Name": item.business_partner__name,
          "Total Amount": item.total_amount,
        }))
      );
      setExtraExpenseReport(
        reportData.extra_expenses.map((item) => ({
          Date: item.date,
          "Expense Name": item.expense_list__name,
          "Total Amount": item.amount,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadRevenueReport = () => {
    // Convert data to Excel format
    const worksheet = XLSX.utils.json_to_sheet(itemSummaryReport);
    console.log(itemSummaryReport);
    // Set column widths
    const columnWidths = [{ wch: 20 }, { wch: 20 }, { wch: 20 }];

    worksheet["!cols"] = columnWidths;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      "All Project Revenue Report"
    );

    // Generate a download
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(
      blob,
      `Revenue Report ${reportData.start_date} to ${reportData.end_date}.xlsx`
    );
  };

  const handleDownloadPurchaseReport = () => {
    // Convert data to Excel format
    const worksheet = XLSX.utils.json_to_sheet(purchaseReport);
    console.log(purchaseReport);
    // Set column widths
    const columnWidths = [{ wch: 20 }, { wch: 20 }, { wch: 20 }];

    worksheet["!cols"] = columnWidths;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Purchase Report");

    // Generate a download
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(
      blob,
      `Purchase Report ${reportData.start_date} to ${reportData.end_date}.xlsx`
    );
  };

  const handleDownloadExpenseReport = () => {
    // Convert data to Excel format
    const worksheet = XLSX.utils.json_to_sheet(extraExpenseReport);
    console.log(extraExpenseReport);
    // Set column widths
    const columnWidths = [{ wch: 20 }, { wch: 30 }, { wch: 20 }];

    worksheet["!cols"] = columnWidths;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Extra Expense Report");

    // Generate a download
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(
      blob,
      `Extra Expense Report ${reportData.start_date} to ${reportData.end_date}.xlsx`
    );
  };

  useEffect(() => {
    getUserReportByID();
  }, [reportData]);
  return (
    <div className="flex place-items-center space-x-3 my-[30px] justify-center items-center">
      <button
        onClick={handleDownloadRevenueReport}
        className="border border-black hover:bg-success rounded hover:text-white p-1"
      >
        Download Revenue Report <DownloadIcon />
      </button>
      <button
        onClick={handleDownloadPurchaseReport}
        className="border border-black hover:bg-success rounded hover:text-white p-1"
      >
        Download Purchase Report <DownloadIcon />
      </button>
      <button
        onClick={handleDownloadExpenseReport}
        className="border border-black hover:bg-success rounded hover:text-white p-1"
      >
        Download Extra Expense Report <DownloadIcon />
      </button>
    </div>
  );
};

export default DownloadAllProjectReport;
