import axios from "axios";
import React, { useEffect, useState } from "react";
import LocationIcon from "@mui/icons-material/LocationOn";
import { apiUrl } from "../../../ApiUrlAndToken";

const CompletedEmpProjects = ({ userId }) => {
  const [projects, setProjects] = useState([]);
  const getProjects = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/assign/fetchProjectsByUserID/${userId}`
      );
      // console.log(response.data);
      const projectsData = response.data;

      // Fetch detailed information for each project
      const projectsWithDetails = await Promise.all(
        projectsData.map(async (project) => {
          const detailedInfo = await getProjectDetails(project.ID);
          // console.log(detailedInfo);
          return { ...project, ...detailedInfo };
        })
      );
      const ongoingProjects = projectsWithDetails.filter(
        (item) => item.Status === 2
      );
      // console.log(ongoingProjects);
      setProjects(ongoingProjects);
    } catch (error) {
      console.log(error);
    }
  };
  const getProjectDetails = async (projectId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/project/fetchProject/${projectId}`
      );
      return response.data; // Assuming the detailed information is directly available in the response
    } catch (error) {
      console.log(error);
      return {}; // Or handle the error appropriately
    }
  };
  useEffect(() => {
    getProjects();
  }, [userId]);
  return (
    <div>
      <div className="sticky top-0 bg-white border-b-[1px] pb-1">
        <p className="text-black bg-success w-max p-1 md:p-2 text-sm md:text-base mx-auto rounded-lg">
          Completed
        </p>
      </div>

      <div className="flex flex-row md:flex-col gap-2 max-h-96 max-w-60 overflow-auto">
        {projects.map((el, i) => {
          return (
            <div
              key={i}
              className={`p-1 md:p-2 border-[1px] border-solid rounded-xl flex flex-col gap-1 md:gap-3 hover:shadow md:mb-2 hover:cursor-pointer`}
            >
              <p className="md:text-xl font-semibold">{el.ProjectName}</p>

              <div className="text-center border w-56">
                <div className="border-b-2 flex gap-1">
                  <p className="p-1 border-r-2 w-24">Start Date:</p>
                  <p className="p-1">{el.Start_Date.split("T")[0]}</p>
                </div>
                <div className="border-b-2 flex gap-1">
                  <p className="p-1 border-r-2 w-24">End Date:</p>
                  <p className="p-1">{el.End_Date.split("T")[0]}</p>
                </div>
                <div className="flex gap-1">
                  <p className="p-1 border-r-2 w-24">Location:</p>
                  <p className="p-1">{el.Location}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CompletedEmpProjects;
