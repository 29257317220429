import React, { useEffect, useState } from "react";
import DownloadIcon from "@mui/icons-material/DownloadOutlined";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const DownloadBalanceSheet = ({ reportData }) => {
  const [revenueReport, setRevenueReport] = useState([]);
  const getUserReportByID = async () => {
    try {
      setRevenueReport(
        reportData?.map((item) => ({
          Account: item.account,
          Balance: item.balance,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownload = () => {
    // Convert data to Excel format
    const worksheet = XLSX.utils.json_to_sheet(revenueReport);
    console.log(revenueReport);
    // Set column widths
    const columnWidths = [{ wch: 20 }, { wch: 20 }];

    worksheet["!cols"] = columnWidths;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Balance Sheet Report");

    // Generate a download
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, `Balance Sheet Report.xlsx`);
  };

  useEffect(() => {
    getUserReportByID();
  }, [reportData]);
  return (
    <div className="flex place-items-center space-x-3 my-[30px] justify-center items-center">
      <button
        onClick={handleDownload}
        className="border border-black hover:bg-success rounded hover:text-white p-2"
      >
        Download Balance Sheet <DownloadIcon />
      </button>
    </div>
  );
};

export default DownloadBalanceSheet;
