import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl, isAdminLoggedIn } from "../../../ApiUrlAndToken";
import { hoverScale } from "../../../components/DesignStandardize";
import EditIcon from "@mui/icons-material/BorderColor";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ProjectsIcon from "@mui/icons-material/HomeWorkOutlined";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import EditItemGroupForm from "./EditItemGroup";
import SearchInput from "../../../components/SearchInput";
import { Table } from "../../../components/Table";
import GeneralModal from "../../../components/GeneralModal";
import { Descriptions } from "antd";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const ItemGroupsList = () => {
  const [itemgroups, setItemGroups] = useState([]);
  const [editingGroupId, setEditingGroupId] = useState(null);
  const [viewGroupId, setViewGroupId] = useState(null);
  const [projectModalID, setProjectModalID] = useState(null);
  const jwt_token = useSelector((state) => state.global.jwt_token);
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/extra-expense-lists/`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        setItemGroups(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchGroups();
  }, []);

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal((prevShowModal) => !prevShowModal);
  };
  const [projectModal, setProjectModal] = useState(false);
  const toggleProjectModal = () => {
    setProjectModal(!projectModal);
  };
  const ProjectFetch = (userID) => {
    setProjectModalID(userID);
    toggleProjectModal();
  };
  const handleEdit = (id) => {
    setEditingGroupId(id);
    toggleModal();
  };

  const handleView = (id) => {
    setViewGroupId(id);
    // toggleViewModal();
  };

  const handleDelete = async (id, name) => {
    if (window.confirm(`Are you sure you want to remove ${name}?`)) {
      try {
        await axios.delete(`${apiUrl}/api/extra-expense-lists/${id}/`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        const response = await axios.get(`${apiUrl}/api/extra-expense-lists/`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        setItemGroups(response.data);
        toast.warning("Group Deleted Successfully");
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    }
  };

  return (
    <>
      <div className="h-screen pb-[200px]">
        <SearchInput
          onChange={(e) => setSearchString(e.target.value)}
          value={searchString}
        />

        <div className="mt-[30px]">
          <Table
            items={itemgroups}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handleView={handleView}
            searchString={searchString}
          />
        </div>
      </div>

      {modal && editingGroupId && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex justify-between">
              <p className="text-xl text-blue-900 font-bold">Edit Category</p>
              <CloseIcon
                onClick={toggleModal}
                fontSize="1px"
                className={`hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
              />
            </div>
            <EditItemGroupForm
              id={editingGroupId}
              onCancel={() => setEditingGroupId(null)}
            />
          </div>
        </div>
      )}
      {viewGroupId && (
        <GeneralModal
          title="View Group Details"
          onClose={() => setViewGroupId(null)}
        >
          <Descriptions bordered>
            <Descriptions.Item label="ID">{viewGroupId.id}</Descriptions.Item>
            <Descriptions.Item label="Name">
              {viewGroupId.name}
            </Descriptions.Item>
            <Descriptions.Item label="Owner">
              {viewGroupId.owner}
            </Descriptions.Item>
          </Descriptions>
        </GeneralModal>
      )}
      {projectModal && projectModalID && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleProjectModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex justify-between">
              <p className="text-xl text-blue-900 font-bold">
                See Individual Projects
              </p>
              <CloseIcon
                onClick={toggleProjectModal}
                fontSize="1px"
                className={`hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ItemGroupsList;
