import React from "react";
import InsertChartIconOutlined from "@mui/icons-material/InsertChartOutlined";
import BarChart from "./components/BarChart";
import { MultiAxisLineChart } from "./components/MultiAxisLineChart";

function ProjectStatistics() {
  return (
    <div className="">
      <div className=" flex space-x-[20px] place-items-center font-bold">
        <div className="">
          <InsertChartIconOutlined
            fontSize="100px"
            style={{
              height: "40px",
              width: "40px",
              color: "#334155",
            }}
            color="#334155"
          />
        </div>
        <p className="text-[30px] my-[20px]">Project Statistics</p>
      </div>
      <div className="flex h-auto space-y-[0px] mt-[30px]">
        <div className="w-[100%]">
          <BarChart />
        </div>
      </div>
      <div className="flex h-auto space-y-[0px] py-[40px]">
        <div className="w-[100%]">
          <MultiAxisLineChart />
        </div>
      </div>
    </div>
  );
}

export default ProjectStatistics;
