import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl, isAdminLoggedIn } from "../../../ApiUrlAndToken";
import { hoverScale } from "../../../components/DesignStandardize";
import EditIcon from "@mui/icons-material/BorderColor";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import ProjectsIcon from "@mui/icons-material/HomeWorkOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";
import EmployeeProjects from "../employeeProjects/EmployeeProjects";
import { useSelector } from "react-redux";
import EditPartnerForm from "./EditPartner";
import { Table } from "../../../components/Table";
import SearchInput from "../../../components/SearchInput";
import GeneralModal from "../../../components/GeneralModal";
import { Descriptions } from "antd";

const CustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [editingUserId, setEditingUserId] = useState(null);
  const [projectModalID, setProjectModalID] = useState(null);
  const [viewingCustomer, setViewingCustomer] = useState(null);
  const jwt_token = useSelector((state) => state.global.jwt_token);
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/businesspartner/`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        setCustomers(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal((prevShowModal) => !prevShowModal);
  };
  const [projectModal, setProjectModal] = useState(false);
  const toggleProjectModal = () => {
    setProjectModal(!projectModal);
  };
  const ProjectFetch = (userID) => {
    setProjectModalID(userID);
    toggleProjectModal();
  };
  const handleEdit = (id) => {
    setEditingUserId(id);
    toggleModal();
  };

  const handleDelete = async (id, name) => {
    if (window.confirm(`Are you sure you want to remove ${name}?`)) {
      try {
        await axios.delete(`${apiUrl}/api/businesspartner/${id}/`, {
          headers: {
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        });
        // Refresh the user list after deletion
        const response = await await axios.get(
          `${apiUrl}/api/businesspartner/`,
          {
            headers: {
              Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
            },
          }
        );
        setCustomers(response.data);
        toast.warning("Partner Deleted Successfully");
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    }
  };

  const handleView = (customer) => {
    setViewingCustomer(customer);
  };

  const closeViewModal = () => {
    setViewingCustomer(null);
  };

  return (
    <>
      <div className="my-[30px] pt-[14px]">
        <SearchInput
          onChange={(e) => setSearchString(e.target.value)}
          value={searchString}
        />
      </div>
      <div>
        <Table
          items={customers}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleView={handleView}
          searchString={searchString}
        />
      </div>

      {modal && editingUserId && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex justify-between">
              <p className="text-xl text-blue-900 font-bold">
                Edit Partner Details
              </p>
              <CloseIcon
                onClick={toggleModal}
                fontSize="1px"
                className={`hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
              />
            </div>
            <EditPartnerForm
              id={editingUserId}
              onCancel={() => setEditingUserId(null)}
            />
          </div>
        </div>
      )}
      {projectModal && projectModalID && (
        <div className="z-10 fixed inset-0 flex items-center justify-center">
          <div
            className="bg-black bg-opacity-50 fixed inset-0"
            onClick={toggleProjectModal}
          />
          <div className="card bg-white z-20 p-4 modal-white flex flex-col gap-4">
            <div className="card-title flex justify-between">
              <p className="text-xl text-blue-900 font-bold">
                See Individual Projects
              </p>
              <CloseIcon
                onClick={toggleProjectModal}
                fontSize="1px"
                className={`hover:cursor-pointer hover:bg-blue-900 w-5 h-5 hover:text-white rounded-full p-[1px] ${hoverScale}`}
              />
            </div>
            <EmployeeProjects
              userId={projectModalID}
              onCancel={() => setProjectModalID(null)}
            />
          </div>
        </div>
      )}
      {viewingCustomer && (
        <GeneralModal title={"Customer Details"} onClose={closeViewModal}>
          <div>
            <Descriptions bordered>
              <Descriptions.Item label="ID">
                {viewingCustomer.id}
              </Descriptions.Item>
              <Descriptions.Item label="Total Sales Amount">
                {viewingCustomer.total_sales_amount}
              </Descriptions.Item>
              <Descriptions.Item label="Total Purchase Amount">
                {viewingCustomer.total_purchase_amount}
              </Descriptions.Item>
              <Descriptions.Item label="Total AR Invoice Amount">
                {viewingCustomer.total_ar_invoice_amount}
              </Descriptions.Item>
              <Descriptions.Item label="Code">
                {viewingCustomer.code}
              </Descriptions.Item>
              <Descriptions.Item label="Name">
                {viewingCustomer.name}
              </Descriptions.Item>
              <Descriptions.Item label="Address">
                {viewingCustomer.address}
              </Descriptions.Item>
              <Descriptions.Item label="Currency Type">
                {viewingCustomer.currency_type}
              </Descriptions.Item>
              <Descriptions.Item label="Contact">
                {viewingCustomer.contact}
              </Descriptions.Item>
              <Descriptions.Item label="Vendor Type">
                {viewingCustomer.vendor_type}
              </Descriptions.Item>
              <Descriptions.Item label="Remarks">
                {viewingCustomer.remarks}
              </Descriptions.Item>
              <Descriptions.Item label="Currency">
                {viewingCustomer.currency}
              </Descriptions.Item>
              <Descriptions.Item label="Owner">
                {viewingCustomer.owner}
              </Descriptions.Item>
            </Descriptions>
          </div>
        </GeneralModal>
      )}
    </>
  );
};

export default CustomerList;
