import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { apiUrl } from "../../../ApiUrlAndToken";
import { submitButton } from "../../../components/DesignStandardize";
import GeneralInput from "../../../components/GeneralInput";
import { jwtDecode } from "jwt-decode";
const AdminLogin = () => {
  // const { login } = useAuth();
  const [username, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const AdminLogin = async (e) => {
    e.preventDefault();
    if (username === "" || password === "") {
      toast.error("Please fill the required fields");
      return;
    }

    try {
      setLoading(true);
      const data = {
        username,
        password,
      };
      const response = await axios.post(`${apiUrl}/auth/jwt/create/`, data);
      console.log(response.data);
      if (response.status === 200) {
        const { refresh, access } = response.data;
        const decodedData = jwtDecode(access);
        const res = await axios.get(
          `${apiUrl}/api/user/${decodedData.user_id}/is_staff/`
        );
        if (res.data.is_staff) {
          localStorage.setItem("is_staff", true);
        }
        localStorage.setItem("token", access);
        localStorage.setItem("adminLoggedIn", true);
        toast.done("Logged In Successfully");
        setLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 100);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.response) {
        toast.error(`${error.response.data.detail}`);
      } else if (error.request) {
        toast.error("No response received from the server");
      } else {
        toast.error("An error occurred while processing the request");
      }
    }
  };
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };
  return (
    <div className="flex flex-col gap-3">
      <p className="text-xl text-blue-900 font-medium">
        Use provided Username and Password
      </p>
      <div className="card bg-[#334155]/60 w-[500px] flex justify-center items-center border-2 border-solid shadow-md">
        <div className="card-body px-5 py-10">
          <form
            className="flex flex-col text-white gap-3 items-center w-full mt-auto md:mr-auto font-semibold font-Inter text-sm text-left md:text-base"
            onSubmit={AdminLogin}
          >
            <GeneralInput
              label="Username"
              type="username"
              placeholder="Enter Username"
              value={username}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />

            <GeneralInput
              label="Password"
              type="password"
              placeholder="Enter Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <button className={`${submitButton} mt-3`}>
              {loading ? "Please Wait..." : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
