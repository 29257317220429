import axios from "axios";
import { apiUrl, isAdminLoggedIn } from "../ApiUrlAndToken";

export const HitApi = async (endpoint) => {
  const jwt_token = window.localStorage.getItem("token");

  const response = await axios.get(`${apiUrl}${endpoint}`, {
    headers: {
      Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
    },
  });

  return response.data;
};
