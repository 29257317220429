import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import GeneralInput from "../../../components/GeneralInput";
import { submitButton } from "../../../components/DesignStandardize";
import { apiUrl, isAdminLoggedIn } from "../../../ApiUrlAndToken";
import GeneralSelect from "../../../components/GeneralSelect";
import { useSelector } from "react-redux";

const AddNewItem = () => {
  const [name, setName] = useState(null);

  const jwt_token = useSelector((state) => state.global.jwt_token);
  const ownerID = window.localStorage.getItem("ownerID");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("owner", ownerID);

      const response = await axios.post(`${apiUrl}/api/banks/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
        },
      });

      console.log(response);
      toast.success(`New Bank Added`);
      setTimeout(() => {
        window.location.href = "/admin/banks";
      }, 500);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form className="flex flex-col gap-3 items-center w-full mt-auto md:mr-auto font-semibold font-Inter text-sm text-left md:text-base">
      <div className="flex flex-row place-items-center gap-4">
        <div className="">
          <GeneralInput
            className={""}
            label="Bank Name"
            id="name"
            name="name"
            type="text"
            placeholder="Enter Bank Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <button
          className={`${submitButton} h-[50px] mt-[30px] w-max`}
          onClick={(e) => handleSubmit(e)}
        >
          Add Bank
        </button>
      </div>
    </form>
  );
};

export default AddNewItem;
