import React, { useEffect, useState } from "react";
import GeneralInput from "../../../components/GeneralInput";
import GeneralSelect from "../../../components/GeneralSelect";
import { submitButton } from "../../../components/DesignStandardize";
import axios from "axios";
import { apiUrl, isAdminLoggedIn } from "../../../ApiUrlAndToken";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const EditRevenue = ({ invoiceID }) => {
  const [description, setDescription] = useState("");
  const [total_amount, setTotalAmount] = useState("");
  const [project, setProject] = useState("");
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [projects, setProjects] = useState([]);

  const jwt_token = useSelector((state) => state.global.jwt_token);

  const getOnGoingProjects = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/project/`, {
        headers: {
          Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
        },
      });

      const retrived = response.data;
      setProjects(
        retrived.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getOnGoingProjects();
  }, []);
  const EditInvoice = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("remarks", description);
    formData.append("total_amount", total_amount);
    formData.append("sales_order", project);
    if (file) {
      formData.append("picture", file);
    }

    try {
      const response = await axios.put(
        `${apiUrl}/api/ar-invoices/${invoiceID}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
          },
        }
      );
      toast.success("Invoice Edited Successfully!");
      console.log(response);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.log(error);
      if (error.response) {
        toast.error(`${error.response.data.message}`);
      } else if (error.request) {
        toast.error("No response received from the server");
      } else {
        toast.error("An error occurred while processing the request");
      }
    }
  };

  // Fetch user data when the component mounts
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/ar-invoices/${invoiceID}/`,
          {
            headers: {
              Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
            },
          }
        );
        const res = response.data;
        setDescription(res.remarks);
        setTotalAmount(res.total_amount);
        setProject(res.sales_order);
        setFile(res.picture);
        setFilePreview(res.picture);
        console.log(res);
        // Set fetched user data as initial values
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUser();
  }, [invoiceID]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFilePreview(URL.createObjectURL(selectedFile));
  };

  return (
    <form
      className="p-1 flex flex-col gap-3 items-center min-h-max max-h-[440px] overflow-y-auto mt-auto md:mr-auto font-semibold font-Inter text-sm text-left md:text-base"
      onSubmit={EditInvoice}
    >
      <div className="flex w-full flex-col md:flex-row gap-6 justify-center ">
        {/*  */}
        <div className="flex w-auto flex-col gap-3">
          <GeneralSelect
            options={projects}
            label="Select Project" // Corrected from 'lable' to 'label'
            placeholder="Select Project"
            onChange={(selectedOption) => setProject(selectedOption.value)}
            value={projects?.find((option) => option.value === project) || ""}
          />
          <GeneralInput
            label="Invoice Details"
            type="textarea"
            placeholder="Enter Invoice Details"
            value={description}
            required
            onChange={(e) => setDescription(e.target.value)}
          />
          <GeneralInput
            label="Total Amount"
            type="number"
            min={1}
            value={total_amount}
            onChange={(e) => setTotalAmount(e.target.value)}
          />
        </div>

        {/*  */}
        <div className="flex w-auto flex-col gap-3">
          {filePreview && (
            <div className="flex justify-center items-start flex-col gap-2">
              <h1 className="text-lg font-bold text-black">Invoice Image</h1>
              <Zoom>
                <img
                  src={filePreview}
                  className="w-[320px] h-[140px] rounded-xl object-cover"
                  alt="Invoice Image"
                />
              </Zoom>
            </div>
          )}

          <GeneralInput
            label={file ? "Change Image" : "Upload Image"}
            type="file"
            onChange={handleFileChange}
          />
        </div>
      </div>
      <button className={`${submitButton} mt-3`}>Update</button>
    </form>
  );
};

export default EditRevenue;
