import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isDrawerSwitched: false,
  jwt_token: window.localStorage.getItem("token"),
  is_staff: window.localStorage.getItem("is_staff"),
  dark_theme: false,
  ownerID: window.localStorage.getItem("ownerID"),
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    drawerSwitch: (state) => {
      state.isDrawerSwitched = !state.isDrawerSwitched;
    },
    themeSwitch: (state) => {
      state.dark_theme = !state.dark_theme;
    },
  },
});

export const { drawerSwitch, themeSwitch } = globalSlice.actions;

export default globalSlice.reducer;
