import React, { useState } from "react";
import PageTitle from "../pageTitle/PageTitle";
import { hoverScale, submitButton } from "../../components/DesignStandardize";
import { isAdminLoggedIn } from "../../ApiUrlAndToken";
import EngineerIcon from "@mui/icons-material/Engineering";
import GeneralModal from "../../components/GeneralModal";
import AddNewItemGroup from "./components/AddNewItemGroup";
import ItemGroupsList from "./components/ItemGroupsList";
import { useSelector } from "react-redux";

const ItemGroups = () => {
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal((prevShowModal) => !prevShowModal);
  };
  const is_staff = useSelector((state) => state.global.is_staff);

  return (
    <>
      <PageTitle title="Item Groups" />
      <div className="py-12">
        <ItemGroupsList />
      </div>

      {/* Button to add new employee */}
      <>
        {isAdminLoggedIn && is_staff && (
          <div
            className={`w-max  hidden md:block fixed bottom-2 right-2 hover:cursor-pointer font-Inter ${submitButton} ${hoverScale}`}
            onClick={toggleModal}
          >
            <EngineerIcon className="text-sm" /> Add Item Group
          </div>
        )}
        {isAdminLoggedIn && is_staff && (
          <div
            className={`w-max  fixed bottom-2 left-2 md:hidden hover:cursor-pointer font-Inter ${submitButton} ${hoverScale}`}
            onClick={toggleModal}
          >
            <EngineerIcon className="text-sm" /> Add Item Group
          </div>
        )}
      </>

      {/* To Register New Employee */}
      {modal && (
        <GeneralModal title="Add New Item Group" onClose={toggleModal}>
          <AddNewItemGroup />
        </GeneralModal>
      )}
    </>
  );
};

export default ItemGroups;
