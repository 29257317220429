import React, { useState } from "react";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";

function SearchInput({ value, onChange }) {
  return (
    <div className="flex flex-col md:flex-row space-y-[10px] md:space-y-0 md:space-x-[10px] md:place-items-center text-[24px] mt-[70px] font-semibold">
      <input
        type="search"
        value={value}
        onChange={onChange}
        className="outline-none p-3 h-[40px] w-[380px] border-2  border-black/50 rounded-lg text-black text-[18px] bg-white"
        placeholder="Start writing keywords..."
      />
      <div className="size-[43px] hidden md:flex place-items-center place-content-center cursor-pointer text-white bg-[#273A55] p-3 rounded-md">
        <ManageSearchIcon fontSize="inherit" color="white" />
      </div>
    </div>
  );
}

export default SearchInput;
