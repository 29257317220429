import React, { useState, useEffect } from "react";
import PageTitle from "../pageTitle/PageTitle";
import { isAdminLoggedIn } from "../../ApiUrlAndToken";
import { hoverScale, submitButton } from "../../components/DesignStandardize";
import ProjectsIcon from "@mui/icons-material/HomeWorkOutlined";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import OngoingProjects from "../projects/OngoingProjects";
import CompletedProjects from "../projects/CompletedProjects";
import UpcomingProjects from "../projects/UpcomingProjects";
import ProjectAdd from "../projects/components/ProjectAdd";
import SearchForm from "./component/SearchProject";
import GeneralModal from "../../components/GeneralModal";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ProjectStatistics from "../projects/ProjectStatistics";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import CountingFlow from "./component/CountingFlow";
import AllProjects from "../projects/AllProjects";
import TopItems from "./component/TopItems";
import TopBusinessPartner from "./component/TopBusinessPartner";
import { DoughnutChart } from "../../components/DoughnutChart";
import RecentRevenue from "./component/RecentRevenue";

const Dashbooard = () => {
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);

  const jwt_token = useSelector((state) => state.global.jwt_token);
  const [tokenData, setTokenData] = useState(null);
  const is_staff = useSelector((state) => state.global.is_staff);

  useEffect(() => {
    const token = jwt_token; // Replace with your actual JWT
    try {
      const decodedToken = jwtDecode(token);
      setTokenData(decodedToken);
      console.log(decodedToken);
      localStorage.setItem("ownerID", decodedToken.user_id);
    } catch (error) {
      console.error("Invalid token", error);
    }
  }, []);

  const toggleModal = () => {
    setModal((prevShowModal) => !prevShowModal);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 110);
  }, []);

  return (
    <>
      <PageTitle title="Dashboard" />

      <div className={`pt-[90px] md:pt-[120px] flex flex-col font-Inter`}>
        {loading ? (
          <div className="flex content-center justify-center items-center">
            <div className="mt-[20%] animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid m-auto" />
          </div>
        ) : (
          <div className="flex flex-col lg:flex-row justify-between">
            <div className="w-full lg:w-[68%] pr-[3%] lg:pr-[0%]">
              <CountingFlow />
              <AllProjects />
              <ProjectStatistics />
            </div>
            <div className="w-full lg:w-[30%] pr-[3%]">
              <TopItems />
              <DoughnutChart />
              <RecentRevenue />
              <TopBusinessPartner />
            </div>
          </div>
        )}
      </div>

      {/* Button To Add New Projects md to large screen */}
      {isAdminLoggedIn && is_staff && (
        <div
          className={`w-max hidden md:place-content-center md:place-items-center md:flex fixed bottom-2 right-2 hover:cursor-pointer font-Inter ${submitButton} ${hoverScale}`}
          onClick={toggleModal}
        >
          <AddCircleIcon className="text-sm mr-1" />{" "}
          <span>Add New Project</span>
        </div>
      )}
      {/* Button To Add New Projects small screen */}
      {isAdminLoggedIn && is_staff && (
        <div
          className={`w-max fixed bottom-2 left-2 md:hidden hover:cursor-pointer font-Inter ${submitButton} ${hoverScale}`}
          onClick={toggleModal}
        >
          <AddCircleIcon className="text-sm mr-1" />{" "}
          <span>Add New Project</span>
        </div>
      )}

      {/* Modal To Add New Projects */}
      {modal && (
        <GeneralModal title="New Project" onClose={toggleModal}>
          <ProjectAdd />
        </GeneralModal>
      )}
    </>
  );
};

export default Dashbooard;
