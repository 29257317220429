import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import GeneralInput from "../../../components/GeneralInput";
import { submitButton } from "../../../components/DesignStandardize";
import { apiUrl, isAdminLoggedIn } from "../../../ApiUrlAndToken";
import GeneralSelect from "../../../components/GeneralSelect";
import { useSelector } from "react-redux";

const AddNewItem = () => {
  const [photo, setPhoto] = useState(null);
  const [name, setName] = useState("");
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState(null);

  const jwt_token = useSelector((state) => state.global.jwt_token);
  const ownerID = window.localStorage.getItem("ownerID");

  const getOnGoingProjects = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/project/`, {
        headers: {
          Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
        },
      });

      const retrived = response.data;
      setProjects(
        retrived.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getOnGoingProjects();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("description", description);
      formData.append("totalAmount", amount);
      formData.append("name", name);
      formData.append("sales_order", project);
      formData.append("owner", ownerID);
      if (photo) {
        formData.append("image", photo); // 'Upload' matches the server's expected field name
      }
      console.log(formData);
      const response = await axios.post(`${apiUrl}/api/voucher/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${isAdminLoggedIn && jwt_token}`,
        },
      });

      console.log(response);
      toast.success(`New Voucher Registered`);
      setTimeout(() => {
        window.location.href = "/admin/vouchers";
      }, 500);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePhotoChange = (event) => {
    setPhoto(event.currentTarget.files[0]);
  };

  return (
    <form className="flex flex-col gap-3 items-center w-full mt-auto md:mr-auto font-semibold font-Inter text-sm text-left md:text-base">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        <div className="">
          <GeneralInput
            className={""}
            label="Voucher Name"
            id="name"
            name="name"
            type="text"
            placeholder="Enter Voucher Name"
            value={name}
            required
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <GeneralSelect
            options={projects}
            label="Select Project" // Corrected from 'lable' to 'label'
            placeholder="Select Project"
            onChange={(selectedOption) => setProject(selectedOption.value)}
            value={projects.find((option) => option.value === project) || ""}
          />
        </div>
        <div className="">
          <GeneralInput
            className={""}
            label="Voucher Description"
            id="description"
            name="description"
            type="text"
            placeholder="Enter Voucher Description"
            value={description}
            required
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className="">
          <GeneralInput
            className={""}
            label="Voucher Amount"
            id="amount"
            name="amount"
            type="number"
            min={1}
            placeholder="Enter Voucher Amount"
            value={amount}
            required
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>

        <div>
          <GeneralInput
            label="Voucher Picture"
            id="photo"
            name="image"
            type="file"
            accept="image/*"
            onChange={handlePhotoChange}
          />
        </div>
      </div>

      <button
        className={`${submitButton} mt-3`}
        onClick={(e) => handleSubmit(e)}
      >
        Add Voucher
      </button>
    </form>
  );
};

export default AddNewItem;
